import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import U from './../../config/util';
import ImageSelect from '../../components/content/image-select.jsx';
import UserProfileImg from '../../components/content/user_profile_img.jsx';
import AlertDialog from "../dlg/alert-dialog.js";

export default class ProfileDialog extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            show_alert_popup: false,
            alert_msg: ""
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onLikeUser = this.onLikeUser.bind(this);
        this.onRequestAudio = this.onRequestAudio.bind(this);
        this.onRequestVideo = this.onRequestVideo.bind(this);
        this.onProfile = this.onProfile.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    onLikeUser() {
        let self = this;
        self.context.post(
            'likeUser',
            {
                id: this.props.rootStore.user.id,
                peer_id: this.props.peer.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("좋아요 했습니다.");
                } else if (data.result == "fail") {
                    self.onAlert("이미 좋아요 했습니다.");
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onRequestAudio() {
        if (this.props.onRequestAudioVideo) {
            this.props.onRequestAudioVideo(1);
        }
    }

    onRequestVideo() {
        if (this.props.onRequestAudioVideo) {
            this.props.onRequestAudioVideo(2);
        }
    }

    onProfile() {
        if (this.props.onProfile) {
            this.props.onProfile();
        }
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    render() {

        return (
            <div>
                <AlertDialog
                    msg={this.state.alert_msg}
                    isOpen={this.state.show_alert_popup}
                    onClose={() => {
                        this.setState({ show_alert_popup: false });
                    }} />
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.toggleModal}
                    size={'md'}>
                    <ModalBody style={{ padding: 0 }}>
                        <div style={{ height: 44, background: '#FF545A', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '0 12px' }}>
                            <label style={{ color: '#fff', fontSize: 15, flexGrow: 100 }}>{strings.profile}</label>
                            <img src={"assets/ic_close.png"} width={20} height={20} className='img-contain' onClick={this.toggleModal} />
                        </div>
                        {
                            this.props.peer &&
                            <div style={{ height: 206, background: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ flexGrow: 100, width: '100%', padding: '10px 10px 10px 30px', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'start' }}>
                                    <div style={{ flexGrow: 100, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className='profile-img-div' onClick={this.onProfile}>
                                            <UserProfileImg
                                                id={this.props.peer.id}
                                                profileImgUrl={this.props.peer.profile_img_url}
                                                sex={this.props.peer.sex}
                                                imgConfirm={this.props.peer.img_confirm}
                                                width="95"
                                                height="95"
                                            />

                                            {
                                                this.props.peer.level != "1" &&
                                                <img src={this.props.peer.level == "5" ? "assets/ic_vip_badge.png" : "assets/ic_svip_badge.png"} width={32} height={32} className='img-contain img-badge' />
                                            }
                                        </div>
                                        <div style={{ flexGrow: 100, height: '100%', marginLeft: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                <label style={{ fontSize: 17, color: '#474747' }}>{this.props.peer.nickname}</label>
                                                <img src={this.props.peer.sex == "m" ? "assets/ic_main_man.png" : "assets/ic_main_girl.png"} width={8} height={10} className='img-contain' style={{ marginLeft: 4, marginRight: 2 }} />
                                                <label style={{ fontSize: 10, color: this.props.peer.sex == "m" ? '#8981FE' : '#FF777A' }}>{this.props.peer.age}세</label>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                <img src={"assets/ic_detail_heart.png"} width={12} height={12} className='img-contain' style={{ marginRight: 2 }} />
                                                <label style={{ fontSize: 11, color: '#A8A8A8' }}>{this.props.peer.ulike_count}</label>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                <img src={"assets/ic_detail_star.png"} width={12} height={12} className='img-contain' style={{ marginRight: 2 }} />
                                                <label style={{ fontSize: 11, color: '#DF70FF' }}>{this.props.peer.sum}</label>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                <img src={"assets/ic_videochat_score.png"} width={12} height={12} className='img-contain' style={{ marginRight: 2 }} />
                                                <label style={{ fontSize: 11, color: '#FF7A7D' }}>{this.props.peer.all_score}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <img src={"assets/btn_like.png"} width={36} height={36} className='img-contain' onClick={this.onLikeUser} />
                                </div>
                                <div className='dashed-line-div' style={{ width: '100%' }}></div>
                                <div style={{ height: 36, width: '100%', margin: '20px 0', padding: '0 30px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ height: 36, flex: 1, borderRadius: 18, backgroundColor: '#7E92FE', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} onClick={this.onRequestAudio}>
                                        <img src={"assets/ic_voice_call.png"} width={20} height={20} className='img-contain' />
                                        <label style={{ color: '#fff', fontSize: 15, marginLeft: 6 }}>{strings.voice_call}</label>
                                    </div>
                                    <div style={{ height: 36, flex: 1, borderRadius: 18, backgroundColor: '#FF777A', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 10 }} onClick={this.onRequestVideo}>
                                        <img src={"assets/ic_video_call.png"} width={20} height={20} className='img-contain' />
                                        <label style={{ color: '#fff', fontSize: 15, marginLeft: 6 }}>{strings.video_call}</label>
                                    </div>
                                </div>
                            </div>
                        }
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}