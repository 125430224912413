import { UiState } from "./ui-state";
import { decorate, observable } from "mobx";

class RootStore {

    systempoint = {
        rewardMsg: '',
        rewardGift: '',
        rewardAudio: '',
        rewardVideo: '',
        rewardPhoto: '',
        priceMsg: '',
        priceGroupMsg: '',
        priceAudio: '',
        priceVideo: '',
        pricePhoto: '',
        pointFirstCommunity: '',
        attendance: '',
        rewardChat: '',
        priceChat: ''
    };

    user = {
        id: '',
        age: '',
        nickname: '',
        location: '',
        point: '',
        level: '',
        sex: '',
        profileMsg: '',
        profileImgUrl: '',
        expire: '',
        expireDate: '',
        authKey: '',
        imgConfirm: '',
        allowGift: '',
        allowVideo: '',
        rsacode: '',
        expMsg: '',
        payable: 0,
        weekPoint: '',
        dailyPoint: '',
        balloonPoint: '',
        balloonList: '',
        videoMaxDelay: '',
        igacnt: 0,
        rankingEnable: '',
        appallServer: '',
        payAction: ''
    };

    access_token = "";


    constructor() {
        this.uiState = new UiState(this);
        this.loadStore();
    }

    setAccessToken(token) {
        this.access_token = token;
        this.saveStore();
    }

    setSystemPoint(point) {
        this.systempoint = point;
        this.saveStore();
    }

    login(user) {
        this.user = user;
        this.saveStore();
    }

    logout() {
        this.access_token = "";

        this.systempoint = {
            rewardMsg: '',
            rewardGift: '',
            rewardAudio: '',
            rewardVideo: '',
            rewardPhoto: '',
            priceMsg: '',
            priceGroupMsg: '',
            priceAudio: '',
            priceVideo: '',
            pricePhoto: '',
            pointFirstCommunity: '',
            attendance: '',
            rewardChat: '',
            priceChat: ''
        };
        this.user = {
            id: '',
            age: '',
            nickname: '',
            location: '',
            point: '',
            level: '',
            sex: '',
            profileMsg: '',
            profileImgUrl: '',
            expire: '',
            expireDate: '',
            authKey: '',
            imgConfirm: '',
            allowGift: '',
            allowVideo: '',
            rsacode: '',
            expMsg: '',
            payable: 0,
            weekPoint: '',
            dailyPoint: '',
            balloonPoint: '',
            balloonList: '',
            videoMaxDelay: '',
            igacnt: 0,
            rankingEnable: '',
            appallServer: '',
            payAction: ''
        };
        this.saveStore();
    }

    isLogin() {
        return this.user.id !== '';
    }

    loadStore() {
        try {
            let store = JSON.parse(sessionStorage.getItem('store'));
            if (!store) {
                return;
            }

            this.user = store.user;
            this.systempoint = store.systempoint;
            this.access_token = store.access_token;
        } catch (e) {
            // console.log(e);
        }
    }

    saveStore() {
        sessionStorage.setItem('store', JSON.stringify(this));
    }
}

decorate(RootStore, {
    user: observable,
    systempoint: observable,
    access_token: observable,
});

export default RootStore;