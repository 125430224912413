import React from 'react';
import { Route, Link } from 'react-router-dom';
import UserProfileImg from '../../../components/content/user_profile_img.jsx';

class ChatRoomList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}


	render() {

		return (
			<div style={{
				display: 'flex', flexDirection: 'row', alignItems: 'center',
				padding: 10,
				borderBottom: '1px solid #eee',
				cursor: 'pointer',
			}}>
				<div className='profile-img-div'>
					<UserProfileImg
						id={this.props.item.peer_id}
						profileImgUrl={this.props.item.peer_profile_img_url}
						sex={this.props.item.peer_sex}
						imgConfirm={this.props.item.img_confirm}
						width="60"
						height="60"
					/>
					{
						this.props.item.is_friend == "1" &&
						<img src={"assets/ic_friend_mark.png"} width={30} height={21} className='img-contain img-badge' />
					}
				</div>
				{
					this.props.item.peer_id == "admin" || this.props.item.peer_id == "adminchat" ?
						<div className='profile-nick-div'>
							<label className='chat-nick-label'>{this.props.item.peer_id == "admin" ? "=알림=" : "관리자(1:1)"}</label>
							<div className='chat-content-label'>
								{this.props.item.content}
							</div>
						</div>
						:
						<div className='profile-nick-div'>
							<label className='chat-nick-label'>{this.props.item.peer_nickname}</label>
							{
								this.props.item.peer_sex == "m" ?
									<img src={"assets/ic_main_man.png"} width={8} height={8} className='img-contain' />
									:
									<img src={"assets/ic_main_girl.png"} width={8} height={8} className='img-contain' />
							}
							{
								this.props.item.peer_sex == "m" ?
									<label className='chat-age-label-man'>{this.props.item.peer_age}</label>
									:
									<label className='chat-age-label-girl'>{this.props.item.peer_age}</label>
							}
							<img src={"assets/ic_dot.png"} width={2} height={2} className='img-dot-contain' />
							<label className='chat-before-label'>{this.props.item.before}</label>
							<div className='chat-content-label'>
								{this.props.item.content}
							</div>
						</div>
				}
				{
					(this.props.item.peer_id == "admin" ? this.props.item.admin_noread : this.props.item.read_count) != "0" &&
					<div className='chat-unread-div'>
						<label className='chat-unread-label'>{this.props.item.peer_id == "admin" ? this.props.item.admin_noread : this.props.item.read_count}</label>
					</div>
				}
			</div>
		);
	}
}

export default ChatRoomList;