import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import TalkList from './cell/talk-list.jsx';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel.jsx';
import ProfileDialog from "../dlg/profile-dialog.js";
import AlertDialog from "../dlg/alert-dialog.js";
import TalkDialog from "../dlg/talk-dialog.js";
import ConfirmDialog from "../dlg/confirm-dialog.js";
import SendMsgDialog from "../dlg/send-msg-dialog.js";

class TalkFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            pageNum: 0,
            isLast: false,
            talkList: [],
            show_detail_popup: false,
            peer_user: null,
            show_talk_popup: false,
            show_alert_popup: false,
            alert_msg: "",
            show_delete_popup: false,
            selectedIndex: -1,
            show_chat_popup: false,
            show_charge_popup: false,
            show_install_popup: false,
        };

        this.onWriteTalk = this.onWriteTalk.bind(this);
    }

    componentDidMount() {
        this.getTalkList();
    }

    onProfile(item) {
        if (item.id == "admin" || item.id == "adminchat") {
            return;
        }

        if (item.statevideo == "1") {
            this.onAlert("상대방이 통화중입니다.");
            return;
        }

        item.pro_img_url = item.profile_img_url;
        item.img_confirm = item.pro_img_confirm;

        //상대회원 리뷰정보 얻기
        let self = this;
        self.context.post(
            'otherUserReviewScore',
            {
                id: this.props.rootStore.user.id,
                peer_id: item.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    item.avg = data.avg;
                    item.sum = data.sum;
                    item.ulike_count = data.ulike_count;
                    item.ulike_status = data.ulike_status;
                    item.all_score = data.all_score;
                    item.level = data.level;

                    self.setState({
                        show_detail_popup: true,
                        peer_user: item,
                    });
                }
            });
    }

    onWriteTalk(event) {
        this.setState({
            show_talk_popup: true
        });
    }

    //토크리스트
    getTalkList() {
        if (this.state.isLast || this.state.loading) {
            return;
        }
        this.setState({
            loading: true
        })

        let self = this;
        self.context.post(
            'getTalkList',
            {
                id: this.props.rootStore.user.id,
                sex: this.props.rootStore.user.sex,
                page: this.state.pageNum
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    if (data.boardList.length == 0) {
                        self.setState({
                            isLast: true
                        })
                    }
                    if (self.state.pageNum == 0) {
                        self.setState({
                            talkList: data.boardList
                        })
                    } else {
                        let list = self.state.talkList.concat(data.boardList);
                        self.setState({
                            talkList: list
                        })
                    }
                }
                self.setState({
                    loading: false
                })
            });
    }

    addTalk(content) {
        let self = this;
        self.context.post(
            'addTalk',
            {
                id: this.props.rootStore.user.id,
                nickname: this.props.rootStore.user.nickname,
                sex: this.props.rootStore.user.sex,
                age: this.props.rootStore.user.age,
                content: content,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.props.parent.resumeHomePage();
                    self.setState({
                        pageNum: 0,
                        isLast: false,
                    }, () => {
                        self.getTalkList();
                    })
                }
            });
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    openProfilePage() {
        let item = this.state.peer_user;

        if (item.id == "admin" || item.id == "adminchat") {
            return;
        }

        //상대회원 상세정보 얻기
        let self = this;
        self.context.post(
            'otherUserInfo',
            {
                id: item.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    item.level = data.level;
                    item.ulike = item.ulike;
                    item.profile_msg = item.profile_msg;
                    item.statevideo = item.statevideo;

                    sessionStorage.setItem('selectedUser', JSON.stringify(item));
                    self.props.history.push('/profile');
                }
            });
    }

    reqDelTalk() {
        let talk = this.state.talkList[this.state.selectedIndex];

        let self = this;
        self.context.post(
            'deletetalk',
            {
                id: this.props.rootStore.user.id,
                ind: talk.ind
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("토크 삭제되었습니다.");
                    let filteredArray = self.state.talkList.filter(item => item !== talk)
                    self.setState({
                        selectedIndex: -1,
                        talkList: filteredArray
                    })
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onChat() {
        let talk = this.state.talkList[this.state.selectedIndex];

        let self = this;
        self.context.post(
            'isChatRoomOpen',
            {
                id: this.props.rootStore.user.id,
                peer_id: talk.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    //채팅방 열림-이동
                    sessionStorage.setItem('selectedPeerId', talk.id);
                    self.props.history.push('/chat');
                } else if (data.result == "fail") {
                    self.setState({
                        show_chat_popup: true
                    });
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onSendMsg(msg) {
        if (Number(this.props.rootStore.systempoint.priceMsg) > Number(this.props.rootStore.user.point)) {
            if (this.props.rootStore.user.sex == "m") {
                this.setState({
                    show_charge_popup: true
                });
            } else {
                this.onAlert("포인트가 부족합니다.");
            }
            return;
        }

        let talk = this.state.talkList[this.state.selectedIndex];
        let self = this;
        self.context.post(
            'newMessage',
            {
                id: this.props.rootStore.user.id,
                peer_id: talk.id,
                content: msg,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("쪽지 발송되었습니다.");

                    let user = self.props.rootStore.user;
                    user.point = data.point;
                    self.props.rootStore.login(user);
                } else if (data.result == "userblock") {
                    self.onAlert("상대방이 차단했습니다.");
                } else if (data.result == "notenough") {
                    self.onAlert("포인트가 부족합니다.");
                } else if (data.result == "fail") {
                    self.onAlert(data.msg);
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 5
        if (bottom) {
            this.setState({
                pageNum: this.state.pageNum + 1
            }, () => {
                this.getTalkList();
            })

        }
    }

    appInstall() {
        this.setState({
            show_install_popup: true
        })
    }

    appInstallLink() {
        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
        if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
            window.open(strings.appstore_link, '_blank');
        } else {
            window.open(strings.google_link, '_blank');
        }
    }

    render() {

        return (
            <div className='fragment-div'>
                <ConfirmDialog
                    title="알림"
                    msg={strings.use_app_version}
                    cancelBtn="아니"
                    okBtn="예"
                    isOpen={this.state.show_install_popup}
                    onClose={() => {
                        this.setState({ show_install_popup: false });
                    }}
                    onCancel={() => {
                        this.setState({ show_install_popup: false });
                    }}
                    onConfirm={() => {
                        this.setState({ show_install_popup: false });
                        this.appInstallLink();
                    }} />
                <SendMsgDialog
                    rootStore={this.props.rootStore}
                    peer={this.state.selectedIndex == -1 ? null : this.state.talkList[this.state.selectedIndex]}
                    isOpen={this.state.show_chat_popup}
                    onClose={() => {
                        this.setState({ show_chat_popup: false });
                    }}
                    onSend={(msg) => {
                        this.setState({ show_chat_popup: false });
                        this.onSendMsg(msg);
                    }} />
                <ConfirmDialog
                    title="알림"
                    msg="정말 삭제하시겠습니까?"
                    cancelBtn="취소"
                    okBtn="삭제하기"
                    isOpen={this.state.show_delete_popup}
                    onClose={() => {
                        this.setState({ show_delete_popup: false });
                    }}
                    onCancel={() => {
                        this.setState({ show_delete_popup: false });
                    }}
                    onConfirm={() => {
                        this.setState({ show_delete_popup: false });
                        this.reqDelTalk();
                    }} />
                <ConfirmDialog
                    title="알림"
                    msg="포인트가 부족하십니다.<br>포인트를 충전 하시겠습니까?"
                    cancelBtn="닫기"
                    okBtn="충전"
                    isOpen={this.state.show_charge_popup}
                    onClose={() => {
                        this.setState({ show_charge_popup: false });
                    }}
                    onCancel={() => {
                        this.setState({ show_charge_popup: false });
                    }}
                    onConfirm={() => {
                        this.setState({ show_charge_popup: false });
                        this.props.history.push('/pointcharge');
                    }} />
                <ProfileDialog
                    peer={this.state.peer_user}
                    rootStore={this.props.rootStore}
                    isOpen={this.state.show_detail_popup}
                    onClose={() => {
                        this.setState({ show_detail_popup: false });
                    }}
                    onRequestAudioVideo={(e) => {
                        this.setState({ show_detail_popup: false });
                        this.appInstall();
                    }}
                    onProfile={() => {
                        this.setState({ show_detail_popup: false });
                        if (this.state.peer_user.sex == this.props.rootStore.user.sex) {
                            this.onAlert(strings.not_view_same_gender);
                        } else {
                            this.openProfilePage();
                        }
                    }} />
                <AlertDialog
                    msg={this.state.alert_msg}
                    isOpen={this.state.show_alert_popup}
                    onClose={() => {
                        this.setState({ show_alert_popup: false });
                    }} />
                <TalkDialog isOpen={this.state.show_talk_popup}
                    onClose={() => {
                        this.setState({ show_talk_popup: false });
                    }}
                    onConfirm={(content) => {
                        this.setState({ show_talk_popup: false });
                        this.addTalk(content);
                    }} />

                <div className='talk-div'>
                    <div className='scroll-talk-div' onScroll={this.handleScroll}>
                        <ul>
                            {
                                this.state.talkList.map((item, index) => {
                                    return <li key={index} onClick={() => this.onProfile(item)}>
                                        <TalkList
                                            item={item}
                                            rootStore={this.props.rootStore}
                                            onPhoto={() => {
                                                if (item.sex == this.props.rootStore.user.sex) {
                                                    this.onAlert(strings.not_view_same_gender);
                                                } else {
                                                    if (item.id == "admin" || item.id == "adminchat") {
                                                        return;
                                                    }

                                                    item.pro_img_url = item.profile_img_url;
                                                    item.img_confirm = item.pro_img_confirm;

                                                    //상대회원 리뷰정보 얻기
                                                    let self = this;
                                                    self.context.post(
                                                        'otherUserReviewScore',
                                                        {
                                                            id: this.props.rootStore.user.id,
                                                            peer_id: item.id,
                                                        },
                                                        function (response) {
                                                            var data = JSON.parse(response.data);
                                                            // console.log(data);
                                                            if (data.result == "success") {
                                                                item.avg = data.avg;
                                                                item.sum = data.sum;
                                                                item.ulike_count = data.ulike_count;
                                                                item.ulike_status = data.ulike_status;
                                                                item.all_score = data.all_score;
                                                                item.level = data.level;

                                                                self.setState({
                                                                    peer_user: item,
                                                                }, () => {
                                                                    self.openProfilePage();
                                                                });
                                                            }
                                                        });
                                                }
                                            }}
                                            onDelete={() => {
                                                this.setState({
                                                    show_delete_popup: true,
                                                    selectedIndex: index,
                                                })
                                            }}
                                            onWrite={() => {
                                                if (item.sex == this.props.rootStore.user.sex) {
                                                    this.onAlert(strings.not_msg_same_gender);
                                                } else {
                                                    this.setState({
                                                        selectedIndex: index,
                                                    }, () => {
                                                        this.onChat();
                                                    })
                                                }
                                            }}
                                        />
                                    </li>
                                })
                            }
                        </ul>
                    </div>

                    <img src={"assets/btn_write.png"} width={60} height={60} className='img-contain img-talk-write' onClick={this.onWriteTalk} />
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(TalkFragment));