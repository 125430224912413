import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import U from './../../config/util';
import ImageSelect from '../../components/content/image-select.jsx';
import UserProfileImg from '../../components/content/user_profile_img.jsx';

export default class RankingDialog extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    render() {

        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.toggleModal}
                    size={'md'}>
                    <ModalBody style={{padding: 0}}>
                        <div style={{height: 44, background: '#FF545A', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '0 12px'}}>
                            <label style={{color: '#fff', fontSize: 15, flexGrow: 100}}>{strings.rank_system}</label>
                            <img src={"assets/ic_close.png"} width={20} height={20} className='img-contain' onClick={this.toggleModal}/>
                        </div>
                        <div style={{background: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 12}}>
                            <div style={{background: '#fff', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                                <label style={{fontSize: 13, color: '#474747', flexGrow: 100}}>영상통화 1분</label>
                                <div style={{width: 32, height: 18, borderRadius: 9, backgroundColor: '#FF777A', textAlign: 'center'}}>
                                    <label style={{fontSize: 8, color: '#fff', flexGrow: 100}}>+5점</label>
                                </div>
                            </div>
                            <div style={{background: '#fff', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                                <label style={{fontSize: 13, color: '#474747', flexGrow: 100}}>음성통화 1분</label>
                                <div style={{width: 32, height: 18, borderRadius: 9, backgroundColor: '#FF777A', textAlign: 'center'}}>
                                    <label style={{fontSize: 8, color: '#fff', flexGrow: 100}}>+3점</label>
                                </div>
                            </div>
                            <div style={{background: '#fff', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                                <label style={{fontSize: 13, color: '#474747', flexGrow: 100}}>별풍선 선물(주기/받기) 1개당</label>
                                <div style={{width: 32, height: 18, borderRadius: 9, backgroundColor: '#FF777A', textAlign: 'center'}}>
                                    <label style={{fontSize: 8, color: '#fff', flexGrow: 100}}>+3점</label>
                                </div>
                            </div>
                            <label style={{fontSize: 11, color: '#828282', width: '100%'}}>(제제 되거나 탈퇴한 계정은 집계되지 않습니다.)</label>
                            <div className='dashed-line-div' style={{marginTop: 10, marginBottom: 10, width: '100%'}}></div>
                            <label style={{fontSize: 11, color: '#828282', width: '100%'}} dangerouslySetInnerHTML={{__html: strings.rank_system_guide_1}}></label>
                            <div className='dashed-line-div' style={{marginTop: 10, marginBottom: 10, width: '100%'}}></div>
                            <div>
                                <img src={"assets/ic_week_rank.png"} width={12} height={12} className='img-contain'/>
                                <label style={{fontSize: 10, color: '#FF5D60', marginLeft: 4}}>매주 랭킹별 보상선물</label>
                            </div>
                            <div style={{marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                <div style={{width: 72, borderRadius: 4, backgroundColor: '#FFC2C2', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                    <label style={{fontSize: 11, color: '#FF545A', marginTop: 2, marginBottom: 2}}>1위</label>
                                    <div style={{width: 72, backgroundColor: '#FFE8F8', borderBottomLeftRadius: 4, borderBottomRightRadius: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5}}>
                                        <img src={"assets/ic_win1_giftbox.png"} width={53} height={38} className='img-contain'/>
                                        <label style={{fontSize: 10, color: '#636363'}}>{U.currencyFormat(this.props.rootStore.user.weekPoint.split(":")[0])}P</label>
                                    </div>
                                </div>
                                <div style={{width: 72, borderRadius: 4, backgroundColor: '#FFC2C2', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: 10, marginRight: 10}}>
                                    <label style={{fontSize: 11, color: '#FF545A', marginTop: 2, marginBottom: 2}}>2위</label>
                                    <div style={{width: 72, backgroundColor: '#FFE8F8', borderBottomLeftRadius: 4, borderBottomRightRadius: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5}}>
                                        <img src={"assets/ic_win2.png"} width={53} height={38} className='img-contain'/>
                                        <label style={{fontSize: 10, color: '#636363'}}>{U.currencyFormat(this.props.rootStore.user.weekPoint.split(":")[1])}P</label>
                                    </div>
                                </div>
                                <div style={{width: 72, borderRadius: 4, backgroundColor: '#FFC2C2', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                    <label style={{fontSize: 11, color: '#FF545A', marginTop: 2, marginBottom: 2}}>3위</label>
                                    <div style={{width: 72, backgroundColor: '#FFE8F8', borderBottomLeftRadius: 4, borderBottomRightRadius: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5}}>
                                        <img src={"assets/ic_win3.png"} width={53} height={38} className='img-contain'/>
                                        <label style={{fontSize: 10, color: '#636363'}}>{U.currencyFormat(this.props.rootStore.user.weekPoint.split(":")[2])}P</label>
                                    </div>
                                </div>
                            </div>
                            <div style={{marginTop: 20}}>
                                <img src={"assets/ic_day_rank.png"} width={12} height={12} className='img-contain'/>
                                <label style={{fontSize: 10, color: '#8D76FF', marginLeft: 4}}>일일 랭킹별 보상선물</label>
                            </div>
                            <div style={{marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                <div style={{width: 72, borderRadius: 4, backgroundColor: '#C9D1F9', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                    <label style={{fontSize: 11, color: '#8D76FF', marginTop: 2, marginBottom: 2}}>1위</label>
                                    <div style={{width: 72, backgroundColor: '#E8EAFF', borderBottomLeftRadius: 4, borderBottomRightRadius: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5}}>
                                        <img src={"assets/ic_win2.png"} width={53} height={38} className='img-contain'/>
                                        <label style={{fontSize: 10, color: '#636363'}}>{U.currencyFormat(this.props.rootStore.user.dailyPoint.split(":")[0])}P</label>
                                    </div>
                                </div>
                                <div style={{width: 72, borderRadius: 4, backgroundColor: '#C9D1F9', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: 10, marginRight: 10}}>
                                    <label style={{fontSize: 11, color: '#8D76FF', marginTop: 2, marginBottom: 2}}>2위</label>
                                    <div style={{width: 72, backgroundColor: '#E8EAFF', borderBottomLeftRadius: 4, borderBottomRightRadius: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5}}>
                                        <img src={"assets/ic_win3.png"} width={53} height={38} className='img-contain'/>
                                        <label style={{fontSize: 10, color: '#636363'}}>{U.currencyFormat(this.props.rootStore.user.dailyPoint.split(":")[1])}P</label>
                                    </div>
                                </div>
                                <div style={{width: 72, borderRadius: 4, backgroundColor: '#C9D1F9', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                    <label style={{fontSize: 11, color: '#8D76FF', marginTop: 2, marginBottom: 2}}>3위</label>
                                    <div style={{width: 72, backgroundColor: '#E8EAFF', borderBottomLeftRadius: 4, borderBottomRightRadius: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 5, paddingBottom: 5}}>
                                        <img src={"assets/ic_win4.png"} width={53} height={38} className='img-contain'/>
                                        <label style={{fontSize: 10, color: '#636363'}}>{U.currencyFormat(this.props.rootStore.user.dailyPoint.split(":")[2])}P</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}