import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import U from './../../config/util';
import ImageSelect from '../../components/content/image-select.jsx';
import UserProfileImg from '../../components/content/user_profile_img.jsx';

export default class CustomerDialog extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {},
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onSend = this.onSend.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    onSend(result) {
        if (this.props.onSend) {
            this.props.onSend(this.state.item.content);
        }
    }

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    render() {
        let item = this.state.item;

        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.toggleModal}
                    size={'md'}>
                    <ModalBody style={{ padding: 0 }}>
                        <div style={{ height: 44, background: '#FF545A', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '0 12px' }}>
                            <label style={{ color: '#fff', fontSize: 15, flexGrow: 100 }}>고객센터</label>
                            <img src={"assets/ic_close.png"} width={20} height={20} className='img-contain' onClick={this.toggleModal} />
                        </div>
                        <div style={{ background: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                            <textarea autoComplete='off' className='form-control' id='content'
                                placeholder='문의내용을 입력해 주세요.'
                                rows={6}
                                value={item.content}
                                onChange={(e) => this.onDataChange(e)} />
                            <div style={{ height: 36, width: '100%', marginTop: 10, padding: '0 30px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ height: 36, flex: 1, borderRadius: 18, backgroundColor: '#9C9C9C', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} onClick={this.toggleModal}>
                                    <label style={{ color: '#fff', fontSize: 15, marginLeft: 6 }}>취소</label>
                                </div>
                                <div style={{ height: 36, flex: 1, borderRadius: 18, backgroundColor: '#FF777A', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 10 }} onClick={this.onSend}>
                                    <label style={{ color: '#fff', fontSize: 15, marginLeft: 6 }}>보내기</label>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}