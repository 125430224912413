import React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings.js';
import { inject } from 'mobx-react';
import UserProfileImg from '../../components/content/user_profile_img.jsx';

import $ from 'jquery';
import AlertDialog from "../dlg/alert-dialog.js";

class Join extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {
                nickname: "",
                age: "",
                sex: "",
                profileMsg: "",
                profileImgUrl: "",
            },
            dropdownAgeOpen: false,
            dropdownMsgOpen: false,
            show_alert_popup: false,
            alert_msg: "",
            ageList: [],
            msgList: ['술친구를 구해요^_^', '무엇이든 물어보세요 ㅎ', '애인구함ㅎㅎ', '연애하고 싶어요~",', '영화보러 갈 사람!!!', '오늘부터 일일~ z', '원나잇^___^', '댄스타임@@_@@', '얼공!!!!! ~~ㅋㅋㅋ', '영상,음성 모두 가능!!!', '영상채팅해요~', '음성통화만해요~', '그냥 심심해서요', '대화 먼저해볼까요?', '동네 친구구해요', '맛집 탐방 하실분!?', '지금 만나요!!!', '드라이브 하고시퍼~', '고민 상담해주세요!', '안녕하세요~']
        };

        this.onBack = this.onBack.bind(this);
        this.toggleAge = this.toggleAge.bind(this);
        this.toggleMsg = this.toggleMsg.bind(this);
        this.reqSignup = this.reqSignup.bind(this);
    }

    componentDidMount() {
        let arr = []
        for (var i = 20; i < 100; i++) {
            arr.push(i);
        }
        this.setState({
            ageList: arr
        })
    }

    toggleAge() {
        this.setState(prevState => ({
            dropdownAgeOpen: !prevState.dropdownAgeOpen
        }));
    }

    toggleMsg() {
        this.setState(prevState => ({
            dropdownMsgOpen: !prevState.dropdownMsgOpen
        }));
    }

    onBack(event) {
        event.preventDefault();
        this.props.history.goBack();
    }

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    reqSignup(event) {
        let item = this.state.item;
        if (item.sex == "") {
            this.onAlert("성별을 선택하세요.");
            return;
        }
        if (item.nickname == "") {
            this.onAlert("닉네임을 입력하세요.");
            return;
        }
        if (item.nickname.length < 3 || item.nickname.length > 6) {
            this.onAlert("닉네임은 3~6글자이어야 합니다.");
            return;
        }
        if (item.age == "") {
            this.onAlert("나이를 선택하세요.");
            return;
        }
        if (item.profileMsg == "") {
            this.onAlert("대화주제를 선택하세요.");
            return;
        }

        let self = this;
        self.context.post(
            'signup',
            {
                phone: sessionStorage.getItem('phone_number'),
                code: sessionStorage.getItem('phone_code'),
                nickname: item.nickname,
                profile_img_url: item.profileImgUrl,
                profile_msg: item.profileMsg,
                age: item.age,
                gender: item.sex
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.reqLogin();
                } else if (data.result == "block") {
                    if (data.msg == "block words") {
                        self.onAlert("닉네임에 금지어가 포함되어 있습니다.");
                    } else {
                        self.onAlert("네트워크 혹은 서버오류로 서버에 접속할 수 없습니다.\n해당 상태가 반복되는 경우 고객센터로 메일을\n보내주세요\n(회원탈퇴하신 경우 12시간 이후 다시 접속해주세요.)");
                    }
                } else if (data.result == "fail") {
                    if (data.msg == "existid") {
                        self.reqLogin();
                    } else if (data.msg == "existnickname") {
                        self.onAlert("이미 사용중인 닉네임입니다.");
                    } else {
                        self.onAlert("네트워크 혹은 서버오류로 서버에 접속할 수 없습니다.\n해당 상태가 반복되는 경우 고객센터로 메일을\n보내주세요\n(회원탈퇴하신 경우 12시간 이후 다시 접속해주세요.)");
                    }
                }
            },
            true
        );
    }

    reqLogin() {
        let self = this;
        self.context.post(
            'login',
            {
                phone: sessionStorage.getItem('phone_number'),
            },
            function (response) {
                var access_token = response.data.access_token;
                // console.log(access_token);
                self.props.rootStore.setAccessToken(access_token);

                var data = JSON.parse(response.data.data);
                // console.log(data);
                if (data.result == "success") {
                    self.props.rootStore.login({
                        id: data.id,
                        age: data.age,
                        nickname: data.nickname,
                        location: data.location,
                        point: data.point,
                        level: data.level,
                        sex: data.sex,
                        profileMsg: data.profile_msg,
                        profileImgUrl: data.profile_img_url,
                        expire: data.expire,
                        expireDate: data.expire_date,
                        authKey: data.auth_key,
                        imgConfirm: data.img_confirm,
                        allowGift: data.allow_gift,
                        allowVideo: data.allow_video,
                        rsacode: data.rsacode,
                        expMsg: data.exp_msg,
                        payable: data.payable,
                        weekPoint: data.week_point,
                        dailyPoint: data.daily_point,
                        balloonPoint: data.balloon_point,
                        balloonList: data.balloon_list,
                        videoMaxDelay: data.video_max_delay,
                        igacnt: data.igacnt,
                        rankingEnable: data.ranking_enable,
                        appallServer: data.appall_server,
                        payAction: data.pay_action
                    });

                    sessionStorage.setItem('mainTabIndex', 0);
                    self.props.history.push('/dashboard');
                } else {
                    self.onAlert(data.msg);
                }
            },
            true
        );
    }

    onImageChange = (e) => {
        let self = this;

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            self.uploadData(file);
        };
        reader.readAsDataURL(file);
    };

    uploadData(file) {
        let formData = new FormData();
        formData.append('uploadfile', file);
        formData.append('id', "");
        formData.append('phone', sessionStorage.getItem('phone_number'));
        formData.append('mode', "before");

        let self = this;
        self.context.post(
            'uploadProfile',
            formData,
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    let item = self.state.item;
                    item.profileImgUrl = data.profile_img_url;
                    self.setState({
                        item: item
                    });
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className='dashboard-div'>
                    <AlertDialog
                        msg={this.state.alert_msg}
                        isOpen={this.state.show_alert_popup}
                        onClose={() => {
                            this.setState({ show_alert_popup: false });
                        }} />

                    <div className='common-header-div borderb'>
                        <div className='common-header-back-div' onClick={this.onBack}>
                            <img src={"assets/btn_back.png"} width={15} height={13} />
                        </div>
                        <label className='common-header-title-label' style={{ flexGrow: 100 }}>{strings.join}</label>
                        <label className='common-header-title-label' style={{ color: '#FD8185', marginRight: 15 }} onClick={this.reqSignup}>가입</label>
                    </div>

                    <div className='scroll-blocklist-div'>
                        <div className='profile-header-div' onClick={() => this.imageRef.click()}>
                            <div style={{ position: 'relative', width: 95, height: 95 }}>
                                <UserProfileImg
                                    id=""
                                    profileImgUrl={this.state.item.profileImgUrl}
                                    sex={this.state.item.sex}
                                    imgConfirm="1"
                                    width="95"
                                    height="95"
                                />
                                <img src={"assets/btn_add_profile_img.png"} width={32} height={32} style={{ position: 'absolute', bottom: 0, right: 0 }} />

                                <input type='file' accept='image/*' className='hide'
                                    onChange={(e) => this.onImageChange(e)}
                                    ref={(ref) => this.imageRef = ref} />
                            </div>
                            <p className='profile-photo-desc-label' dangerouslySetInnerHTML={{ __html: strings.profile_edit_photo_desc }}></p>
                        </div>

                        <div style={{ margin: '8px 16px' }}>
                            <label className='profile-paragraph-label'>{strings.gender}</label>
                            <div className='profile-paragraph-div'>
                                <div onClick={() => {
                                    let item = this.state.item;
                                    item.sex = "m";

                                    this.setState({
                                        item: item
                                    });
                                }}>
                                    <img src={this.state.item.sex == "m" ? "assets/ic_radio_on.png" : "assets/ic_radio_off.png"} width={20} height={20} style={{ marginRight: 10 }} />
                                    <label className={"profile-gender-label " + (this.state.item.sex == "m" ? "selected" : "")}>{strings.male}</label>
                                </div>
                                <div style={{ marginLeft: 10 }} onClick={() => {
                                    let item = this.state.item;
                                    item.sex = "f";

                                    this.setState({
                                        item: item
                                    });
                                }}>
                                    <img src={this.state.item.sex == "f" ? "assets/ic_radio_on.png" : "assets/ic_radio_off.png"} width={20} height={20} style={{ marginRight: 10 }} />
                                    <label className={"profile-gender-label " + (this.state.item.sex == "f" ? "selected" : "")}>{strings.female}</label>
                                </div>
                            </div>
                            <label className='profile-paragraph-label' style={{ marginTop: 10 }}>{strings.nickname}</label>
                            <div className='profile-paragraph-div'>
                                <input id={'nickname'} type="text" autoComplete={'off'}
                                    className="nickname-input-box" placeholder={strings.input_nickname}
                                    value={this.state.item.nickname}
                                    onChange={(e) => this.onDataChange(e)}
                                    required />
                            </div>
                            <label className='profile-paragraph-label' style={{ marginTop: 10 }}>{strings.age}</label>
                            <Dropdown direction='up' isOpen={this.state.dropdownAgeOpen} toggle={this.toggleAge} className="dropdown navbar-user" tag="li">
                                <DropdownToggle tag="a">
                                    <div className='profile-paragraph-div'>
                                        <label className='profile-age-label'>{this.state.item.age == "" ? "나이를 선택해 주세요" : this.state.item.age + "세"}</label>
                                        <img src={"assets/ic_spinner_triangle.png"} width={7} height={15} />
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul"
                                    modifiers={{
                                        setMaxHeight: {
                                            enabled: true,
                                            order: 890,
                                            fn: (data) => {
                                                return {
                                                    ...data,
                                                    styles: {
                                                        ...data.styles,
                                                        overflow: 'auto',
                                                        maxHeight: 100,
                                                    },
                                                };
                                            },
                                        },
                                    }}>
                                    {
                                        this.state.ageList.map((item, index) => {
                                            return <DropdownItem key={index}
                                                onClick={() => {
                                                    let sitem = this.state.item;
                                                    sitem.age = item;

                                                    this.setState({
                                                        item: sitem
                                                    });
                                                }}>{item}세</DropdownItem>
                                        })
                                    }
                                </DropdownMenu>
                            </Dropdown>

                            <label className='profile-paragraph-label' style={{ marginTop: 10 }}>{strings.topic}</label>
                            <Dropdown direction='up' isOpen={this.state.dropdownMsgOpen} toggle={this.toggleMsg} className="dropdown navbar-user" tag="li">
                                <DropdownToggle tag="a">
                                    <div className='profile-paragraph-div'>
                                        <label className='profile-age-label'>{this.state.item.profileMsg == "" ? "대화주제를 선택해 주세요." : this.state.item.profileMsg}</label>
                                        <img src={"assets/ic_spinner_triangle.png"} width={7} height={15} />
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul"
                                    modifiers={{
                                        setMaxHeight: {
                                            enabled: true,
                                            order: 890,
                                            fn: (data) => {
                                                return {
                                                    ...data,
                                                    styles: {
                                                        ...data.styles,
                                                        overflow: 'auto',
                                                        maxHeight: 100,
                                                    },
                                                };
                                            },
                                        },
                                    }}>
                                    {
                                        this.state.msgList.map((item, index) => {
                                            return <DropdownItem key={index}
                                                onClick={() => {
                                                    let sitem = this.state.item;
                                                    sitem.profileMsg = item;

                                                    this.setState({
                                                        item: sitem
                                                    });
                                                }}>{item}</DropdownItem>
                                        })
                                    }
                                </DropdownMenu>
                            </Dropdown>
                            <p className='profile-photo-desc-label' dangerouslySetInnerHTML={{ __html: strings.profile_edit_desc }}></p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(inject('rootStore')(Join));