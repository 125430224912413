import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
// Constant
import Const from './config/const';
import strings from './lang/strings.js';
import './App.css';

import { inject } from "mobx-react";
import { PageSettings } from './config/page-settings.js';

import Content from './components/content/content.jsx';
import ProgressBar from './components/content/progress-bar.jsx';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.post = async (url, param, callback, showProgress = false) => {
      if (showProgress)
        this.handleSetPageLoading(true);

      try {
        let result = await axios.post(Const.BASE_URL + url, param, {
          headers: {
            'x-access-token': this.props.rootStore.access_token
          }
        });
        // console.log(result);

        if (showProgress)
          this.handleSetPageLoading(false);

        let response = result.data;
        if (response.code == 200) {
          callback(response);
        }
      } catch (error) {


        if (showProgress)
          this.handleSetPageLoading(false);

        this.handleApiError(error);
      }
    };

    this.handleApiError = (err) => {
      // console.log(err);
    };

    this.handleSetPageLoading = (value) => {
      this.setState(state => ({
        pageLoading: value
      }));
    };

    this.state = {
      pageContent: true,
      pageContentClass: '',

      pageLoading: false,
      handleSetPageLoading: this.handleSetPageLoading,

      post: this.post,
      handleApiError: this.handleApiError,
    };
  }

  appInstall1() {
    window.open(strings.appstore_link, '_blank');
  }

  appInstall2() {
    window.open(strings.google_link, '_blank');
  }

  render() {
    return (
      <PageSettings.Provider value={this.state}>
        <div className='App'>
          <div className='App-download'>
            <div className='logo-div'>
              <img src={"assets/lovebattery.png"} width={'600px'} height={'293px'}/>
            </div>
            <div className='download-div'>
              <img src={"assets/btn_ios.png"} width={'160px'} height={'55px'} onClick={() => this.appInstall1()} />
              <img src={"assets/btn_googleplay.png"} width={'160px'} height={'55px'} style={{ marginLeft: 20 }} onClick={() => this.appInstall2()} />
            </div>
          </div>
          <div className='App-content'>
            <Content />
            {this.state.pageLoading && (<ProgressBar />)}
          </div>
        </div>
      </PageSettings.Provider>
    )
  }
}

export default withRouter(inject('rootStore')(App));
