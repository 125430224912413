import React from 'react';
import Zoom from 'react-medium-image-zoom'
import strings from '../../../lang/strings.js';
import { Route, Link } from 'react-router-dom';
import UserProfileImg from '../../../components/content/user_profile_img.jsx';
import U from '../../../config/util';
import 'react-medium-image-zoom/dist/styles.css'

class ChatRoomList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};

		this.onProfile = this.onProfile.bind(this);
		this.onPhoto = this.onPhoto.bind(this);
	}

	getChatUserNick(peer_id, item) {
		if (peer_id == "admin") {
			return "알림";
		} else if (peer_id == "adminchat") {
			return "관리자";
		} else {
			return item.peer_nickname;
		}
	}

	onProfile(event) {
		event.stopPropagation();
		if (this.props.onProfile) {
			this.props.onProfile();
		}
	}

	onPhoto(event) {
		event.stopPropagation();
		if (this.props.onPhoto) {
			this.props.onPhoto();
		}
	}

	render() {
		const index = this.props.index;
		const item = this.props.item;
		const list = this.props.list;
		const peer_id = this.props.peer_id;
		const otherUser = this.props.otherUser;

		var visibleTime = false;
		if (index < list.length - 1) {
			let nextDate = list[index + 1].wtime.substring(0, 7);
			let curDate = list[index].wtime.substring(0, 7);
			visibleTime = nextDate != curDate;
		} else {
			visibleTime = true;
		}
		var type = item.type;
		var kind = item.kind;

		return (
			<div style={{
				display: 'flex', flexDirection: 'column', alignItems: 'center',
				cursor: 'pointer',
			}}>
				{
					(visibleTime) &&
					<label style={{ fontSize: 10, color: '#000', width: '100%', textAlign: 'center' }}>{item.wtime.substring(0, 7)}</label>
				}
				<div style={{ height: 10 }}></div>
				{
					(type == "text" && kind == "receive") && //상대 메시지
					<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>
						<UserProfileImg onClick={this.onProfile}
							id={peer_id}
							profileImgUrl={otherUser.profile_img_url}
							sex={otherUser.sex}
							imgConfirm={otherUser.img_confirm}
							width="40"
							height="40"
						/>
						<div style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', marginLeft: 4 }}
							onClick={() => {
								if (peer_id == "adminchat" && item.content.includes("http")) {
									let url = item.content.substring(item.content.indexOf("[") + 1, item.content.lastIndexOf("]"));
									window.open(url, '_blank');
								}
							}}>
							<label style={{ fontSize: 10, color: '#474747', marginBottom: 2 }}>{this.getChatUserNick(peer_id, item)}</label>
							<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'start' }}>
								<div style={{ backgroundColor: '#FFE8F8', padding: '4px 6px', fontSize: 12, color: '#828282', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopRightRadius: 5 }}>
									<label style={{ lineBreak: 'anywhere' }} dangerouslySetInnerHTML={{ __html: U.replaceAll(item.content, "\r\n", "<br>") }}></label>
								</div>
								<label style={{ marginLeft: 4, fontSize: 8, color: '#A8A8A8' }}>{item.wtime.substring(item.wtime.length - 5)}</label>
							</div>
						</div>
					</div>
				}
				{
					(type == "text" && kind == "send") && //나의 메시지
					<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'end' }}>
						<div style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', marginLeft: 4 }}>
							<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'end' }}>
								<label style={{ marginRight: 4, fontSize: 8, color: '#A8A8A8' }}>{item.wtime.substring(item.wtime.length - 5)}</label>
								<div style={{ backgroundColor: '#FFE8F8', padding: '4px 6px', fontSize: 12, color: '#828282', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopLeftRadius: 5 }}>
									<label style={{ lineBreak: 'anywhere' }} dangerouslySetInnerHTML={{ __html: U.replaceAll(item.content, "\r\n", "<br>") }}></label>
								</div>
							</div>
						</div>
					</div>
				}
				{
					(type == "photo" && kind == "receive") && //상대 사진
					<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>
						<UserProfileImg onClick={this.onProfile}
							id={peer_id}
							profileImgUrl={otherUser.profile_img_url}
							sex={otherUser.sex}
							imgConfirm={otherUser.img_confirm}
							width="40"
							height="40"
						/>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', marginLeft: 4 }}>
							<label style={{ fontSize: 10, color: '#474747', marginBottom: 2 }}>{item.peer_nickname}</label>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'start' }}>
								<Zoom>
									<img src={item.photo_url} width={120} height={120} className='img-cover' style={{ borderRadius: 20 }} onClick={this.onPhoto} />
								</Zoom>
								<label style={{ marginLeft: 4, fontSize: 8, color: '#A8A8A8' }}>{item.wtime.substring(item.wtime.length - 5)}</label>
							</div>
						</div>
					</div>
				}
				{
					(type == "photo" && kind == "send") && //나의 사진
					<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'end' }}>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', marginLeft: 4 }}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'start' }}>
								<label style={{ marginRight: 4, fontSize: 8, color: '#A8A8A8' }}>{item.wtime.substring(item.wtime.length - 5)}</label>
								<Zoom>
									<img src={item.photo_url} width={120} height={120} className='img-cover' style={{ borderRadius: 20 }} onClick={this.onPhoto} />
								</Zoom>
							</div>
						</div>
					</div>
				}
				{
					((type == "video" || type == "audio") && kind == "receive") && //상대 음성/영성 채팅신청
					<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>
						<UserProfileImg onClick={this.onProfile}
							id={peer_id}
							profileImgUrl={otherUser.profile_img_url}
							sex={otherUser.sex}
							imgConfirm={otherUser.img_confirm}
							width="40"
							height="40"
						/>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', marginLeft: 4 }}>
							<label style={{ fontSize: 10, color: '#474747', marginBottom: 2 }}>{item.peer_nickname}</label>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'start' }}>
								<div style={{ backgroundColor: (type == "video" ? '#FF777A' : '#7E92FE'), padding: '4px 6px', fontSize: 12, color: '#828282', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopRightRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
									<img src={"assets/" + (type == "video" ? "ic_chatmsg_video.png" : "ic_chatmsg_voice.png")} width={23} height={23} className='img-contain' />
									<label style={{ fontSize: 12, color: '#fff' }}>{type == "video" ? "영상" : "음성"}채팅 신청</label>
								</div>
								<label style={{ marginLeft: 4, fontSize: 8, color: '#A8A8A8' }}>{item.wtime.substring(item.wtime.length - 5)}</label>
							</div>
						</div>
					</div>
				}
				{
					((type == "video" || type == "audio") && kind == "send") && //나의 음성/영성 채팅신청
					<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'end' }}>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', marginLeft: 4 }}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'start' }}>
								<label style={{ marginRight: 4, fontSize: 8, color: '#A8A8A8' }}>{item.wtime.substring(item.wtime.length - 5)}</label>
								<div style={{ backgroundColor: (type == "video" ? '#FF777A' : '#7E92FE'), padding: '4px 6px', fontSize: 12, color: '#828282', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopLeftRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
									<img src={"assets/" + (type == "video" ? "ic_chatmsg_video.png" : "ic_chatmsg_voice.png")} width={23} height={23} className='img-contain' />
									<label style={{ fontSize: 12, color: '#fff' }}>{type == "video" ? "영상" : "음성"}채팅 신청</label>
								</div>
							</div>
						</div>
					</div>
				}
				{
					(type == "balloon_send" && kind == "receive") && //상대 별풍선
					<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>
						<UserProfileImg onClick={this.onProfile}
							id={peer_id}
							profileImgUrl={otherUser.profile_img_url}
							sex={otherUser.sex}
							imgConfirm={otherUser.img_confirm}
							width="40"
							height="40"
						/>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', marginLeft: 4 }}>
							<label style={{ fontSize: 10, color: '#474747', marginBottom: 2 }}>{item.peer_nickname}</label>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'start' }}>
								<div style={{ backgroundColor: '#BD7AFF', padding: '4px 6px', fontSize: 12, color: '#828282', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopRightRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
									<img src={"assets/ic_chatmsg_present.png"} width={23} height={23} className='img-contain' />
									<label style={{ fontSize: 12, color: '#fff' }}>{item.content}</label>
								</div>
								<label style={{ marginLeft: 4, fontSize: 8, color: '#A8A8A8' }}>{item.wtime.substring(item.wtime.length - 5)}</label>
							</div>
						</div>
					</div>
				}
				{
					(type == "balloon_send" && kind == "send") && //나의 별풍선
					<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'end' }}>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', marginLeft: 4 }}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'start' }}>
								<label style={{ marginRight: 4, fontSize: 8, color: '#A8A8A8' }}>{item.wtime.substring(item.wtime.length - 5)}</label>
								<div style={{ backgroundColor: '#BD7AFF', padding: '4px 6px', fontSize: 12, color: '#828282', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopLeftRadius: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
									<img src={"assets/ic_chatmsg_present.png"} width={23} height={23} className='img-contain' />
									<label style={{ fontSize: 12, color: '#fff' }}>{item.content}</label>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		);
	}
}

export default ChatRoomList;