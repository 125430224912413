import React from 'react';
import { Route, Link } from 'react-router-dom';
import strings from '../../../lang/strings.js';
import U from '../../../config/util';

class PassbookList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}


	render() {
		const point = this.props.point;
        const price = this.props.price;

		return (
			<div style={{
				display: 'flex', flexDirection: 'row', alignItems: 'center',
				paddingRight: 10,
				paddingLeft: 16,
				paddingTop: 10,
				paddingBottom: 10,
				borderBottom: '1px solid #eee',
			}}>
				<img src={this.props.selectedPos == this.props.index ? "assets/ic_checkbox_on.png" : "assets/ic_checkbox_off.png"} width={24} height={22} className='img-contain'/>
				<label className={'passbook-point-label ' + (this.props.selectedPos == this.props.index ? 'selected' : '')}>{U.formatAssets(point) + 'P'}</label>
				<label className={'passbook-price-label ' + (this.props.selectedPos == this.props.index ? 'selected' : '')}>{U.formatAssets(price) + '원'}</label>
			</div>
		);
	}
}

export default PassbookList;