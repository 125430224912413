import React from 'react';
import { Route, Link } from 'react-router-dom';
import strings from '../../../lang/strings.js';
import UserProfileImg from '../../../components/content/user_profile_img.jsx';

class TopUserList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}


	render() {

		return (
			<div style={{
				display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
				paddingLeft: 17,
				paddingRight: 17,
				paddingTop: 9,
				paddingBottom: 9,
			}}>
				<div className='profile-img-div'>
					<UserProfileImg
						id={this.props.item.id}
						profileImgUrl={this.props.item.pro_img_url}
						sex={this.props.item.sex}
						imgConfirm={this.props.item.img_confirm}
						width="80"
						height="80"
					/>
					{
						this.props.index == 0 ?
							<img src={"assets/ic_star_red.png"} width={30} height={30} className='img-contain img-top' />
							: this.props.index == 1 ?
								<img src={"assets/ic_star_purple.png"} width={30} height={30} className='img-contain img-top' />
								: this.props.index == 2 ?
									<img src={"assets/ic_star_green.png"} width={30} height={30} className='img-contain img-top' />
									:
									<img src={"assets/ic_star_yellow.png"} width={30} height={30} className='img-contain img-top' />
					}
					{
						this.props.index < 2 ?
							<label className='top-rank-label'>{this.props.index + 1}</label>
							: this.props.index == 2 ?
								<label className='top-rank-label green'>{this.props.index + 1}</label>
								:
								<label className='top-rank-label red'>{this.props.index + 1}</label>
					}
				</div>
			</div>
		);
	}
}

export default TopUserList;