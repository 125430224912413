import React from 'react';
import { Route, Link } from 'react-router-dom';
import strings from '../../../lang/strings.js';
import U from '../../../config/util';

class PointHistoryList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	getContentFromType(item) {
		var result = "";
		if (item.kind == "msg") {
			if (item.states == "in") {
				result = item.memo.split(",")[0] + "님과의 쪽지 수신 보상";
			} else {
				result = item.memo.split(",")[0] + "님과의 쪽지 발송 비용";
			}
		} else if (item.kind == "msg_new") {
			if (item.states == "in") {
				result = "신규 쪽지 수신 보상";
			} else {
				result = "신규 쪽지  발송 비용";
			}
		} else if (item.kind == "chatphoto") {
			if (item.states == "in") {
				result = item.memo.split(",")[0] + "님과 사진전송 보상";
			} else {
				result = item.memo.split(",")[0] + "님과 사진수신 비용";
			}
		} else if (item.kind == "first_write_community") {
			result = "일일 첫토크 작성 보상";
		} else if (item.kind == "attendance") {
			result = "출석체크 보상";
		} else if (item.kind == "refund") {
			result = "환급 신청";
		} else if (item.kind == "freepoint") {
			result = "무료충전소 보상";
		} else if (item.kind == "group_message") {
			result = "단체쪽지 발송 비용";
		} else if (item.kind == "video") {
			if (item.states == "in") {
				result = item.memo.split(",")[0] + "님과의 영상통화 보상";
			} else {
				result = item.memo.split(",")[0] + "님과의 영상통화 비용";
			}
		} else if (item.kind == "audio") {
			if (item.states == "in") {
				result = item.memo.split(",")[0] + "님과의 음성통화 보상";
			} else {
				result = item.memo.split(",")[0] + "님과의 음성통화 비용";
			}
		} else if (item.kind == "gift") {
			if (item.states == "in") {
				result = item.memo.split(",")[0] + "님에게 포인트 선물 받음";
			} else {
				result = item.memo.split(",")[0] + "님에게 포인트 선물 보냄";
			}
		} else if (item.kind == "balloon") {
			if (item.states == "in") {
				result = item.memo.split(",")[0] + "님에게 별풍선 선물 받음";
			} else {
				result = item.memo.split(",")[0] + "님에게 별풍선 선물 보냄";
			}
		} else if (item.kind == "signup") {
			if (item.memo == null || item.memo == "") {
				result = "가입 축하 포인트";
			} else {
				result = "추천인 가입 포인트";
			}
		} else if (item.kind == "reward_nas") {
			result = "무료충전소" + "-" + item.memo;
		} else if (item.kind == "purchase") {
			result = "포인트충전";
		} else if (item.kind == "chat") {
			if (item.states == "in") {
				result = item.memo.split(",")[0] + "님과의 채팅 보상";
			} else {
				result = item.memo.split(",")[0] + "님과의 채팅 비용";
			}
		} else if (item.kind == "photo") {
			if (item.states == "in") {
				result = "사진 수신 보상";
			} else {
				result = "사진 발송 비용";
			}
		} else if (item.kind == "ranking") {
			result = item.memo;
		} else if (item.kind == "deposite") {
			result = item.memo;
		} else if (item.kind == "MMS") {
			result = item.memo;
		}
		return result;
	}

	render() {

		return (
			<div style={{
				display: 'flex', flexDirection: 'row', alignItems: 'center',
				paddingRight: 10,
				paddingLeft: 16,
				paddingTop: 10,
				paddingBottom: 10,
				borderBottom: '1px solid #eee',
			}}>
				<div className='point-history-item-div'>
					<label className='point-history-type-label'>{this.getContentFromType(this.props.item)}</label>
					<label className='point-history-date-label'>{this.props.item.event_time}</label>
				</div>

				<label className={this.props.item.states == "in" ? 'point-history-charge-label' : 'point-history-charge-label-1'}>{this.props.item.states == "in" ? "+ " : "- "}{U.formatAssets(this.props.item.points)}</label>
			</div>
		);
	}
}

export default PointHistoryList;