export default {
    app_name: "러브밧데리",
    login: "로그인",
    join: "회원가입",
    dashboard: "대시보드",
    phone_number: "전화번호",
    cert_number: "인증코드",
    tab_live: "라이브",
    tab_talk: "토크",
    tab_rank: "랭킹",
    tab_chat: "채팅",
    tab_more: "더보기",
    live_top: "TOP 10",
    all: "전체",
    friend: "친구",
    male: "남자",
    female: "여자",
    today: "오늘",
    this_week: "이번주",
    last_week: "지난주",
    sort_login: "접속순",
    sort_like: "인기순",
    sort_register: "좋아요",
    chat_all_read: "전체 읽음 처리",
    chat_all_delete: "전체 삭제",
    empty_chat_room: "쪽지함이 비었습니다.",
    empty_friend_list: "새로운 친구를 만들어 보세요.",
    empty_block_list: "차단 목록이 비었습니다.",
    gifticon: "기프티콘",
    passbook: "무통장입금",
    usage_history: "사용내역",
    attendance: "출석체크",
    setting: "설정",
    friend_list: "친구목록",
    block_list: "차단목록",
    usage_guide: "이용안내",
    customer_service: "고객센터",
    notice_event: "공지&이벤트",
    notice_event_title: "공지사항&이벤트",
    tab_notice: "공지사항",
    tab_event: "이벤트",
    rank_guide_desc1: "랭킹 시스템 & 보상안내",
    rank_guide_desc2: "자세한 내용은 이곳을 터치해주세요!",
    write_msg: "쪽지쓰기",
    in_video: "영상중",
    request_video: "영상요청",
    point_charge: "포인트 구매",
    my_point: "내포인트",
    point_history: "적립 및 사용내역",
    owned_point: "보유한 포인트",
    delete: "삭제하기",
    event_prepare: "이벤트 준비중입니다.",
    deposit_name: "입금자명",
    input_deposit_name: "입금자명을 입력해주세요.",
    passbook_desc: "입금자명을 기재후, 입금신청을 누르시면<br/>입금해야 될 계좌번호가 표시됩니다.",
    request_deposit: "입금신청",
    alarm_setting: "알림설정",
    alarm: "알림",
    sound: "소리",
    vibrate: "진동",
    app_version: "앱버전",
    withdrawal: "계정 삭제하기",
    usage_policy: "이용약관",
    privacy_policy: "개인정보 취급방침",
    profile: "프로필",
    do_report: "신고하기",
    do_block: "차단하기",
    video_average_score: "영상통화 평가점수",
    video_total_score: "영상통화 스코어",
    save: "저장",
    profile_edit_photo_desc: "새로운 사진 첨부시 운영팀 확인을 거치며<br>승인된 사진은 승인상태가 계속 유지됩니다.",
    gender: "성별",
    male: "남자",
    female: "여자",
    nickname: "닉네임",
    age: "나이",
    topic: "대화주제",
    profile_edit_desc: "홍보목적이나 외설적인 단어가 포함된 닉네임은<br>통보없이 필터링 될수 있습니다.",
    select_topic: "대화주제를 선택하세요.",
    select_age: "나이를 선택하세요.",
    input_nickname: "닉네임을 입력해주세요.",
    refund_request: "환급신청",
    refund_history: "환급 신청내역",
    refund_title: "환급 신청 정보 활용 동의",
    refund_content: "1. 출금 신청한 포인트는 100%금액이 현금으로 지급됩니다.<br><br>2. 환급신청시 예금주와 전화번호, 가입자의 정보가 일치하여야 하며 불일치시 환급은 보류됩니다.<br><br>3. 환급 신청으로 취득한 개인정보는 소득신고 자료로만 활용하며, 삭제를 원하시면 언제든지 삭제 가능합니다.<br><br>4. 3만 포인트부터 신청 가능합니다.<br><br>5. 송금 수수료는 저희가 부담합니다.",
    refund_content_2: "· 신청하신 환급건은 월요일 ~ 일요일 매일 통장으로 송금해드립니다.<br><br>· 휴일없이 일일 처리해드립니다.",
    refund_content_3: "환급 신청에 필요한 정보수집 및 활용에 동의합니다.",
    confirm: "확인",
    request: "신청",
    depositor_name: "예금주명",
    input: "입력해주세요.",
    phone_number2: "휴대폰번호",
    input2: "입력해주세요.(- 제외)",
    input3: "세금신고 목적으로만 활용",
    jumin_num: "주민번호",
    home_address: "자택주소",
    account_num: "계좌번호",
    bank: "은행",
    select_bank: "은행선택",
    req_point: "신청포인트",
    available_req_point: "- 3만 포인트이상 신청이 가능합니다.",
    refund_list: "· 환전리스트",
    no_refund_history: "환전신청 내역이 없습니다.<br>지금 포인트를 적립후에 현금으로 환전해보세요!",
    bank_account: "신청계좌",
    req_pint: "신청액",
    refund_point: "입금액",
    req_time: "신청일",
    refund_time: "입금일",
    req_status: "상태",
    req_status_1: "대기",
    req_status_2: "완료",
    chat: "채팅",
    chat_desc: "청소년 보호법 상 음란물, 성매매 등 불법행위가 채팅방 내에서<br>사용될 경우 법적 처벌 받을 수 있습니다.",
    block: "차단",
    report: "신고",
    video_connect: "영상통화",
    voice_connect: "음성통화",
    add_friend: "친구추가",
    msg_input: "메세지를 입력해주세요.",
    voice_call: "음성통화",
    video_call: "영상통화",
    rank_system: "랭킹점수시스템",
    rank_system_guide_1: "· 랭킹은 실시간으로 갱신되며, 월요일에 주간 랭킹이 갱신됩니다.<br><br>· 높은 랭킹을 가진 유저일수록 영상통화 연결 확률이 높습니다.<br><br>· 랭킹에 기록되면 하단에 명시된 랭킹별 보상포인트를 매일, 매주 지급해 드립니다.",
    passport_desc: "무통장 입금신청이 완료되었습니다.<br>아래의 계좌로 입금해주시면 포인트가<br>자동 적립됩니다.",
    report_desc: "건전한 서비스 환경을 저해하는 비매너,<br>불량 사용자는 신고해주세요.",
    use_app_version: "앱에서만 가능합니다.<br>앱을 설치하시겠습니까?",
    not_view_same_gender: "동성 프로필은 확인 할수 없습니다.",
    not_msg_same_gender: "동성에게는 쪽지를 보낼 수 없습니다.",
    not_change_gender: "성별은 변경하실 수 없습니다.",
    google_link: "https://play.google.com/store/apps/details?id=com.battery.lovebattery",
    appstore_link: "https://itunes.apple.com/app/id1511120791"
}