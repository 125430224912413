import React from 'react';
import strings from '../../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../../config/page-settings.js';
import { inject } from 'mobx-react';
import UserProfileImg from '../../../components/content/user_profile_img.jsx';
import SendMsgDialog from "../../dlg/send-msg-dialog.js";
import ReportDialog from "../../dlg/report-dialog.js";
import ConfirmDialog from "../../dlg/confirm-dialog.js";
import AlertDialog from "../../dlg/alert-dialog.js";

import $ from 'jquery';
import U from '../../../config/util';

class Profile extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            show_chat_popup: false,
            show_report_popup: false,
            show_block_popup: false,
            show_friend_popup: false,
            show_charge_popup: false,
            show_alert_popup: false,
            alert_msg: "",
            item: null,
            show_install_popup: false,
        };

        this.onBack = this.onBack.bind(this);
        this.onChat = this.onChat.bind(this);
        this.onReport = this.onReport.bind(this);
        this.onBlock = this.onBlock.bind(this);
        this.onLikeUser = this.onLikeUser.bind(this);
        this.onAddFriend = this.onAddFriend.bind(this);
    }

    componentWillMount() {
        this.setState({
            item: JSON.parse(sessionStorage.getItem('selectedUser'))
        })
    }

    onBack(event) {
        event.preventDefault();
        this.props.history.goBack();
    }

    onChat(event) {
        let self = this;
        self.context.post(
            'isChatRoomOpen',
            {
                id: this.props.rootStore.user.id,
                peer_id: this.state.item.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    //채팅방 열림-이동
                    sessionStorage.setItem('selectedPeerId', self.state.item.id);
                    self.props.history.push('/chat');
                } else if (data.result == "fail") {
                    self.setState({
                        show_chat_popup: true
                    });
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onReport(event) {
        this.setState({
            show_report_popup: true
        });
    }

    onBlock(event) {
        this.setState({
            show_block_popup: true
        });
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    reportMember(type, content) {
        let self = this;
        self.context.post(
            'reportMember',
            {
                w_id: this.props.rootStore.user.id,
                w_nic: this.props.rootStore.user.nickname,
                w_sex: this.props.rootStore.user.sex,
                r_id: this.state.item.id,
                r_nic: this.state.item.nickname,
                r_sex: this.state.item.sex,
                r_reason: type,
                r_report: content,
                v_roomid: "PROFILE",
                v_seconds: "0",
                v_mode: "profile",
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("신고되었습니다.");
                    self.props.history.goBack();
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    blockUser() {
        let self = this;
        self.context.post(
            'addFriendBlock',
            {
                type: "block",
                id: this.props.rootStore.user.id,
                peer_id: this.state.item.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("상대방을 차단했습니다.");
                    self.props.history.goBack();
                } else if (data.result == "already") {
                    self.onAlert("이미 차단된 유저입니다.");
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onSendMsg(msg) {
        if (Number(this.props.rootStore.systempoint.priceMsg) > Number(this.props.rootStore.user.point)) {
            if (this.props.rootStore.user.sex == "m") {
                this.setState({
                    show_charge_popup: true
                });
            } else {
                this.onAlert("포인트가 부족합니다.");
            }
            return;
        }

        let self = this;
        self.context.post(
            'newMessage',
            {
                id: this.props.rootStore.user.id,
                peer_id: this.state.item.id,
                content: msg,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("쪽지 발송되었습니다.");

                    let user = self.props.rootStore.user;
                    user.point = data.point;
                    self.props.rootStore.login(user);
                } else if (data.result == "userblock") {
                    self.onAlert("상대방이 차단했습니다.");
                } else if (data.result == "notenough") {
                    self.onAlert("포인트가 부족합니다.");
                } else if (data.result == "fail") {
                    self.onAlert(data.msg);
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onLikeUser() {
        let self = this;
        self.context.post(
            'likeUser',
            {
                id: this.props.rootStore.user.id,
                peer_id: this.state.item.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("좋아요 했습니다.");
                } else if (data.result == "fail") {
                    self.onAlert("이미 좋아요 했습니다.");
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onAddFriend() {
        this.setState({
            show_friend_popup: true
        });
    }

    friendUser() {
        let self = this;
        self.context.post(
            'addFriendBlock',
            {
                type: "add",
                id: this.props.rootStore.user.id,
                peer_id: this.state.item.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("친구로 추가되었습니다.");
                } else if (data.result == "already") {
                    self.onAlert("이미 친구로 추가되었습니다.");
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    appInstall() {
        this.setState({
            show_install_popup: true
        })
    }

    appInstallLink() {
        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
        if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
            window.open(strings.appstore_link, '_blank');
        } else {
            window.open(strings.google_link, '_blank');
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.item != null &&
                    <div className='dashboard-div'>
                        <ConfirmDialog
                            title="알림"
                            msg={strings.use_app_version}
                            cancelBtn="아니"
                            okBtn="예"
                            isOpen={this.state.show_install_popup}
                            onClose={() => {
                                this.setState({ show_install_popup: false });
                            }}
                            onCancel={() => {
                                this.setState({ show_install_popup: false });
                            }}
                            onConfirm={() => {
                                this.setState({ show_install_popup: false });
                                this.appInstallLink();
                            }} />
                        <SendMsgDialog
                            rootStore={this.props.rootStore}
                            peer={this.state.item}
                            isOpen={this.state.show_chat_popup}
                            onClose={() => {
                                this.setState({ show_chat_popup: false });
                            }}
                            onSend={(msg) => {
                                this.setState({ show_chat_popup: false });
                                this.onSendMsg(msg);
                            }} />
                        <ReportDialog isOpen={this.state.show_report_popup}
                            onClose={() => {
                                this.setState({ show_report_popup: false });
                            }}
                            onReport={(type, content) => {
                                this.setState({ show_report_popup: false });
                                this.reportMember(type, content);
                            }} />
                        <ConfirmDialog
                            title="알림"
                            msg={this.state.item.nickname + "님을 차단하시겠습니까?<br>차단된 유저가 보내는 메시지는 받을 수 없으며<br>채팅방은 삭제됩니다."}
                            cancelBtn="아니요"
                            okBtn="네"
                            isOpen={this.state.show_block_popup}
                            onClose={() => {
                                this.setState({ show_block_popup: false });
                            }}
                            onCancel={() => {
                                this.setState({ show_block_popup: false });
                            }}
                            onConfirm={() => {
                                this.setState({ show_block_popup: false });
                                this.blockUser();
                            }} />
                        <ConfirmDialog
                            title="알림"
                            msg={this.state.item.nickname + "님을 친구로<br>추가하시겠습니까?"}
                            cancelBtn="아니요"
                            okBtn="네"
                            isOpen={this.state.show_friend_popup}
                            onClose={() => {
                                this.setState({ show_friend_popup: false });
                            }}
                            onCancel={() => {
                                this.setState({ show_friend_popup: false });
                            }}
                            onConfirm={() => {
                                this.setState({ show_friend_popup: false });
                                this.friendUser();
                            }} />
                        <ConfirmDialog
                            title="알림"
                            msg="포인트가 부족하십니다.<br>포인트를 충전 하시겠습니까?"
                            cancelBtn="닫기"
                            okBtn="충전"
                            isOpen={this.state.show_charge_popup}
                            onClose={() => {
                                this.setState({ show_charge_popup: false });
                            }}
                            onCancel={() => {
                                this.setState({ show_charge_popup: false });
                            }}
                            onConfirm={() => {
                                this.setState({ show_charge_popup: false });
                                this.props.history.push('/pointcharge');
                            }} />
                        <AlertDialog
                            msg={this.state.alert_msg}
                            isOpen={this.state.show_alert_popup}
                            onClose={() => {
                                this.setState({ show_alert_popup: false });
                            }} />

                        <div className='common-header-div borderb'>
                            <div className='common-header-back-div' onClick={this.onBack}>
                                <img src={"assets/btn_back.png"} width={15} height={13} />
                            </div>
                            <label className='common-header-title-label'>{strings.profile}</label>
                        </div>

                        <div style={{ position: 'relative', height: 150 }}>
                            <div className='profile-img-div' style={{ width: 130, height: 130, position: 'absolute', top: 20, left: 0, right: 0, margin: 'auto' }}>
                                <UserProfileImg
                                    id={this.state.item.id}
                                    profileImgUrl={this.state.item.profile_img_url}
                                    sex={this.state.item.sex}
                                    imgConfirm={this.state.item.img_confirm}
                                    width="130"
                                    height="130"
                                />
                            </div>

                            <div style={{ position: 'absolute', top: 10, right: 15, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div className='profile-report-div' onClick={this.onReport}>
                                    신고하기
                                </div>

                                <div className='profile-block-div' style={{ marginTop: 5 }} onClick={this.onBlock}>
                                    차단하기
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: 10, textAlign: 'center' }}>
                            <label style={{ color: '#474747', fontSize: 18 }}>{this.state.item.nickname}</label>
                        </div>
                        <div style={{ marginTop: 4, textAlign: 'center' }}>
                            <label style={{ color: '#828282', fontSize: 14 }}>{this.state.item.profile_msg}</label>
                        </div>
                        <div style={{ marginTop: 15, textAlign: 'center' }}>
                            <img src={"assets/btn_detail_videochat.png"} width={48} height={48} onClick={() => this.appInstall()} />
                            <img src={"assets/btn_detail_voicechat.png"} width={48} height={48} style={{ marginLeft: 10 }} onClick={() => this.appInstall()} />
                            <img src={"assets/btn_detail_msgchat.png"} width={48} height={48} style={{ marginLeft: 10 }} onClick={this.onChat} />
                            <img src={"assets/btn_detail_like.png"} width={48} height={48} style={{ marginLeft: 10 }} onClick={this.onLikeUser} />
                            <img src={"assets/btn_detail_recommend.png"} width={48} height={48} style={{ marginLeft: 10 }} onClick={this.onAddFriend} />
                        </div>

                        <div className='dashed-line-div' style={{ marginTop: 20, marginLeft: 20, marginRight: 20 }}></div>

                        <div className='profile-score-div'>
                            <div className='profile-score-sub-div'>
                                <label style={{ color: '#828282', fontSize: 13 }}>{strings.video_average_score}</label>
                                <div>
                                    <img src={"assets/ic_detail_star.png"} width={12} height={12} />
                                    <label style={{ color: '#9C27B0', fontSize: 13 }}>{this.state.item.avg}</label>
                                    <label style={{ color: '#A8A8A8', fontSize: 13 }}>/{this.state.item.sum}</label>
                                </div>
                            </div>
                            <div className='profile-score-sub-div'>
                                <label style={{ color: '#828282', fontSize: 13 }}>{strings.video_total_score}</label>
                                <div>
                                    <img src={"assets/ic_videochat_score.png"} width={12} height={12} />
                                    <label style={{ color: '#FF7A7D', fontSize: 13 }}>{U.formatAssets(this.state.item.all_score)}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default withRouter(inject('rootStore')(Profile));