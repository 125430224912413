import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings.js';
import { inject } from 'mobx-react';

import $ from 'jquery';
import U from '../../config/util';
import BlockUserList from './cell/blockuser-list.jsx';
import ConfirmDialog from "../dlg/confirm-dialog.js";
import AlertDialog from "../dlg/alert-dialog.js";

class BlockList extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            pageNum: 0,
            isLast: false,
            blockList: [],
            loading: false,
            show_block_del_popup: false,
            selectedIndex: -1,
            show_alert_popup: false,
            alert_msg: ""
        };

        this.onBack = this.onBack.bind(this);
    }

    componentDidMount() {
        this.getBlockList();
    }

    getBlockList = () => {
        if (this.state.isLast || this.state.loading) {
            return;
        }

        this.setState({
            loading: true
        })
        let self = this;
        self.context.post(
            'getFriendBlockList',
            {
                id: this.props.rootStore.user.id,
                stype: "block",
                page: this.state.pageNum
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    if (data.friendList.length == 0) {
                        self.setState({
                            isLast: true
                        })
                    }
                    if (self.state.pageNum == 0) {
                        self.setState({
                            blockList: data.friendList
                        })
                    } else {
                        let list = self.state.blockList.concat(data.friendList);
                        self.setState({
                            blockList: list
                        })
                    }
                }
                self.setState({
                    loading: false
                })
            });
    };

    onBack(event) {
        event.preventDefault();
        this.props.history.goBack();
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 5
        if (bottom) {
            this.setState({
                pageNum: this.state.pageNum + 1
            }, () => {
                this.getBlockList();
            })

        }
    }

    onBlockDel(index) {
        this.setState({
            selectedIndex: index,
            show_block_del_popup: true
        })
    }

    reqBlockDel() {
        let peer = this.state.blockList[this.state.selectedIndex];

        let self = this;
        self.context.post(
            'deleteBlockUser',
            {
                id: this.props.rootStore.user.id,
                ind: peer.ind
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("차단 해제되었습니다.");

                    let filteredArray = self.state.blockList.filter(item => item !== peer)
                    self.setState({
                        selectedIndex: -1,
                        blockList: filteredArray
                    })
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className='dashboard-div'>
                    <AlertDialog
                        msg={this.state.alert_msg}
                        isOpen={this.state.show_alert_popup}
                        onClose={() => {
                            this.setState({ show_alert_popup: false });
                        }} />
                    <ConfirmDialog
                        title="알림"
                        msg="정말 삭제하시겠습니까?"
                        cancelBtn="취소"
                        okBtn="확인"
                        isOpen={this.state.show_block_del_popup}
                        onClose={() => {
                            this.setState({ show_block_del_popup: false });
                        }}
                        onCancel={() => {
                            this.setState({ show_block_del_popup: false });
                        }}
                        onConfirm={() => {
                            this.setState({ show_block_del_popup: false });
                            this.reqBlockDel();
                        }} />

                    <div className='common-header-div borderb'>
                        <div className='common-header-back-div' onClick={this.onBack}>
                            <img src={"assets/btn_back.png"} width={15} height={13} />
                        </div>
                        <label className='common-header-title-label'>{strings.block_list}</label>
                    </div>

                    <div className='scroll-blocklist-div' onScroll={this.handleScroll}>
                        {
                            this.state.blockList.length == 0 ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 40 }}>
                                    <label className='tab-label'>{strings.empty_block_list}</label>
                                </div>
                                :
                                <ul>
                                    {
                                        this.state.blockList.map((item, index) => {
                                            return <li key={index}>
                                                <BlockUserList
                                                    index={index}
                                                    item={item}
                                                    onBlockDel={(index) => {
                                                        this.onBlockDel(index);
                                                    }}
                                                />
                                            </li>
                                        })
                                    }
                                </ul>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(inject('rootStore')(BlockList));