import {computed, decorate, observable} from 'mobx';

var $ = require('jquery');

function getWindowDimensions() {
    return {
        width: $(window).width(),
        height: $(window).height()
    };
}

export class UiState {
    language = "en_us";
    pendingRequestCount = 0;

    // .struct makes sure observer won't be signaled unless the
    // dimensions object changed in a deepEqual manner
    windowDimensions = getWindowDimensions;

    constructor() {
        $(window).resize(() => {
            this.windowDimensions = getWindowDimensions();
        });
    }

    get appIsInSync() {
        return this.pendingRequestCount === 0
    }
}

decorate(UiState, {
    language: observable,
    pendingRequestCount: observable,
    appIsInSync: computed,
    windowDimensions: observable.struct
});

