import React from 'react';
import strings from '../../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../../config/page-settings.js';
import { inject } from 'mobx-react';
import UserProfileImg from '../../../components/content/user_profile_img.jsx';
import ChatMsgList from '../cell/chat-msg-list.jsx';
import AlertDialog from "../../dlg/alert-dialog.js";
import ConfirmDialog from "../../dlg/confirm-dialog.js";
import ReportDialog from "../../dlg/report-dialog.js";

import $ from 'jquery';
import U from '../../../config/util';

class Chat extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            mOtherUser: null,
            chatList: [],
            loading: false,
            isEnd: 0,
            lastIndex: 0,
            firstIndex: 0,
            show_alert_popup: false,
            alert_msg: "",
            show_block_popup: false,
            show_report_popup: false,
            show_friend_popup: false,
            show_charge_popup: false,
            chatTextContent: "",
            peer_id: 0,
            show_install_popup: false,
        };

        this.onBack = this.onBack.bind(this);
        this.onBlock = this.onBlock.bind(this);
        this.onReport = this.onReport.bind(this);
        this.onAddFriend = this.onAddFriend.bind(this);
        this.chatTextSend = this.chatTextSend.bind(this);
    }

    componentWillMount() {
        this.setState({
            peer_id: sessionStorage.getItem('selectedPeerId')
        })
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidMount() {
        this.getOtherUserInfo();

        this.interval = setInterval(() => {
            this.drawChatList(false, false);
        }, 1000 * 10);
    }

    getOtherUserInfo() {
        //상대회원 상세정보 얻기
        let self = this;
        self.context.post(
            'otherUserInfo',
            {
                id: this.state.peer_id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.setState({
                        mOtherUser: data
                    }, () => {
                        self.drawChatList(true, false);
                    })
                } else if (data.result == "fail") {
                    if (data.msg == "findnoid") {
                        self.onAlert("유저가 존재하지 않습니다.");
                    }
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onBack(event) {
        event.preventDefault();
        this.props.history.goBack();
    }

    drawBeforeChatList() {
        this.setState({
            isEnd: 2
        });
        if (this.state.loading) {
            return;
        }

        let self = this;
        self.context.post(
            'getChatList',
            {
                id: this.props.rootStore.user.id,
                peer_id: this.state.peer_id,
                kind: "before",
                first_ind: this.state.firstIndex,
                last_ind: this.state.lastIndex
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);

                self.setState({
                    isEnd: 1
                });
                if (data.result == "success") {
                    self.setState({
                        firstIndex: Number(data.first_ind)
                    })

                    let list = self.state.chatList.concat(data.chatList);
                    self.setState({
                        chatList: list
                    })

                    if (Number(data.last_ind) == 0) {
                        self.setState({
                            isEnd: 0
                        });
                    }
                }
            });
    }

    drawChatList(bNewLoad, isChkReed) {
        if (this.state.loading) {
            return;
        }
        if (bNewLoad && !isChkReed) {
            this.setState({
                firstIndex: 0,
                lastIndex: 0,
                chatList: [],
            })
            // Timer(Duration(milliseconds: 0), () => _controller.jumpTo(0));
        }

        let self = this;
        self.context.post(
            'getChatList',
            {
                id: this.props.rootStore.user.id,
                peer_id: this.state.peer_id,
                kind: "after",
                first_ind: this.state.firstIndex,
                last_ind: this.state.lastIndex
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    if (bNewLoad || isChkReed) {
                        if (!isChkReed) {
                            self.setState({
                                firstIndex: Number(data.first_ind)
                            })
                        }
                    }
                    if (Number(data.last_ind) != 0) {
                        self.setState({
                            lastIndex: Number(data.last_ind)
                        })
                    }
                    self.setState({
                        isEnd: 1
                    })

                    if (bNewLoad) {
                        let list = self.state.chatList.concat(data.chatList);
                        self.setState({
                            chatList: list
                        }, () => {
                            self.chatDivRef.scrollTo({
                                left: 0,
                                top: 3000
                            })
                        })
                    } else {
                        let list = data.chatList.concat(self.state.chatList);
                        self.setState({
                            chatList: list
                        }, () => {
                            self.chatDivRef.scrollTo({
                                left: 0,
                                top: 3000
                            })
                        })
                    }
                }
            });
    }

    reportMember(type, content) {
        let self = this;
        self.context.post(
            'reportMember',
            {
                w_id: this.props.rootStore.user.id,
                w_nic: this.props.rootStore.user.nickname,
                w_sex: this.props.rootStore.user.sex,
                r_id: this.state.peer_id,
                r_nic: this.state.mOtherUser.nickname,
                r_sex: this.state.mOtherUser.sex,
                r_reason: type,
                r_report: content,
                v_roomid: "PROFILE",
                v_seconds: "0",
                v_mode: "profile",
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("신고되었습니다.");
                    self.props.history.goBack();
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    onBlock(event) {
        this.setState({
            show_block_popup: true
        });
    }

    blockUser() {
        let self = this;
        self.context.post(
            'addFriendBlock',
            {
                type: "block",
                id: this.props.rootStore.user.id,
                peer_id: this.state.peer_id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.props.history.goBack();
                } else if (data.result == "already") {
                    self.onAlert("이미 차단된 유저입니다.");
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onReport(event) {
        this.setState({
            show_report_popup: true
        });
    }

    onAddFriend() {
        this.setState({
            show_friend_popup: true
        });
    }

    friendUser() {
        let self = this;
        self.context.post(
            'addFriendBlock',
            {
                type: "add",
                id: this.props.rootStore.user.id,
                peer_id: this.state.peer_id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("친구로 추가되었습니다.");
                } else if (data.result == "already") {
                    self.onAlert("이미 친구로 추가되었습니다.");
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onImageChange = (e) => {
        //point check
        if (this.props.rootStore.user.sex == "m" && Number(this.props.rootStore.user.point) < Number(this.props.rootStore.systempoint.pricePhoto)) {
            this.setState({
                show_charge_popup: true
            });
            return;
        }

        let self = this;

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            self.sendPhotoChat(file);
        };
        reader.readAsDataURL(file);
    };

    sendPhotoChat(file) {
        let formData = new FormData();
        formData.append('uploadfile', file);
        formData.append('id', this.props.rootStore.user.id);
        formData.append('peer_id', this.state.peer_id);

        let self = this;
        self.context.post(
            'sendPhotoChat',
            formData,
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.setState({
                        chatTextContent: ""
                    });
                    self.getPointInfo();
                    self.drawChatList(false, false);
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    getPointInfo() {
        let self = this;
        self.context.post(
            'getPointInfo',
            {
                id: this.props.rootStore.user.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    let user = self.props.rootStore.user;
                    user.point = data.point;
                    self.props.rootStore.login(user);
                }
            });
    }

    onDataChange = (e) => {
        this.setState({
            chatTextContent: e.target.value
        });
    };

    chatTextSend() {
        if (this.state.chatTextContent.trim() == "") {
            this.onAlert("대화 내용을 입력해 주세요.");
            return;
        }

        //point check
        if (this.props.rootStore.user.sex == "m" && this.props.rootStore.user.level == "1" && Number(this.props.rootStore.user.point) < Number(this.props.rootStore.systempoint.priceChat)) {
            this.setState({
                show_charge_popup: true
            });
            return;
        }

        let self = this;
        self.context.post(
            'sendChat',
            {
                id: this.props.rootStore.user.id,
                kind: 'text',
                pay_type: this.state.mOtherUser.sex == "f" ? "wait" : "no",
                content: this.state.chatTextContent.trim(),
                peer_id: this.state.peer_id,
                peer_nick: this.state.mOtherUser.nickname
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.setState({
                        chatTextContent: ""
                    });
                    self.getPointInfo();
                    self.drawChatList(false, false);
                }
            });
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollTop === 0
        if (bottom) {
            if (this.state.isEnd == 1) {
                this.drawBeforeChatList();
            }
        }
    }

    appInstall() {
        this.setState({
            show_install_popup: true
        })
    }

    appInstallLink() {
        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
        if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
            window.open(strings.appstore_link, '_blank');
        } else {
            window.open(strings.google_link, '_blank');
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.peer_id != 0 &&
                    <div className='dashboard-div'>
                        <ConfirmDialog
                            title="알림"
                            msg={strings.use_app_version}
                            cancelBtn="아니"
                            okBtn="예"
                            isOpen={this.state.show_install_popup}
                            onClose={() => {
                                this.setState({ show_install_popup: false });
                            }}
                            onCancel={() => {
                                this.setState({ show_install_popup: false });
                            }}
                            onConfirm={() => {
                                this.setState({ show_install_popup: false });
                                this.appInstallLink();
                            }} />
                        <AlertDialog
                            msg={this.state.alert_msg}
                            isOpen={this.state.show_alert_popup}
                            onClose={() => {
                                this.setState({ show_alert_popup: false });
                            }} />
                        <ConfirmDialog
                            title="알림"
                            msg={(this.state.mOtherUser == null ? "" : this.state.mOtherUser.nickname) + "님을 차단하시겠습니까?<br>차단된 유저와는 다시<br>연결되지 않습니다."}
                            cancelBtn="아니요"
                            okBtn="차단"
                            isOpen={this.state.show_block_popup}
                            onClose={() => {
                                this.setState({ show_block_popup: false });
                            }}
                            onCancel={() => {
                                this.setState({ show_block_popup: false });
                            }}
                            onConfirm={() => {
                                this.setState({ show_block_popup: false });
                                this.blockUser();
                            }} />
                        <ReportDialog isOpen={this.state.show_report_popup}
                            onClose={() => {
                                this.setState({ show_report_popup: false });
                            }}
                            onReport={(type, content) => {
                                this.setState({ show_report_popup: false });
                                this.reportMember(type, content);
                            }} />
                        <ConfirmDialog
                            title="알림"
                            msg={(this.state.mOtherUser == null ? "" : this.state.mOtherUser.nickname) + "님을 친구로<br>추가하시겠습니까?"}
                            cancelBtn="아니요"
                            okBtn="네"
                            isOpen={this.state.show_friend_popup}
                            onClose={() => {
                                this.setState({ show_friend_popup: false });
                            }}
                            onCancel={() => {
                                this.setState({ show_friend_popup: false });
                            }}
                            onConfirm={() => {
                                this.setState({ show_friend_popup: false });
                                this.friendUser();
                            }} />
                        <ConfirmDialog
                            title="알림"
                            msg="포인트가 부족하십니다.<br>포인트를 충전 하시겠습니까?"
                            cancelBtn="닫기"
                            okBtn="충전"
                            isOpen={this.state.show_charge_popup}
                            onClose={() => {
                                this.setState({ show_charge_popup: false });
                            }}
                            onCancel={() => {
                                this.setState({ show_charge_popup: false });
                            }}
                            onConfirm={() => {
                                this.setState({ show_charge_popup: false });
                                this.props.history.push('/pointcharge');
                            }} />
                        <div className='common-header-div borderb'>
                            <div className='common-header-back-div' onClick={this.onBack}>
                                <img src={"assets/btn_back.png"} width={15} height={13} />
                            </div>
                            {
                                this.state.mOtherUser != null &&
                                <label className='common-header-title-label'>{this.state.mOtherUser.nickname}</label>
                            }
                            {
                                (!(this.state.peer_id == "admin" || this.state.peer_id == "adminchat") && this.state.mOtherUser != null) &&
                                <label className='common-header-title-label'>({this.state.mOtherUser.age}세/{this.state.mOtherUser.sex == "m" ? "남자" : "여자"})</label>
                            }
                        </div>

                        <div className='chat-desc-label'>
                            <label dangerouslySetInnerHTML={{ __html: strings.chat_desc }}></label>
                        </div>
                        {
                            !(this.state.peer_id == "admin" || this.state.peer_id == "adminchat") &&
                            <div className='chat-menu-div'>
                                <div className='chat-menu-item-div' onClick={this.onBlock}>
                                    <img src={"assets/ic_chat_stop.png"} width={23} height={23} className='img-contain' />
                                    <label style={{ fontSize: 11, color: '#B5B5B5' }}>{strings.block}</label>
                                </div>
                                <div className='chat-menu-item-div' onClick={this.onReport}>
                                    <img src={"assets/ic_chat_block.png"} width={23} height={23} className='img-contain' />
                                    <label style={{ fontSize: 11, color: '#B5B5B5' }}>{strings.report}</label>
                                </div>
                                <div className='chat-menu-item-div' onClick={() => this.appInstall()} >
                                    <img src={"assets/ic_chat_call.png"} width={23} height={23} className='img-contain' />
                                    <label style={{ fontSize: 11, color: '#B5B5B5' }}>{strings.voice_connect}</label>
                                </div>
                                <div className='chat-menu-item-div' onClick={() => this.appInstall()} >
                                    <img src={"assets/ic_chat_video.png"} width={23} height={23} className='img-contain' />
                                    <label style={{ fontSize: 11, color: '#B5B5B5' }}>{strings.video_connect}</label>
                                </div>
                                <div className='chat-menu-item-div' onClick={this.onAddFriend}>
                                    <img src={"assets/ic_chat_addfriend.png"} width={23} height={23} className='img-contain' />
                                    <label style={{ fontSize: 11, color: '#B5B5B5' }}>{strings.add_friend}</label>
                                </div>
                            </div>
                        }

                        <div onScroll={this.handleScroll} ref={(ref) => this.chatDivRef = ref} style={{ height: this.state.peer_id == "admin" ? 'calc(100% - 94px)' : this.state.peer_id == "adminchat" ? 'calc(100% - 146px)' : 'calc(100% - 196px)', overflowY: 'auto', padding: 10 }}>
                            <ul style={{ display: 'flex', flex: 1, flexDirection: 'column-reverse' }}>
                                {
                                    this.state.chatList.map((item, index) => {
                                        return <li key={index}>
                                            <ChatMsgList
                                                index={index}
                                                item={item}
                                                list={this.state.chatList}
                                                peer_id={this.state.peer_id}
                                                otherUser={this.state.mOtherUser}
                                                onProfile={() => {
                                                    if (this.state.peer_id == "admin" || this.state.peer_id == "adminchat") {
                                                        return;
                                                    }
                                                    if (this.state.mOtherUser.profile_img_url == null || this.state.mOtherUser.profile_img_url == "") {
                                                        return;
                                                    }
                                                    if (this.state.mOtherUser.img_confirm == "0") {
                                                        return;
                                                    }
                                                    // this.props.history.push({
                                                    //     pathname: '/photo_view',
                                                    //     appState: {
                                                    //         url: this.state.mOtherUser.profile_img_url
                                                    //     }
                                                    // });
                                                }}
                                                onPhoto={() => {
                                                    // this.props.history.push({
                                                    //     pathname: '/photo_view',
                                                    //     appState: {
                                                    //         url: item.photo_url
                                                    //     }
                                                    // });
                                                }}
                                            />
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        {
                            this.state.peer_id != "admin" &&
                            <div className='chat-msg-div'>
                                {
                                    this.state.peer_id != "adminchat" ?
                                        <div className='chat-msg-item-div' onClick={() => this.imageRef.click()}>
                                            <img src={"assets/btn_chat_plus.png"} width={20} height={20} className='img-contain' />
                                            <input type='file' accept='image/*' className='hide'
                                                onChange={(e) => this.onImageChange(e)}
                                                ref={(ref) => this.imageRef = ref} />
                                        </div>
                                        :
                                        <div style={{ width: '14px' }}></div>
                                }
                                <div className='chat-msg-input-div'>
                                    <textarea id={'msg_content'} type="text" autoComplete={'off'}
                                        className="msg-input-box" placeholder={strings.msg_input}
                                        value={this.state.chatTextContent}
                                        onChange={(e) => this.onDataChange(e)}
                                        rows={1}
                                        required />
                                </div>
                                <div className='chat-msg-item-div' onClick={this.chatTextSend}>
                                    <img src={"assets/btn_send_noti.png"} width={20} height={20} className='img-contain' />
                                </div>
                            </div>
                        }
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default withRouter(inject('rootStore')(Chat));