import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings.js';
import { inject } from 'mobx-react';

import $ from 'jquery';
import U from '../../config/util';
import PointChargeList from './cell/point-charge-list.jsx';
import AlertDialog from "../dlg/alert-dialog.js";
import ConfirmDialog from "../dlg/confirm-dialog.js";

class PointCharge extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            pointList: [],
            show_alert_popup: false,
            alert_msg: "",
            show_install_popup: false,
        };

        this.onBack = this.onBack.bind(this);
        this.onPointHistory = this.onPointHistory.bind(this);
    }

    componentDidMount() {
        this.getInappList();
    }

    onBack(event) {
        event.preventDefault();
        this.props.history.goBack();
    }

    onPointHistory(event) {
        event.preventDefault();
        this.props.history.push('/pointhistory');
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    getInappList() {
        let self = this;
        self.context.post(
            'inappList',
            {
                id: this.props.rootStore.user.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.setState({
                        pointList: data.items
                    })
                }
            });
    }

    appInstall() {
        this.setState({
            show_install_popup: true
        })
    }

    appInstallLink() {
        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
        if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
            window.open(strings.appstore_link, '_blank');
        } else {
            window.open(strings.google_link, '_blank');
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='dashboard-div'>
                    <ConfirmDialog
                        title="알림"
                        msg={strings.use_app_version}
                        cancelBtn="아니"
                        okBtn="예"
                        isOpen={this.state.show_install_popup}
                        onClose={() => {
                            this.setState({ show_install_popup: false });
                        }}
                        onCancel={() => {
                            this.setState({ show_install_popup: false });
                        }}
                        onConfirm={() => {
                            this.setState({ show_install_popup: false });
                            this.appInstallLink();
                        }} />
                    <AlertDialog
                        msg={this.state.alert_msg}
                        isOpen={this.state.show_alert_popup}
                        onClose={() => {
                            this.setState({ show_alert_popup: false });
                        }} />
                    <div className='common-header-div'>
                        <div className='common-header-back-div' onClick={this.onBack}>
                            <img src={"assets/btn_back.png"} width={15} height={13} />
                        </div>
                        <label className='common-header-title-label'>{strings.point_charge}</label>
                    </div>

                    <div className='point-history-header-div'>
                        <div className='point-charge-mypoint-div'>
                            <label className='point-charge-my-point-label'>{strings.my_point}</label>
                            <div className='header-point-div'>
                                <img src={"assets/ic_pointcharge.png"} width={'19px'} height={'11px'} />
                                <label className='header-point-label'>{U.formatAssets(this.props.rootStore.user.point)}</label>
                            </div>
                        </div>

                        <div className='point-charge-history-div' onClick={this.onPointHistory}>
                            <label className='chat-label selected'>{strings.point_history}</label>
                        </div>
                    </div>

                    <div className='scroll-point-history-div'>
                        <ul>
                            {
                                this.state.pointList.map((item, index) => {
                                    return <li key={index} onClick={() => this.appInstall()}>
                                        <PointChargeList
                                            index={index}
                                            item={item}
                                        />
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(inject('rootStore')(PointCharge));