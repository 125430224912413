import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import U from './../../config/util';
import ImageSelect from '../../components/content/image-select.jsx';
import UserProfileImg from '../../components/content/user_profile_img.jsx';
import AlertDialog from "../dlg/alert-dialog.js";

export default class ReportDialog extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {
                type: -1,
                content: '',
                arrReport: ["선물강요(사기행위)", "성별이상(넷카마,남성)", "화면을 비추지않음(의도적)", "기타"]
            },
            show_alert_popup: false,
            alert_msg: ""
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onReport = this.onReport.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    onSelection(index) {
        let item = this.state.item;
        item.type = index;

        this.setState({
            item: item
        });
    }

    onReport() {
        let item = this.state.item;
        if (item.type == -1) {
            this.onAlert("신고유형을 선택해주세요.");
            return;
        }
        if (item.content.trim() == "") {
            this.onAlert("신고내용을 입력해주세요.");
            return;
        }

        if (this.props.onReport) {
            this.props.onReport(item.arrReport[item.type], item.content.trim());
        }
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    render() {
        let item = this.state.item;

        return (
            <div>
                <AlertDialog
                    msg={this.state.alert_msg}
                    isOpen={this.state.show_alert_popup}
                    onClose={() => {
                        this.setState({ show_alert_popup: false });
                    }} />
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.toggleModal}
                    size={'md'}>
                    <ModalBody style={{ padding: 0 }}>
                        <div style={{ height: 44, background: '#FF545A', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0 12px' }}>
                            <img src={"assets/ic_declare.png"} width={20} height={18} className='img-contain' />
                            <img src={"assets/ic_close.png"} width={20} height={20} className='img-contain' onClick={this.toggleModal} />
                        </div>
                        <div style={{ background: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                            <p dangerouslySetInnerHTML={{ __html: strings.report_desc }} style={{ fontSize: 12, color: '#474747', textAlign: 'center', marginBottom: 10 }}></p>
                            <div className='dashed-line-div' style={{ width: '100%', marginBottom: 10 }}></div>
                            <div className={'report-type-div'} onClick={() => this.onSelection(0)}>
                                <img src={item.type == 0 ? "assets/ic_checkbox_on.png" : "assets/ic_checkbox_off.png"} width={18} height={16} className='img-contain' />
                                <label style={{ color: item.type == 0 ? '#FF545A' : '#A1A1A1', fontSize: 12, marginLeft: 6 }}>{item.arrReport[0]}</label>
                            </div>
                            <div className={'report-type-div'} onClick={() => this.onSelection(1)}>
                                <img src={item.type == 1 ? "assets/ic_checkbox_on.png" : "assets/ic_checkbox_off.png"} width={18} height={16} className='img-contain' />
                                <label style={{ color: item.type == 1 ? '#FF545A' : '#A1A1A1', fontSize: 12, marginLeft: 6 }}>{item.arrReport[1]}</label>
                            </div>
                            <div className={'report-type-div'} onClick={() => this.onSelection(2)}>
                                <img src={item.type == 2 ? "assets/ic_checkbox_on.png" : "assets/ic_checkbox_off.png"} width={18} height={16} className='img-contain' />
                                <label style={{ color: item.type == 2 ? '#FF545A' : '#A1A1A1', fontSize: 12, marginLeft: 6 }}>{item.arrReport[2]}</label>
                            </div>
                            <div className={'report-type-div'} onClick={() => this.onSelection(3)}>
                                <img src={item.type == 3 ? "assets/ic_checkbox_on.png" : "assets/ic_checkbox_off.png"} width={18} height={16} className='img-contain' />
                                <label style={{ color: item.type == 3 ? '#FF545A' : '#A1A1A1', fontSize: 12, marginLeft: 6 }}>{item.arrReport[3]}</label>
                            </div>
                            <textarea autoComplete='off' className='form-control' id='content'
                                rows={3}
                                value={item.content}
                                placeholder='일어났던 일을 적어주세요.&#10;실명이 있어야 올바르게 판단할 수 있습니다.'
                                onChange={(e) => this.onDataChange(e)} />
                            <div style={{ height: 36, width: '100%', marginTop: 10, padding: '0 30px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ height: 36, flex: 1, borderRadius: 18, backgroundColor: '#9C9C9C', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} onClick={this.toggleModal}>
                                    <label style={{ color: '#fff', fontSize: 15, marginLeft: 6 }}>취소</label>
                                </div>
                                <div style={{ height: 36, flex: 1, borderRadius: 18, backgroundColor: '#FF777A', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 10 }} onClick={this.onReport}>
                                    <label style={{ color: '#fff', fontSize: 15, marginLeft: 6 }}>신고하기</label>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}