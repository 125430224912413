import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import U from './../../config/util';
import ImageSelect from '../../components/content/image-select.jsx';
import UserProfileImg from '../../components/content/user_profile_img.jsx';
import AlertDialog from "../dlg/alert-dialog.js";

export default class SendMsgDialog extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {
                content: ''
            },
            show_alert_popup: false,
            alert_msg: ""
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onSend = this.onSend.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    onSend() {
        let msg = this.state.item.content.trim();
        if (msg == "") {
            this.onAlert("쪽지를 입력해주세요.");
            return;
        }
        if (this.props.onSend) {
            this.props.onSend(msg);
        }
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    render() {
        let item = this.state.item;

        return (
            <div>
                <AlertDialog
                    msg={this.state.alert_msg}
                    isOpen={this.state.show_alert_popup}
                    onClose={() => {
                        this.setState({ show_alert_popup: false });
                    }} />
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.toggleModal}
                    size={'md'}>
                    <ModalBody style={{ padding: 0 }}>
                        <div style={{ height: 44, background: '#FF545A', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0 12px' }}>
                            <img src={"assets/ic_dlg_write.png"} width={30} height={30} className='img-contain' />
                            <img src={"assets/ic_close.png"} width={20} height={20} className='img-contain' onClick={this.toggleModal} />
                        </div>
                        {
                            this.props.peer &&
                            <div style={{ background: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                <div style={{ width: '100%', marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
                                    <label style={{ fontSize: 12, color: '#474747' }}>{this.props.peer.nickname}</label>
                                    <img src={this.props.peer.sex == "m" ? "assets/ic_main_man.png" : "assets/ic_main_girl.png"} width={8} height={10} className='img-contain' style={{ marginLeft: 4, marginRight: 2 }} />
                                    <label style={{ fontSize: 10, color: this.props.peer.sex == "m" ? '#8981FE' : '#FF777A' }}>{this.props.peer.age}</label>
                                </div>
                                <textarea autoComplete='off' className='form-control' id='content'
                                    placeholder='내용을 입력해주세요.&#10;휴대폰번호 메신저(카톡,라인 등) 아이디 작성 시 범죄에 악용될 수 있으며, 경고없이 차단 조치됩니다.'
                                    rows={6}
                                    value={item.content}
                                    onChange={(e) => this.onDataChange(e)} />
                                <div style={{ fontSize: 10, color: '#828282', width: '100%', marginTop: 10 }}>
                                    쪽지 발송시 <label style={{ color: '#FF7A7D' }}>{this.props.rootStore.systempoint.priceMsg}포인트</label>가 차갇되며 채팅창이 개설됩니다.
                                </div>
                                <div style={{ fontSize: 10, color: '#828282', width: '100%' }}>
                                    현재 <label style={{ color: '#FF7A7D' }}>{U.formatAssets(this.props.rootStore.user.point)}포인트</label>를 보유하고 있습니다.
                                </div>
                                <div style={{ height: 36, width: '100%', marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ height: 36, width: 120, borderRadius: 18, backgroundColor: '#FF777A', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} onClick={this.onSend}>
                                        <label style={{ color: '#fff', fontSize: 15, marginLeft: 6 }}>보내기</label>
                                    </div>
                                </div>
                            </div>
                        }
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}