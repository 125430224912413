import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings.js';
import { inject } from 'mobx-react';

import $ from 'jquery';
import U from '../../config/util';
import ConfirmDialog from "../dlg/confirm-dialog.js";
import AlertDialog from "../dlg/alert-dialog.js";

class Setting extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            alarm1: true,
            alarm2: true,
            alarm3: true,
            show_confirm_popup: false,
            show_logout_popup: false,
            show_alert_popup: false,
            alert_msg: "",
        };

        this.onBack = this.onBack.bind(this);
        this.onClickAlarm1 = this.onClickAlarm1.bind(this);
        this.onClickAlarm2 = this.onClickAlarm2.bind(this);
        this.onClickAlarm3 = this.onClickAlarm3.bind(this);
        this.onWithdrawal = this.onWithdrawal.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    onBack(event) {
        event.preventDefault();
        this.props.history.goBack();
    }

    onClickAlarm1(event) {
        event.preventDefault();
        this.setState({
            alarm1: !this.state.alarm1
        });
    }

    onClickAlarm2(event) {
        event.preventDefault();
        this.setState({
            alarm2: !this.state.alarm2
        });
    }

    onClickAlarm3(event) {
        event.preventDefault();
        this.setState({
            alarm3: !this.state.alarm3
        });
    }

    onLogout(event) {
        event.preventDefault();
        this.setState({
            show_logout_popup: true
        });
    }

    onWithdrawal(event) {
        event.preventDefault();
        this.setState({
            show_confirm_popup: true
        });
    }

    userLogout() {
        this.props.rootStore.logout();
        this.props.history.push('/');
    }

    userleave() {
        let self = this;
        self.context.post(
            'userLeave',
            {
                id: this.props.rootStore.user.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("회원 탈퇴되었습니다.");
                    self.props.rootStore.logout();
                    self.props.history.push('/');
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className='dashboard-div'>
                    <AlertDialog
                        msg={this.state.alert_msg}
                        isOpen={this.state.show_alert_popup}
                        onClose={() => {
                            this.setState({ show_alert_popup: false });
                        }} />
                    <ConfirmDialog
                        title="알림"
                        msg="정말 삭제하시겠습니까?<br>계정삭제시 사용이력과 포인트는 모두 소멸되며 <br>삭제 후 12시간후에 재가입이 가능합니다."
                        cancelBtn="아니요"
                        okBtn="탈퇴"
                        isOpen={this.state.show_confirm_popup}
                        onClose={() => {
                            this.setState({ show_confirm_popup: false });
                        }}
                        onCancel={() => {
                            this.setState({ show_confirm_popup: false });
                        }}
                        onConfirm={() => {
                            this.setState({ show_confirm_popup: false });
                            this.userleave();
                        }} />
                    <ConfirmDialog
                        title="알림"
                        msg="로그아웃 하시겠습니까?"
                        cancelBtn="아니요"
                        okBtn="로그아웃"
                        isOpen={this.state.show_logout_popup}
                        onClose={() => {
                            this.setState({ show_logout_popup: false });
                        }}
                        onCancel={() => {
                            this.setState({ show_logout_popup: false });
                        }}
                        onConfirm={() => {
                            this.setState({ show_logout_popup: false });
                            this.userLogout();
                        }} />
                    <div className='common-header-div borderb'>
                        <div className='common-header-back-div' onClick={this.onBack}>
                            <img src={"assets/btn_back.png"} width={15} height={13} />
                        </div>
                        <label className='common-header-title-label'>{strings.setting}</label>
                    </div>

                    <div className='setting-header-div'>
                        <label className='setting-header-label'>{strings.alarm_setting}</label>
                    </div>
                    <div className='setting-paragraph-div' onClick={this.onClickAlarm1}>
                        <label className='setting-paragraph-label'>{strings.alarm}</label>
                        <img src={this.state.alarm1 ? "assets/btn_toggle_on.png" : "assets/btn_toggle_off.png"} width={'40px'} height={'23px'} style={{ marginRight: 15 }} />
                    </div>
                    <div className='setting-paragraph-div' onClick={this.onClickAlarm2}>
                        <label className='setting-paragraph-label'>{strings.sound}</label>
                        <img src={this.state.alarm2 ? "assets/btn_toggle_on.png" : "assets/btn_toggle_off.png"} width={'40px'} height={'23px'} style={{ marginRight: 15 }} />
                    </div>
                    <div className='setting-paragraph-div' onClick={this.onClickAlarm3}>
                        <label className='setting-paragraph-label'>{strings.vibrate}</label>
                        <img src={this.state.alarm3 ? "assets/btn_toggle_on.png" : "assets/btn_toggle_off.png"} width={'40px'} height={'23px'} style={{ marginRight: 15 }} />
                    </div>
                    <div className='setting-header-div'>
                        <label className='setting-header-label'>{strings.app_version}</label>
                    </div>
                    <div className='setting-paragraph-div'>
                        <label className='setting-paragraph-label'>{strings.app_name}</label>
                        <label className='setting-appversion-label'>1.0.0</label>
                    </div>
                    <div className="request-deposit-buttons" style={{ marginTop: 20 }}>
                        <button type="submit" className="btn-request-deposit" onClick={this.onLogout}>로그아웃</button>
                    </div>
                    <div className="request-deposit-buttons">
                        <button type="submit" className="btn-request-deposit" onClick={this.onWithdrawal}>{strings.withdrawal}</button>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(inject('rootStore')(Setting));