import React from 'react';
import { Route, Link } from 'react-router-dom';
import strings from '../../../lang/strings.js';
import U from '../../../config/util';

class EventList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {

		return (
			<div style={{
				position: 'relative',
				height: 120,
				marginBottom: 6,
				backgroundColor: '#fff'
			}}>
				<img src={this.props.item.img_url} width={'100%'} height={120} className='img-cover' />
				{
					(this.props.item.status == 2 || this.props.item.status == 3) &&
					<div className='in-video-div'>
						<label className={'event-status-label'}>{this.props.item.status == 2 ? "종료됨" : "예정됨"}</label>
					</div>
				}
			</div>
		);
	}
}

export default EventList;