import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings.js';
import { inject } from 'mobx-react';

import $ from 'jquery';
import U from '../../config/util';
import PassbookList from './cell/passbook-list.jsx';
import PassportDialog from "../dlg/passport-dialog.js";
import AlertDialog from "../dlg/alert-dialog.js";

class PassBook extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            pointList: ['8000', '16000', '24000', '32000', '40000', '80000', '160000', '240000', '400000'],
            pricesList: ['10000', '20000', '30000', '40000', '50000', '100000', '200000', '300000', '500000'],
            show_detail_popup: false,
            selectedPos: -1,
            account_name: "",
            bank_info: "",
            show_alert_popup: false,
            alert_msg: "",
        };

        this.onBack = this.onBack.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    componentDidMount() {
        if (this.props.rootStore.user.level == '10') {
            let pointList = ['', '', '', '', '', '', '', '', ''];
            for (var i = 0; i < this.state.pricesList.length; i++) {
                pointList[i] = (~~(this.state.pricesList[i] * 0.9)).toString();
            }
            this.setState({
                pointList: pointList
            })
        }
    }

    onBack(event) {
        event.preventDefault();
        this.props.history.goBack();
    }

    onDataChange = (e) => {
        let name = e.target.value;

        this.setState({
            account_name: name
        });
    };

    onConfirm(event) {
        if (this.state.account_name == "") {
            this.onAlert("입금자명을 입력해주세요.")
            return
        }
        if (this.state.selectedPos == -1) {
            this.onAlert("하나이상을 선택해주세요.")
            return
        }
        let self = this;
        self.context.post(
            'requestDeposit',
            {
                id: this.props.rootStore.user.id,
                name: this.state.account_name,
                price: this.state.pricesList[this.state.selectedPos]
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.setState({
                        show_detail_popup: true,
                        bank_info: data.bank_info
                    });
                } else {
                    self.onAlert("입금신청 실패되었습니다.");
                }
            });
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className='dashboard-div'>
                    <AlertDialog
                        msg={this.state.alert_msg}
                        isOpen={this.state.show_alert_popup}
                        onClose={() => {
                            this.setState({ show_alert_popup: false });
                        }} />
                    <PassportDialog
                        bank_info={this.state.bank_info}
                        price={this.state.selectedPos == -1 ? "0" : this.state.pricesList[this.state.selectedPos]}
                        isOpen={this.state.show_detail_popup}
                        onClose={() => {
                            this.setState({ show_detail_popup: false });
                        }} />
                    <div className='common-header-div borderb'>
                        <div className='common-header-back-div' onClick={this.onBack}>
                            <img src={"assets/btn_back.png"} width={15} height={13} />
                        </div>
                        <label className='common-header-title-label'>{strings.passbook}</label>
                    </div>

                    <div className='point-history-header-div'>
                        <label className='deposit-name-label'>{strings.deposit_name}</label>
                        <input id={'deposit_name'} type="text" autoComplete={'off'}
                            className="deposit-input-box" placeholder={strings.input_deposit_name}
                            onChange={(e) => this.onDataChange(e)}
                            required />
                    </div>

                    <div className='scroll-point-history-div'>
                        <ul>
                            {
                                this.state.pointList.map((item, index) => {
                                    return <li key={index} onClick={() => {
                                        this.setState({
                                            selectedPos: index
                                        })
                                    }}>
                                        <PassbookList
                                            point={this.state.pointList[index]}
                                            price={this.state.pricesList[index]}
                                            selectedPos={this.state.selectedPos}
                                            index={index}
                                        />
                                    </li>
                                })
                            }
                        </ul>

                        <p className='passbook-desc-label' dangerouslySetInnerHTML={{ __html: strings.passbook_desc }}></p>

                        <div className="request-deposit-buttons">
                            <button type="submit" className="btn-request-deposit" onClick={this.onConfirm}>{strings.request_deposit}</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(inject('rootStore')(PassBook));