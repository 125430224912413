import React from 'react';
import { Route, Link } from 'react-router-dom';
import strings from '../../../lang/strings.js';
import U from '../../../config/util';

class NoticeList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	render() {

		return (
			<div style={{
				display: 'flex', flexDirection: 'column', alignItems: 'end',
				paddingLeft: 12,
				paddingRight: 12,
				paddingBottom: 8,
				paddingTop: 8,
				borderBottom: '1px solid #eee',
				backgroundColor: '#FFFCFC'
			}}>
				<div className='notice-content-div'>
					{
						this.props.item.content.includes("http") ?
						<p dangerouslySetInnerHTML={{__html: this.props.item.content.replace(/\r\n/gi,"<br>")}} className={'notice-content-label'}></p>
						:
						<p dangerouslySetInnerHTML={{__html: this.props.item.content.replace(/\r\n/gi,"<br>")}} className={'notice-content-label'}></p>
					}
				</div>
				<label className={'notice-time-label'}>{this.props.item.time}</label>
			</div>
		);
	}
}

export default NoticeList;