import React from 'react';
import { inject } from 'mobx-react';
import { PageSettings } from "./../../config/page-settings";

class UserProfileImg extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            imageLoaded: false,
        };
    }

    componentDidMount() {
    }

    render() {
        const id = this.props.id;
        const profileImgUrl = this.props.profileImgUrl;
        const sex = this.props.sex;
        const imgConfirm = this.props.imgConfirm;
        const width = this.props.width;
        const height = this.props.height;
        const radius = this.props.radius;

        return (
            <div style={{
                display: 'block', position: 'relative',
                height: height,
                width: width,
                borderRadius: radius ? radius : (width / 2),
                overflow: 'hidden',
            }}>
                {
                    (id == "admin" || id == "adminchat") ?
                        <img src={"assets/ic_launcher.png"} width={width} height={height} className='img-cover' />
                        :
                        !profileImgUrl && sex == "f" ?
                            <img src={"assets/bg_profile_girl_default.png"} width={width} height={height} className='img-cover' />
                            :
                            !profileImgUrl && sex != "f" ?
                                <img src={"assets/bg_profile_man_default.png"} width={width} height={height} className='img-cover' />
                                :
                                profileImgUrl && imgConfirm == "0" ?
                                    <img src={"assets/bg_image_status.png"} width={width} height={height} className='img-cover' />
                                    :
                                    <div>
                                        {
                                            !this.state.imageLoaded &&
                                            <img src={sex == "f" ? "assets/bg_profile_girl_default.png" : "assets/bg_profile_man_default.png"} width={width} height={height} className='img-cover' />
                                        }
                                        <img src={profileImgUrl} width={width} height={height} className='img-cover' style={this.state.imageLoaded ? {} : { display: `none` }}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = sex == "f" ? "assets/bg_profile_girl_default.png" : "assets/bg_profile_man_default.png";
                                            }} onLoad={() => {
                                                this.setState({
                                                    imageLoaded: true
                                                })
                                            }} />
                                    </div>
                }
            </div>
        );
    }

}

export default inject('rootStore')(UserProfileImg);