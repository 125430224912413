import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from "../../config/page-settings";
import { inject } from 'mobx-react';
import UserProfileImg from '../../components/content/user_profile_img.jsx';
import CustomerDialog from "../dlg/customer-dialog.js";
import AlertDialog from "../dlg/alert-dialog.js";
import ConfirmDialog from "../dlg/confirm-dialog.js";

class MoreFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            show_customer_popup: false,
            show_alert_popup: false,
            alert_msg: "",
            show_install_popup: false,
        };

        this.onBlockList = this.onBlockList.bind(this);
        this.onFriendList = this.onFriendList.bind(this);
        this.onNoticeEventList = this.onNoticeEventList.bind(this);
        this.onPassbook = this.onPassbook.bind(this);
        this.onUseGuide = this.onUseGuide.bind(this);
        this.onPointHistory = this.onPointHistory.bind(this);
        this.onRollCheck = this.onRollCheck.bind(this);
        this.onSetting = this.onSetting.bind(this);
        this.onProfileEdit = this.onProfileEdit.bind(this);
        this.onPurchase = this.onPurchase.bind(this);
        this.onRefund = this.onRefund.bind(this);
        this.onCustomer = this.onCustomer.bind(this);
    }

    componentDidMount() {

    }

    onUseGuide(event) {
        event.preventDefault();
        this.props.history.push('/useguide');
    }

    onBlockList(event) {
        event.preventDefault();
        this.props.history.push('/blocklist');
    }

    onFriendList(event) {
        event.preventDefault();
        this.props.history.push('/friendlist');
    }

    onNoticeEventList(event) {
        event.preventDefault();
        this.props.history.push('/noticeeventlist');
    }

    onPassbook(event) {
        event.preventDefault();
        if (this.props.rootStore.user.sex == "f") {
            this.onAlert("서버점검중입니다. 고객센터에 문의해주세요.");
            return;
        }
        this.props.history.push('/passbook');
    }

    onPointHistory(event) {
        event.preventDefault();
        this.props.history.push('/pointhistory');
    }

    onRollCheck(event) {
        event.preventDefault();
        let self = this;
        self.context.post(
            'checkAttendance',
            {
                id: this.props.rootStore.user.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert(self.props.rootStore.systempoint.attendance + "포인트 적립되었습니다.");
                    self.props.parent.resumeHomePage();
                } else if (data.result == "already") {
                    self.onAlert("이미 출석체크 했습니다.");
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onSetting(event) {
        event.preventDefault();
        this.props.history.push('/setting');
    }

    onProfileEdit(event) {
        event.preventDefault();
        this.props.history.push('/profile_edit');
    }

    onPurchase(event) {
        event.preventDefault();
        this.props.history.push('/pointcharge');
    }

    onRefund(event) {
        event.preventDefault();
        this.appInstall();
    }

    onCustomer(event) {
        this.setState({
            show_customer_popup: true
        });
    }

    reqQuestion(content) {
        let self = this;
        self.context.post(
            'inquiry',
            {
                id: this.props.rootStore.user.id,
                content: content,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("문의하신 내용이 접수되었습니다. 24시간안에 답변드리겠습니다.");
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    appInstall() {
        this.setState({
            show_install_popup: true
        })
    }

    appInstallLink() {
        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
        if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
            window.open(strings.appstore_link, '_blank');
        } else {
            window.open(strings.google_link, '_blank');
        }
    }

    render() {

        return (
            <div className='fragment-div'>
                <ConfirmDialog
                    title="알림"
                    msg={strings.use_app_version}
                    cancelBtn="아니"
                    okBtn="예"
                    isOpen={this.state.show_install_popup}
                    onClose={() => {
                        this.setState({ show_install_popup: false });
                    }}
                    onCancel={() => {
                        this.setState({ show_install_popup: false });
                    }}
                    onConfirm={() => {
                        this.setState({ show_install_popup: false });
                        this.appInstallLink();
                    }} />
                <AlertDialog
                    msg={this.state.alert_msg}
                    isOpen={this.state.show_alert_popup}
                    onClose={() => {
                        this.setState({ show_alert_popup: false });
                    }} />
                <CustomerDialog
                    isOpen={this.state.show_customer_popup}
                    onClose={() => {
                        this.setState({ show_customer_popup: false });
                    }}
                    onSend={(content) => {
                        this.setState({ show_customer_popup: false });
                        this.reqQuestion(content);
                    }} />

                <div className='myinfo-div'>
                    <div className='profile-img-div' onClick={this.onProfileEdit}>
                        <UserProfileImg
                            id={this.props.rootStore.user.id}
                            profileImgUrl={this.props.rootStore.user.profileImgUrl}
                            sex={this.props.rootStore.user.sex}
                            imgConfirm="1"
                            width="80"
                            height="80"
                        />
                        {
                            this.props.rootStore.user.level != "1" &&
                            <img src={this.props.rootStore.user.level == "10" ? "assets/ic_svip_round_badge.png" : "assets/ic_vip_round_badge.png"} width={30} height={30} className='img-contain img-badge' />
                        }
                    </div>

                    <div className='profile-nick-div'>
                        <label className='profile-nick-label'>{this.props.rootStore.user.nickname}</label>
                        <div>
                            <img src={this.props.rootStore.user.sex == "f" ? "assets/ic_main_girl.png" : "assets/ic_main_man.png"} width={13} height={13} className='img-contain' />
                            <img src={"assets/ic_dot.png"} width={2} height={2} className='img-dot-contain' />
                            <label className='profile-age-label'>{this.props.rootStore.user.age}세</label>
                        </div>
                    </div>
                    <img src={"assets/btn_edit.png"} width={'55px'} height={'55px'} onClick={this.onProfileEdit} />
                    {
                        this.props.rootStore.user.sex == "m" ?
                            <img src={"assets/btn_purchase.png"} width={'55px'} height={'55px'} style={{ marginLeft: 10 }} onClick={this.onPurchase} />
                            :
                            <img src={"assets/btn_refund.png"} width={'55px'} height={'55px'} style={{ marginLeft: 10 }} onClick={this.onRefund} />
                    }
                </div>

                <div className='scroll-menu-div'>
                    <div className='menu-sub-div'>
                        <div className='menu-item-div' onClick={this.onPassbook}>
                            <img src={this.props.rootStore.user.sex == "f" ? "assets/btn_gift_icon2.png" : "assets/btn_passbook.png"} width={54} height={54} className='img-contain' />
                            <label className={'menu-label'}>{this.props.rootStore.user.sex == "f" ? strings.gifticon : strings.passbook}</label>
                        </div>
                        <div className='menu-item-div' onClick={this.onPointHistory}>
                            <img src={"assets/btn_usded_list_icon.png"} width={54} height={54} className='img-contain' />
                            <label className={'menu-label'}>{strings.usage_history}</label>
                        </div>
                        <div className='menu-item-div' onClick={this.onRollCheck}>
                            <img src={"assets/btn_giftcon_icon.png"} width={54} height={54} className='img-contain' />
                            <label className={'menu-label'}>{strings.attendance}</label>
                        </div>
                    </div>
                    <div className='menu-sub-div'>
                        <div className='menu-item-div' onClick={this.onSetting}>
                            <img src={"assets/btn_setting_icon.png"} width={54} height={54} className='img-contain' />
                            <label className={'menu-label'}>{strings.setting}</label>
                        </div>
                        <div className='menu-item-div' onClick={this.onFriendList}>
                            <img src={"assets/btn_purchase_list_icon.png"} width={54} height={54} className='img-contain' />
                            <label className={'menu-label'}>{strings.friend_list}</label>
                        </div>
                        <div className='menu-item-div' onClick={this.onBlockList}>
                            <img src={"assets/btn_block_list_icon.png"} width={54} height={54} className='img-contain' />
                            <label className={'menu-label'}>{strings.block_list}</label>
                        </div>
                    </div>
                    <div className='menu-sub-div'>
                        <div className='menu-item-div' onClick={this.onUseGuide}>
                            <img src={"assets/btn_use_guide_icon.png"} width={54} height={54} className='img-contain' />
                            <label className={'menu-label'}>{strings.usage_guide}</label>
                        </div>
                        <div className='menu-item-div' onClick={this.onCustomer}>
                            <img src={"assets/btn_help_desk_icon.png"} width={54} height={54} className='img-contain' />
                            <label className={'menu-label'}>{strings.customer_service}</label>
                        </div>
                        <div className='menu-item-div' onClick={this.onNoticeEventList}>
                            <img src={"assets/btn_notice_menu_icon.png"} width={54} height={54} className='img-contain' />
                            <label className={'menu-label'}>{strings.notice_event}</label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(MoreFragment));