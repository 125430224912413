import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings.js';
import { inject } from 'mobx-react';

import $ from 'jquery';
import AlertDialog from "../dlg/alert-dialog.js";

class Login extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            show_alert_popup: false,
            alert_msg: ""
        };

        this.phoneAuth = this.phoneAuth.bind(this);
        this.phoneVerify = this.phoneVerify.bind(this);
    }

    componentDidMount() {
        this.systemPoint();
    }

    systemPoint = () => {
        let self = this;
        self.context.post(
            'systempoint',
            {
            },
            function (response) {
                var data = JSON.parse(response.data);
                if (data.result == "success") {
                    //포인트 정보를 저장
                    self.props.rootStore.setSystemPoint({
                        rewardMsg: data.reward_msg,
                        rewardGift: data.reward_gift,
                        rewardAudio: data.reward_audio,
                        rewardVideo: data.reward_video,
                        rewardPhoto: data.reward_photo,
                        priceMsg: data.price_msg,
                        priceGroupMsg: data.price_group_msg,
                        priceAudio: data.price_audio,
                        priceVideo: data.price_video,
                        pricePhoto: data.price_photo,
                        pointFirstCommunity: data.point_first_community,
                        attendance: data.attendance,
                        rewardChat: data.reward_chat,
                        priceChat: data.price_chat
                    });
                }
            });
    };

    phoneAuth(event) {        
        if ($('#phone').val() == "") {
            this.onAlert("전화번호를 입력해 주세요.")
            return;
        }
        let self = this;
        self.context.post(
            'phoneAuth',
            {
                phone: $('#phone').val(),
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("인증번호를 요청했습니다.");
                } else {
                    if (data.msg == "permission") {
                        self.onAlert("전화번호를 다시 확인해주세요");
                    } else if (data.msg == "already") {
                        self.onAlert("이미 코드를 발송했습니다");
                    } else if (data.msg == "maximum") {
                        self.onAlert("최대발송횟수를 넘었습니다.");
                    } else if (data.msg == "block") {
                        self.onAlert("해당 폰번호는 12시간 후 이용가능합니다.");
                    } else if (data.msg == "block_user") {
                        self.onAlert("사용할 수 없는 전화번호입니다.");
                    } else if (data.msg == "sms") {
                        self.onAlert("SMS 발송에 실패했습니다.");
                    }
                }
            },
            true
        );
    }

    phoneVerify(event) {
        if ($('#phone').val() == "") {
            this.onAlert("전화번호를 입력해 주세요.")
            return;
        }
        if ($('#code').val() == "") {
            this.onAlert("인증코드를 입력해 주세요.")
            return;
        }
        let self = this;
        self.context.post(
            'phoneAuthConfirm',
            {
                phone: $('#phone').val(),
                code: $('#code').val(),
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    //id등록여부 확인
                    self.getRegisterInfo();
                } else {
                    if (data.msg == "timeout") {
                        self.onAlert("인증코드가 만료되었습니다. 재요청해주세요.");
                    } else if (data.msg == "code_error") {
                        self.onAlert("잘못된 인증코드입니다.");
                    } else {
                        self.onAlert(data.msg);
                    }
                }
            },
            true
        );
    }

    getRegisterInfo() {
        let self = this;
        self.context.post(
            'getRegisterInfo',
            {
                phone: $('#phone').val(),
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.reqLogin();
                } else {
                    //회원가입으로 이동
                    sessionStorage.setItem('phone_number', $('#phone').val());
                    sessionStorage.setItem('phone_code', $('#code').val());
                    self.props.history.push('/join');
                }
            },
            true
        );
    }

    reqLogin() {
        let self = this;
        self.context.post(
            'login',
            {
                phone: $('#phone').val(),
            },
            function (response) {
                var access_token = response.data.access_token;
                // console.log(access_token);
                self.props.rootStore.setAccessToken(access_token);

                var data = JSON.parse(response.data.data);
                // console.log(data);
                if (data.result == "success") {
                    self.props.rootStore.login({
                        id: data.id,
                        age: data.age,
                        nickname: data.nickname,
                        location: data.location,
                        point: data.point,
                        level: data.level,
                        sex: data.sex,
                        profileMsg: data.profile_msg,
                        profileImgUrl: data.profile_img_url,
                        expire: data.expire,
                        expireDate: data.expire_date,
                        authKey: data.auth_key,
                        imgConfirm: data.img_confirm,
                        allowGift: data.allow_gift,
                        allowVideo: data.allow_video,
                        rsacode: data.rsacode,
                        expMsg: data.exp_msg,
                        payable: data.payable,
                        weekPoint: data.week_point,
                        dailyPoint: data.daily_point,
                        balloonPoint: data.balloon_point,
                        balloonList: data.balloon_list,
                        videoMaxDelay: data.video_max_delay,
                        igacnt: data.igacnt,
                        rankingEnable: data.ranking_enable,
                        appallServer: data.appall_server,
                        payAction: data.pay_action
                    });

                    sessionStorage.setItem('mainTabIndex', 0);
                    self.props.history.push('/dashboard');
                } else {
                    self.onAlert(data.msg);
                }
            },
            true
        );
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="login-div">
                    <AlertDialog
                        msg={this.state.alert_msg}
                        isOpen={this.state.show_alert_popup}
                        onClose={() => {
                            this.setState({ show_alert_popup: false });
                        }} />
                    <div className="login-content">
                        {/* <form className="margin-bottom-0"> */}
                        <div style={{display: 'flex', justifyContent: 'center', marginBottom: 20}}>
                            <img src={"assets/ic_status_review.png"} width={'51px'} height={'44px'}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', marginBottom: 30}}>
                            <img src={"assets/ic_main_title.png"} width={'90px'} height={'24px'}/>
                        </div>
                        <div className="form-group m-b-20">
                            <input id={'phone'} type="text" autoComplete={'off'}
                                className="form-control form-control-lg" placeholder={strings.phone_number}
                                required />
                        </div>

                        <div className="login-buttons" onClick={this.phoneAuth}>
                            <button type="submit" className="btn btn-success btn-success-v2 btn-block btn-lg">전화번호 인증</button>
                        </div>

                        <div className="form-group m-b-20" style={{ marginTop: 20 }}>
                            <input id={'code'} type="text" autoComplete={'off'}
                                className="form-control form-control-lg" placeholder={strings.cert_number}
                                required />
                        </div>
                        <div className="login-buttons" onClick={this.phoneVerify}>
                            <button type="submit" className="btn btn-success btn-success-v2 btn-block btn-lg">{strings.login}</button>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(inject('rootStore')(Login));