import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings.js';
import { inject } from 'mobx-react';

import $ from 'jquery';
import U from '../../config/util';

class UseGuide extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {

        };

        this.onBack = this.onBack.bind(this);
        this.onPolicy1 = this.onPolicy1.bind(this);
        this.onPolicy2 = this.onPolicy2.bind(this);
    }

    onBack(event) {
        event.preventDefault();
        this.props.history.goBack();
    }

    onPolicy1(event) {
        event.preventDefault();

        let url = "/license.html";
        window.open(url, '_blank');
    }

    onPolicy2(event) {
        event.preventDefault();

        let url = "/policy.html";
        window.open(url, '_blank');
    }

    render() {
        return (
            <React.Fragment>
                <div className='dashboard-div'>
                    <div className='common-header-div borderb'>
                        <div className='common-header-back-div' onClick={this.onBack}>
                            <img src={"assets/btn_back.png"} width={15} height={13} />
                        </div>
                        <label className='common-header-title-label'>{strings.usage_guide}</label>
                    </div>

                    <div className='useguide_paragraph_div' style={{ marginTop: 20 }} onClick={this.onPolicy1}>
                        <label className='useguide-paragraph-label'>{strings.usage_policy}</label>
                        <img src={"assets/ic_arrow_up.png"} width={'19px'} height={'10px'} />
                    </div>

                    <div className='useguide_paragraph_div' style={{ marginTop: 5 }} onClick={this.onPolicy2}>
                        <label className='useguide-paragraph-label'>{strings.privacy_policy}</label>
                        <img src={"assets/ic_arrow_up.png"} width={'19px'} height={'10px'} />
                    </div>

                    <div className='useguide-desc-label' style={{ margin: 20 }}>
                        <div>
                            · 영상과 쪽지대화는 모든 카테고리에서 사용이 가능합니다.
                        </div>
                        <div>
                            · 메세지 전송시 <label className='useguide-desc-highlight-label'>{this.props.rootStore.user.sex == "m" ? this.props.rootStore.systempoint.priceMsg : this.props.rootStore.systempoint.rewardMsg}포인트</label> {this.props.rootStore.user.sex == "m" ? "차감됩니다." : "적립됩니다."}
                        </div>
                        <div>
                            · 영상채팅 분당 <label className='useguide-desc-highlight-label'>{this.props.rootStore.user.sex == "m" ? this.props.rootStore.systempoint.priceVideo * 2 : (this.props.rootStore.systempoint.priceVideo * this.props.rootStore.systempoint.rewardVideo / 50)}포인트</label> {this.props.rootStore.user.sex == "m" ? "차감됩니다." : "적립됩니다."}
                        </div>
                        <div>
                            · 음성채팅 분당 <label className='useguide-desc-highlight-label'>{this.props.rootStore.user.sex == "m" ? this.props.rootStore.systempoint.priceAudio * 2 : (this.props.rootStore.systempoint.priceAudio * this.props.rootStore.systempoint.rewardAudio / 50)}포인트</label> {this.props.rootStore.user.sex == "m" ? "차감됩니다." : "적립됩니다."}
                        </div>
                        <div>
                            · 사진 전송시 <label className='useguide-desc-highlight-label'>{this.props.rootStore.user.sex == "m" ? this.props.rootStore.systempoint.pricePhoto : this.props.rootStore.systempoint.pricePhoto * this.props.rootStore.systempoint.rewardPhoto / 100}포인트</label> 차감됩니다.
                        </div>
                        <div>
                            · 포인트는 출석체크, 무료충전소, 결제, 기타 이벤트를 통해 얻을수 있습니다.
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(inject('rootStore')(UseGuide));