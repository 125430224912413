import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import U from './../../config/util';
import ImageSelect from '../../components/content/image-select.jsx';
import UserProfileImg from '../../components/content/user_profile_img.jsx';

export default class ConfirmDialog extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {},
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    onConfirm(result) {
        if (this.props.onConfirm) {
            this.props.onConfirm(result);
        }
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    render() {
        let item = this.state.item;

        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.toggleModal}
                    size={'md'}>
                    <ModalBody style={{padding: 0}}>
                        <div style={{height: 44, background: '#FF545A', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '0 12px'}}>
                            <label style={{color: '#fff', fontSize: 15, flexGrow: 100}}>알림</label>
                            <img src={"assets/ic_close.png"} width={20} height={20} className='img-contain' onClick={this.toggleModal}/>
                        </div>
                        <div style={{background: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', padding: 10}}>
                            <p dangerouslySetInnerHTML={{__html: this.props.msg}} style={{fontSize: 12, color: '#474747', textAlign: 'start'}}></p>
                            <div className='dashed-line-div' style={{width: '100%'}}></div>
                            <div style={{height: 36, width: '100%', marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{height: 36, width: 160, borderRadius: 18, backgroundColor: '#FF777A', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 10}} onClick={this.onConfirm}>
                                    <label style={{color: '#fff', fontSize: 15}}>확인</label>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}