import React from 'react';
import { Route, Link } from 'react-router-dom';
import strings from '../../../lang/strings.js';
import UserProfileImg from '../../../components/content/user_profile_img.jsx';

class RankList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};

		this.onRequestVideo = this.onRequestVideo.bind(this);
		this.onWrite = this.onWrite.bind(this);
		this.onPhoto = this.onPhoto.bind(this);
	}

	onRequestVideo(event) {
		event.stopPropagation();
        if (this.props.onRequestAudioVideo) {
            this.props.onRequestAudioVideo(2);
        }
    }

	onWrite(event) {
		event.stopPropagation();
		if (this.props.onWrite) {
            this.props.onWrite(2);
        }
	}

	onPhoto(event) {
		event.stopPropagation();
        if (this.props.onPhoto) {
            this.props.onPhoto();
        }
    }

	render() {
		var rankImg = this.props.index == 0
			? 'assets/ic_rank_red.png'
			: (this.props.index == 1 ? 'assets/ic_rank_purple.png' : (this.props.index == 2 ? 'assets/ic_rank_green.png' : 'assets/ic_rank_yellow.png'));
		return (
			<div style={{
				display: 'flex', flexDirection: 'row', alignItems: 'center',
				padding: 10,
				borderBottom: '1px solid #eee',
				backgroundColor: '#FFF8F8'
			}}>
				<div className='rank-div'>
					<div className='rank-flag-div'>
						<img src={rankImg} width={24} height={32} className='img-contain' />
						{
							this.props.index < 3 ?
								<label className='rank-label'>{this.props.index + 1}</label>
								:
								<label className='rank-label-1'>{this.props.index + 1}</label>
						}
					</div>
					<div>
						{
							this.props.item.prev_rank != "0" &&
							<img src={this.props.index <= 3 ? "assets/ic_rank_up.png" : "assets/ic_rank_down.png"} width={8} height={8} className='img-contain' />
						}
						<label className='rank-prev-label'>{this.props.item.prev_rank == "0" ? "New" : this.props.item.prev_rank}</label>
					</div>
				</div>
				<div className='profile-img-div' onClick={this.onPhoto}>
					<UserProfileImg
						id={this.props.item.id}
						profileImgUrl={this.props.item.profile_img_url}
						sex={this.props.item.sex}
						imgConfirm={this.props.item.img_confirm}
						width="60"
						height="60"
					/>
				</div>

				<div className='profile-nick-div'>
					<label className='rank-nick-label'>{this.props.item.nickname}</label>
					<div className='rank-like-div'>
						<img src={"assets/ic_rank_number.png"} width={13} height={13} className='img-contain' />
						<label className='rank-like-label'>{this.props.rootStore.user.level != "1" && this.props.rootStore.user.sex == this.props.item.sex ? this.props.item.point : '---'}</label>
					</div>
				</div>

				<div className='rank-btn-div' onClick={this.onWrite}>
					<img src={"assets/btn_contact.png"} width={44} height={44} className='img-contain' />
					<label className={'rank-btn-label'}>{strings.write_msg}</label>
				</div>

				<div className='rank-btn-div' onClick={this.onRequestVideo}>
					<img src={this.props.item.statevideo == "1" ? "assets/btn_videorequest_on.png" : "assets/btn_videorequest_off.png"} width={44} height={44} className='img-contain' />
					<label className={'rank-btn-label'}>{this.props.item.statevideo == "1" ? strings.in_video : strings.request_video}</label>
				</div>
			</div>
		);
	}
}

export default RankList;