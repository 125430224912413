import React from 'react';
import { Route, Link } from 'react-router-dom';
import strings from '../../../lang/strings.js';
import UserProfileImg from '../../../components/content/user_profile_img.jsx';

class TalkList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};

		this.onDelete = this.onDelete.bind(this);
        this.onWrite = this.onWrite.bind(this);
		this.onPhoto = this.onPhoto.bind(this);
	}

	onPhoto(event) {
		event.stopPropagation();
        if (this.props.onPhoto) {
            this.props.onPhoto();
        }
    }

	onDelete(event) {
		event.stopPropagation();
        if (this.props.onDelete) {
            this.props.onDelete();
        }
    }

	onWrite(event) {
		event.stopPropagation();
        if (this.props.onWrite) {
            this.props.onWrite();
        }
    }

	render() {

		return (
			<div style={{
				display: 'flex', flexDirection: 'row', alignItems: 'center',
				padding: 10,
				margin: 10,
				backgroundColor: '#FFF8F8',
				borderRadius: 4,
			}}>
				<div className='talk-nick-div'>
					<label className='chat-nick-label'>{this.props.item.nickname}</label>
					{
						this.props.item.sex == "m" ?
							<img src={"assets/ic_main_man.png"} width={8} height={8} className='img-contain' />
							:
							<img src={"assets/ic_main_girl.png"} width={8} height={8} className='img-contain' />
					}
					{
						this.props.item.sex == "m" ?
							<label className='chat-age-label-man'>{this.props.item.age}</label>
							:
							<label className='chat-age-label-girl'>{this.props.item.age}</label>
					}
					<img src={"assets/ic_dot.png"} width={2} height={2} className='img-dot-contain' />
					<label className='chat-before-label'>{this.props.item.before}</label>
					<div className='talk-content-div'>
						{
							this.props.item.profile_img_url != "" && this.props.item.pro_img_confirm == "1" &&
							<div className='talk-profile-div' onClick={this.onPhoto}>
								<UserProfileImg
									id={this.props.item.id}
									profileImgUrl={this.props.item.profile_img_url}
									sex={this.props.item.sex}
									imgConfirm={this.props.item.pro_img_confirm}
									width="60"
									height="60"
									radius="4px"
								/>
							</div>
						}
						<div className='talk-content-label'>
							{this.props.item.content}
						</div>
					</div>
				</div>
				{
					this.props.item.id == this.props.rootStore.user.id ?
						<div className='rank-btn-div' onClick={this.onDelete}>
							<img src={"assets/btn_remove.png"} width={44} height={44} className='img-contain' />
							<label className={'talk-btn-label'}>{strings.delete}</label>
						</div>
						:
						<div className='rank-btn-div' onClick={this.onWrite}>
							<img src={"assets/btn_contact.png"} width={44} height={44} className='img-contain' />
							<label className={'talk-btn-label'}>{strings.write_msg}</label>
						</div>
				}

			</div>
		);
	}
}

export default TalkList;