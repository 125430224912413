import React from 'react';
import { Route, Link } from 'react-router-dom';
import strings from '../../../lang/strings.js';
import U from '../../../config/util';
import UserProfileImg from '../../../components/content/user_profile_img.jsx';

class FriendUserList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};

		this.onRequestVideo = this.onRequestVideo.bind(this);
		this.onWrite = this.onWrite.bind(this);
		this.onPhoto = this.onPhoto.bind(this);
	}

	onRequestVideo() {
        if (this.props.onRequestVideo) {
            this.props.onRequestVideo();
        }
    }

	onWrite() {
		if (this.props.onWrite) {
            this.props.onWrite();
        }
	}

	onPhoto() {
		if (this.props.onPhoto) {
            this.props.onPhoto();
        }
	}

	onFriendDel(index) {
		if (this.props.onFriendDel) {
            this.props.onFriendDel(index);
        }
	}

	render() {

		return (
			<div style={{
				display: 'flex', flexDirection: 'row', alignItems: 'center',
				padding: 10,
				borderBottom: '1px solid #eee',
				backgroundColor: '#FFF8F8'
			}}>
				<div className='profile-img-div' onClick={this.onPhoto}>
					<UserProfileImg
						id={this.props.item.id}
						profileImgUrl={this.props.item.profile_img_url}
						sex={this.props.item.sex}
						imgConfirm={this.props.item.img_confirm}
						width="60"
						height="60"
					/>
				</div>

				<div className='profile-nick-div'>
					<label className='rank-nick-label'>{this.props.item.nickname}</label>
					<div>
						<img src={this.props.item.sex == "f" ? "assets/ic_main_girl.png" : "assets/ic_main_man.png"} width={13} height={13} className='img-contain' />
						<label className={this.props.item.sex == "f" ? 'chat-age-label-girl' : 'chat-age-label-man'}>{this.props.item.age}</label>
					</div>
				</div>

				<div className='rank-btn-div' onClick={this.onWrite}>
					<img src={"assets/btn_contact.png"} width={44} height={44} className='img-contain' />
					<label className={'rank-btn-label'}>{strings.write_msg}</label>
				</div>

				<div className='rank-btn-div' onClick={this.onRequestVideo}>
					<img src={this.props.item.statevideo == "1" ? "assets/btn_videorequest_on.png" : "assets/btn_videorequest_off.png"} width={44} height={44} className='img-contain' />
					<label className={'rank-btn-label'}>{this.props.item.statevideo == "1" ? strings.in_video : strings.request_video}</label>
				</div>

				<div className='rank-btn-div' onClick={() => this.onFriendDel(this.props.index)}>
					<img src={"assets/btn_remove.png"} width={44} height={44} className='img-contain' />
					<label className={'delete-btn-label'}>{strings.delete}</label>
				</div>
			</div>
		);
	}
}

export default FriendUserList;