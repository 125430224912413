import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings.js';
import { inject } from 'mobx-react';

import $ from 'jquery';
import U from '../../config/util';
import NoticeList from './cell/notice-list.jsx';
import EventList from './cell/event-list.jsx';
import AlertDialog from "../dlg/alert-dialog.js";

class NoticeEventList extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            tabIndex: 0,
            noticeList: [],
            eventList: [],
            show_alert_popup: false,
            alert_msg: "",
        };

        this.onBack = this.onBack.bind(this);
    }

    componentDidMount() {
        this.getEventList();
        this.getNoticeList();
    }

    getEventList() {
        let self = this;
        self.context.post(
            'eventList',
            {
                id: this.props.rootStore.user.id,
                sex: this.props.rootStore.user.sex,
                level: this.props.rootStore.user.level,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.setState({
                        eventList: data.eventList
                    })
                }
            });
    }

    getNoticeList() {
        let self = this;
        self.context.post(
            'noticeList',
            {
                id: this.props.rootStore.user.id,
                sex: this.props.rootStore.user.sex,
                level: this.props.rootStore.user.level,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.setState({
                        noticeList: data.noticeList
                    })
                }
            });
    }

    selectTab(index) {
        this.setState({
            tabIndex: index
        })
    }

    onBack(event) {
        event.preventDefault();
        this.props.history.goBack();
    }

    onEvent(item) {
        if (item.status == 1) {
            let url = item.target_url;
            window.open(url, '_blank');
        } else if (item.status == 2) {
            this.onAlert("종료된 이벤트입니다.");
        } else if (item.status == 3) {
            this.onAlert("예정된 이벤트입니다.");
        }
    }

    onNotice(item) {
        if (item.content.includes("http")) {
            let url = item.content.substring(item.content.indexOf("[") + 1, item.content.lastIndexOf("]"));
            window.open(url, '_blank');
        }
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className='dashboard-div'>
                    <AlertDialog
                        msg={this.state.alert_msg}
                        isOpen={this.state.show_alert_popup}
                        onClose={() => {
                            this.setState({ show_alert_popup: false });
                        }} />
                    <div className='common-header-div borderb'>
                        <div className='common-header-back-div' onClick={this.onBack}>
                            <img src={"assets/btn_back.png"} width={15} height={13} />
                        </div>
                        <label className='common-header-title-label'>{strings.notice_event_title}</label>
                    </div>

                    <div className='point-history-header-div'>
                        <div className='notice_tab-item-div' onClick={() => this.selectTab(0)}>
                            <img src={"assets/" + (this.state.tabIndex == 0 ? "ic_alarm_selected.png" : "ic_alarm_unselected.png")} width={24} height={22} className='img-contain' />
                            <label className={'tab-label ' + (this.state.tabIndex == 0 ? 'selected' : '')}>{strings.tab_notice}</label>
                        </div>
                        <div className='notice_tab-item-div' onClick={() => this.selectTab(1)}>
                            <img src={"assets/" + (this.state.tabIndex == 1 ? "ic_event_selected.png" : "ic_event_unselected.png")} width={24} height={22} className='img-contain' />
                            <label className={'tab-label ' + (this.state.tabIndex == 1 ? 'selected' : '')}>{strings.tab_event}</label>
                        </div>
                    </div>

                    <div className='notice_container-div'>
                        {
                            this.state.tabIndex == 0 &&
                            <div className='scroll-notice-div'>
                                <ul>
                                    {
                                        this.state.noticeList.map((item, index) => {
                                            return <li key={index} onClick={() => this.onNotice(item)}>
                                                <NoticeList
                                                    index={index}
                                                    item={item}
                                                />
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        }
                        {
                            this.state.tabIndex == 1 && this.state.eventList.length > 0 &&
                            <div className='scroll-notice-div'>
                                <ul>
                                    {
                                        this.state.eventList.map((item, index) => {
                                            return <li key={index} onClick={() => this.onEvent(item)}>
                                                <EventList
                                                    index={index}
                                                    item={item}
                                                />
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        }
                        {
                            this.state.tabIndex == 1 && this.state.eventList.length == 0 &&
                            <div className='empty-div'>
                                <img src={"assets/ic_event_empty.png"} width={90} height={90} className='img-contain' />
                                <label className={'event-prepare-label'}>{strings.event_prepare}</label>
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(inject('rootStore')(NoticeEventList));