import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from "../../config/page-settings";
import { inject } from 'mobx-react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import TopUserList from './cell/top-user-list.jsx';
import LiveUserList from './cell/live-user-list.jsx';
import ProfileDialog from "../dlg/profile-dialog.js";
import AlertDialog from "../dlg/alert-dialog.js";
import ConfirmDialog from "../dlg/confirm-dialog.js";

class LiveFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            selectedOrder: '접속순',
            selectedSex: 'all',
            topUserList: [],
            pageNum: 0,
            isLast: false,
            userList: [],
            loading: false,
            dropdownOpen: false,
            show_detail_popup: false,
            peer_user: null,
            show_alert_popup: false,
            alert_msg: "",
            show_install_popup: false,
        };

        this.toggle = this.toggle.bind(this);
        this.onProfile = this.onProfile.bind(this);
        this.onProfile1 = this.onProfile1.bind(this);
        this.getRankingRealTimeList = this.getRankingRealTimeList.bind(this);
    }

    componentDidMount() {
        this.setState({
            selectedSex: this.props.rootStore.user.sex == "m" ? "f" : "m"
        }, () => {
            this.getRankingRealTimeList();
            this.getLiveList();
        })
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    selectSex(sex) {
        let self = this;
        this.setState({
            selectedSex: sex,
            pageNum: 0,
            isLast: false,
        }, () => {
            self.getLiveList();
        })
    }

    onProfile(item) {
        if (item.id == "admin" || item.id == "adminchat") {
            return;
        }

        //상대회원 리뷰정보 얻기
        let self = this;
        self.context.post(
            'otherUserReviewScore',
            {
                id: this.props.rootStore.user.id,
                peer_id: item.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    item.avg = data.avg;
                    item.sum = data.sum;
                    item.ulike_count = data.ulike_count;
                    item.ulike_status = data.ulike_status;
                    item.all_score = data.all_score;
                    item.level = data.level;
                    item.profile_img_url = item.pro_img_url;

                    sessionStorage.setItem('selectedUser', JSON.stringify(item));
                    self.props.history.push('/profile');
                }
            });
    }

    onProfile1(item) {
        if (item.statevideo == "1") {
            this.onAlert("상대방이 통화중입니다.");
            return;
        }

        //상대회원 리뷰정보 얻기
        let self = this;
        self.context.post(
            'otherUserReviewScore',
            {
                id: this.props.rootStore.user.id,
                peer_id: item.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    item.avg = data.avg;
                    item.sum = data.sum;
                    item.ulike_count = data.ulike_count;
                    item.ulike_status = data.ulike_status;
                    item.all_score = data.all_score;
                    item.level = data.level;

                    self.setState({
                        show_detail_popup: true,
                        peer_user: item,
                    });
                }
            });
    }

    //라이브 탑텐
    getRankingRealTimeList() {
        let self = this;
        self.context.post(
            'getRankingRealTimeList',
            {
                id: this.props.rootStore.user.id,
                sex: (this.props.rootStore.user.sex == "f" ? "m" : "f"),
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.setState({
                        topUserList: data.rankingList
                    })
                }
            });
    }

    //라이브 목록
    getLiveList() {
        if (this.state.isLast || this.state.loading) {
            return;
        }

        this.setState({
            loading: true
        })
        var filterSex = this.state.selectedSex;
        var filterOrder = this.state.selectedOrder == '접속순'
            ? 'login'
            : this.state.selectedOrder == '인기순'
                ? 'like'
                : 'register';

        let self = this;
        self.context.post(
            'getLiveList',
            {
                id: this.props.rootStore.user.id,
                page: this.state.pageNum,
                filter1: filterOrder == "register" ? "like" : filterSex,
                filter2: filterOrder,
                tabposition: "",
                location: "",
                longitude: "0",
                latitude: "0"
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    if (data.boardList.length == 0) {
                        self.setState({
                            isLast: true
                        })
                    }
                    if (self.state.pageNum == 0) {
                        self.setState({
                            userList: data.boardList
                        })
                    } else {
                        let list = self.state.userList.concat(data.boardList);
                        self.setState({
                            userList: list
                        })
                    }
                }
                self.setState({
                    loading: false
                })
            });
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 5
        if (bottom) {
            this.setState({
                pageNum: this.state.pageNum + 1
            }, () => {
                this.getLiveList();
            })

        }
    }

    onOrder(order) {
        let self = this;
        this.setState({
            selectedOrder: order,
            pageNum: 0,
            isLast: false,
        }, () => {
            self.getLiveList();
        })
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    openProfilePage() {
        let item = this.state.peer_user;

        if (item.id == "admin" || item.id == "adminchat") {
            return;
        }

        //상대회원 상세정보 얻기
        let self = this;
        self.context.post(
            'otherUserInfo',
            {
                id: item.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    item.level = data.level;
                    item.ulike = item.ulike;
                    item.profile_msg = item.profile_msg;
                    item.statevideo = item.statevideo;

                    sessionStorage.setItem('selectedUser', JSON.stringify(item));
                    self.props.history.push('/profile');
                }
            });
    }

    appInstall() {
        this.setState({
            show_install_popup: true
        })
    }

    appInstallLink() {
        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
        if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
            window.open(strings.appstore_link, '_blank');
        } else {
            window.open(strings.google_link, '_blank');
        }
    }

    render() {

        return (
            <div className='fragment-div horizontal'>
                <ProfileDialog
                    peer={this.state.peer_user}
                    rootStore={this.props.rootStore}
                    isOpen={this.state.show_detail_popup}
                    onClose={() => {
                        this.setState({ show_detail_popup: false });
                    }}
                    onRequestAudioVideo={(e) => {
                        this.setState({ show_detail_popup: false });
                        this.appInstall();
                    }}
                    onProfile={() => {
                        this.setState({ show_detail_popup: false });
                        if (this.state.peer_user.sex == this.props.rootStore.user.sex) {
                            this.onAlert(strings.not_view_same_gender);
                        } else {
                            this.openProfilePage();
                        }
                    }} />
                <AlertDialog
                    msg={this.state.alert_msg}
                    isOpen={this.state.show_alert_popup}
                    onClose={() => {
                        this.setState({ show_alert_popup: false });
                    }} />
                <ConfirmDialog
                    title="알림"
                    msg={strings.use_app_version}
                    cancelBtn="아니"
                    okBtn="예"
                    isOpen={this.state.show_install_popup}
                    onClose={() => {
                        this.setState({ show_install_popup: false });
                    }}
                    onCancel={() => {
                        this.setState({ show_install_popup: false });
                    }}
                    onConfirm={() => {
                        this.setState({ show_install_popup: false });
                        this.appInstallLink();
                    }} />
                <div className='top-live-div'>
                    <div className='top-live-header-div' onClick={this.getRankingRealTimeList}>
                        <label className={'top-live-header-label'}>{strings.live_top}</label>
                        <img src={"assets/ic_refresh_red.png"} width={14} height={14} className='img-contain' />
                    </div>

                    <div className='scroll-live-div'>
                        <ul>
                            {
                                this.state.topUserList.map((item, index) => {
                                    return <li key={index} onClick={() => this.onProfile(item)}>
                                        <TopUserList
                                            index={index}
                                            item={item}
                                        />
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>

                <div className='live-div'>
                    <div className='live-menu-div'>
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="dropdown navbar-user" tag="li">
                            <DropdownToggle tag="a">
                                <div className='live-sort-div'>
                                    <label className={'chat-label'}>{this.state.selectedOrder}</label>
                                    <img src={"assets/ic_spinner_triangle.png"} width={14} height={14} className='img-contain' />
                                </div>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
                                <DropdownItem onClick={() => this.onOrder('접속순')}>접속순</DropdownItem>
                                <DropdownItem onClick={() => this.onOrder('인기순')}>인기순</DropdownItem>
                                <DropdownItem onClick={() => this.onOrder('좋아요')}>좋아요</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                        <div className='chat-menu-sort-div'>
                            <div className={'chat-item-div ' + (this.state.selectedSex == "all" ? "selected" : "")} onClick={() => this.selectSex("all")}>
                                <label className={'chat-label ' + (this.state.selectedSex == "all" ? 'selected' : '')}>{strings.all}</label>
                            </div>

                            <div className={'chat-item-div ' + (this.state.selectedSex != "all" ? "selected" : "")} onClick={() => this.selectSex(this.props.rootStore.user.sex == "m" ? "f" : "m")}>
                                <label className={'chat-label ' + (this.state.selectedSex != "all" ? 'selected' : '')}>{this.props.rootStore.user.sex == "m" ? strings.female : strings.male}</label>
                            </div>
                        </div>
                    </div>

                    <div className='scroll-live-div' onScroll={this.handleScroll}>
                        <ul className='live-user-ul'>
                            {
                                this.state.userList.map((item, index) => {
                                    return <li key={index} onClick={() => this.onProfile1(item)}>
                                        <LiveUserList
                                            index={index}
                                            item={item}
                                        />
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(LiveFragment));