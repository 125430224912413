import React from 'react';
import { Route, Link } from 'react-router-dom';
import strings from '../../../lang/strings.js';
import U from '../../../config/util';

class PointChargeList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}


	render() {

		return (
			<div style={{
				display: 'flex', flexDirection: 'row', alignItems: 'center',
				marginTop: 10,
				marginLeft: 15,
				marginRight: 15,
				borderRadius: 6,
				backgroundImage: 'linear-gradient(to right, #FFEAEA , #FF9494)'
			}}>
				<img src={"assets/ic_battery_3000.png"} width={60} height={75}/>
				<div className='point-history-item-div'>
					<label style={{fontSize: 17, fontWeight: 'bold', color: '#7B7B7B'}}>{U.formatAssets(this.props.item.point)}P</label>
					<label style={{fontSize: 11, fontWeight: 'bold', color: '#7B7B7B'}}>+{U.formatAssets(this.props.item.bonus)}P</label>
				</div>
				<div className='point-price-div' style={{marginRight: 15}}>
					￦{U.formatAssets(this.props.item.price)}
				</div>
			</div>
		);
	}
}

export default PointChargeList;