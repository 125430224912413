import React from 'react';
import {Redirect} from 'react-router';
import strings from '../lang/strings.js';
import Login from './../pages/auth/login';
import Join from './../pages/auth/join';
import Dashboard from './../pages/home/dashboard';
import PointCharge from '../pages/setting/pointcharge.js';
import PointHistory from '../pages/setting/pointhistory.js';
import Blocklist from '../pages/setting/blocklist.js';
import Friendlist from '../pages/setting/friendlist.js';
import Noticeeventlist from '../pages/setting/noticeeventlist.js';
import Passbook from '../pages/setting/passbook.js';
import Useguide from '../pages/setting/useguide.js';
import Setting from '../pages/setting/setting.js';
import Profile from '../pages/home/page/profile.js';
import Chat from '../pages/home/page/chat.js';
import ProfileEdit from '../pages/setting/profile_edit.js';

const routes = [
    {
        path: '/',
        exact: true,
        title: '',
        access: 'private',
        component: () => <Login/>
    },
    {
        path: '/login',
        exact: true,
        title: strings.join,
        access: 'public',
        component: () => <Login/>
    },
    {
        path: '/join',
        exact: true,
        title: strings.join,
        access: 'public',
        component: () => <Join/>
    },
    {
        path: '/dashboard',
        exact: true,
        title: strings.dashboard,
        access: 'private',
        component: () => <Dashboard/>
    },
    {
        path: '/pointcharge',
        exact: true,
        title: strings.point_charge,
        access: 'private',
        component: PointCharge
    },
    {
        path: '/pointhistory',
        exact: true,
        title: strings.point_history,
        access: 'private',
        component: PointHistory
    },
    {
        path: '/blocklist',
        exact: true,
        title: strings.block_list,
        access: 'private',
        component: Blocklist
    },
    {
        path: '/friendlist',
        exact: true,
        title: strings.friend_list,
        access: 'private',
        component: Friendlist
    },
    {
        path: '/noticeeventlist',
        exact: true,
        title: strings.notice_event_title,
        access: 'private',
        component: Noticeeventlist
    },
    {
        path: '/passbook',
        exact: true,
        title: strings.passbook,
        access: 'private',
        component: Passbook
    },
    {
        path: '/useguide',
        exact: true,
        title: strings.usage_guide,
        access: 'private',
        component: Useguide
    },
    {
        path: '/setting',
        exact: true,
        title: strings.setting,
        access: 'private',
        component: Setting
    },
    {
        path: '/profile',
        exact: true,
        title: strings.profile,
        access: 'private',
        component: Profile
    },
    {
        path: '/profile_edit',
        exact: true,
        title: strings.profile,
        access: 'private',
        component: ProfileEdit
    },
    {
        path: '/chat',
        exact: true,
        title: strings.chat,
        access: 'private',
        component: Chat
    },
];

export default routes;