import React from 'react';

class ProgressBar extends React.Component {
    render() {
        return (
            <div style={{
                position: 'fixed',
                left: '0',
                width: '100%',
                top: '0',
                height: '100%',
                backgroundColor: 'rgba(0,0,0,0.2)',
                fontSize: '3em',
                textAlign: 'center',
                zIndex: '30000',
            }}>
                <i className="fas fa-spinner fa-pulse" style={{
                    position: 'relative',
                    top: '45%',
                }}></i>
            </div>
        )
    }
}

export default ProgressBar;