import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from "../../config/page-settings";
import { inject } from 'mobx-react';
import ChatRoomList from './cell/chat-room-list.jsx';
import ConfirmDialog from "../dlg/confirm-dialog.js";
import AlertDialog from "../dlg/alert-dialog.js";
import LongPressable from 'react-longpressable';

class ChatFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            selectedChatFilter: "0", //0:전체, 1:친구
            pageNum: 0,
            isLast: false,
            chatList: [],
            loading: false,
            show_all_read_popup: false,
            show_all_delete_popup: false,
            show_alert_popup: false,
            alert_msg: "",
            show_delete_room_popup: false,
            selectedItem: null,
        };

        this.onChatReadAll = this.onChatReadAll.bind(this);
        this.onChatDeleteAll = this.onChatDeleteAll.bind(this);
    }

    componentDidMount() {
        this.props.parent.resumeHomePage();
        this.getChatRoomList();
    }

    selectChatFilter(filter) {
        let self = this;
        this.setState({
            selectedChatFilter: filter,
            pageNum: 0,
            isLast: false,
        }, () => {
            self.getChatRoomList();
        })
    }

    onChatReadAll(event) {
        event.preventDefault();
        this.setState({
            show_all_read_popup: true
        })
    }

    onChatDeleteAll(event) {
        event.preventDefault();
        this.setState({
            show_all_delete_popup: true
        })
    }

    chatDeleteAll() {
        let self = this;
        self.context.post(
            'chatDeleteAll',
            {
                id: this.props.rootStore.user.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("전체 삭제 처리되었습니다.");
                    self.setState({
                        pageNum: 0,
                        isLast: false,
                        chatList: []
                    })
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    chatReadAll() {
        let self = this;
        self.context.post(
            'chatReadAll',
            {
                id: this.props.rootStore.user.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.resumeHomePage();
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    resumeHomePage() {
        this.props.parent.resumeHomePage();

        this.setState({
            pageNum: 0,
            isLast: false,
        }, () => {
            this.getChatRoomList();
        })
    }

    getChatRoomList() {
        if (this.state.isLast || this.state.loading) {
            return;
        }

        this.setState({
            loading: true
        })
        let self = this;
        self.context.post(
            'getChatRoomList',
            {
                id: this.props.rootStore.user.id,
                page: this.state.pageNum,
                is_friend: this.state.selectedChatFilter,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    if (data.roomList.length == 0) {
                        self.setState({
                            isLast: true
                        })
                    }
                    if (self.state.pageNum == 0) {
                        self.setState({
                            chatList: data.roomList
                        })
                    } else {
                        let list = self.state.chatList.concat(data.roomList);
                        self.setState({
                            chatList: list
                        })
                    }
                }
                self.setState({
                    loading: false
                })
            });
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 5
        if (bottom) {
            this.setState({
                pageNum: this.state.pageNum + 1
            }, () => {
                this.getChatRoomList();
            })

        }
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    chatRoomOut() {
        let self = this;
        self.context.post(
            'chatRoomOut',
            {
                id: this.props.rootStore.user.id,
                peer_id: this.state.selectedItem.peer_id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.resumeHomePage();
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    render() {

        return (
            <div className='fragment-div'>
                <AlertDialog
                    msg={this.state.alert_msg}
                    isOpen={this.state.show_alert_popup}
                    onClose={() => {
                        this.setState({ show_alert_popup: false });
                    }} />
                <ConfirmDialog
                    title="알림"
                    msg="모든 채팅 방을<br>삭제 하시겠습니까?"
                    cancelBtn="취소"
                    okBtn="삭제"
                    isOpen={this.state.show_all_delete_popup}
                    onClose={() => {
                        this.setState({ show_all_delete_popup: false });
                    }}
                    onCancel={() => {
                        this.setState({ show_all_delete_popup: false });
                    }}
                    onConfirm={() => {
                        this.setState({ show_all_delete_popup: false });
                        this.chatDeleteAll();
                    }} />
                <ConfirmDialog
                    title="알림"
                    msg="모든 메시지를 읽은 상태로<br>변경하시겠습니까?"
                    cancelBtn="아니요"
                    okBtn="네"
                    isOpen={this.state.show_all_read_popup}
                    onClose={() => {
                        this.setState({ show_all_read_popup: false });
                    }}
                    onCancel={() => {
                        this.setState({ show_all_read_popup: false });
                    }}
                    onConfirm={() => {
                        this.setState({ show_all_read_popup: false });
                        this.chatReadAll();
                    }} />
                <ConfirmDialog
                    title="알림"
                    cancelBtn="아니요"
                    msg={(this.state.selectedItem != null ? this.state.selectedItem.peer_nickname : "") + "님과의 채팅 방을<br>삭제하시겠습니까?"}
                    okBtn="네"
                    isOpen={this.state.show_delete_room_popup}
                    onClose={() => {
                        this.setState({ show_delete_room_popup: false });
                    }}
                    onCancel={() => {
                        this.setState({ show_delete_room_popup: false });
                    }}
                    onConfirm={() => {
                        this.setState({ show_delete_room_popup: false });
                        this.chatRoomOut();
                    }} />
                <div className='chat-menu-div'>
                    <div className='chat-menu-item' onClick={this.onChatReadAll}>
                        <img src={"assets/ic_mail_check.png"} width={24} height={16} className='img-contain' />
                        <label className='tab-label selected'>{strings.chat_all_read}</label>
                    </div>

                    <div className='chat-menu-item' onClick={this.onChatDeleteAll}>
                        <img src={"assets/ic_recycle.png"} width={24} height={16} className='img-contain' />
                        <label className='tab-label'>{strings.chat_all_delete}</label>
                    </div>

                    <div className='chat-menu-sort-div'>
                        <div className={'chat-item-div ' + (this.state.selectedChatFilter == "0" ? "selected" : "")} onClick={() => this.selectChatFilter("0")}>
                            <label className={'chat-label ' + (this.state.selectedChatFilter == "0" ? 'selected' : '')}>{strings.all}</label>
                        </div>

                        <div className={'chat-item-div ' + (this.state.selectedChatFilter == "1" ? "selected" : "")} onClick={() => this.selectChatFilter("1")}>
                            <label className={'chat-label ' + (this.state.selectedChatFilter == "1" ? 'selected' : '')}>{strings.friend}</label>
                        </div>
                    </div>
                </div>

                <div className='scroll-chat-div' onScroll={this.handleScroll}>
                    {
                        this.state.chatList.length == 0 ?
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 40 }}>
                                <label className='tab-label'>{strings.empty_chat_room}</label>
                            </div>
                            :
                            <ul>
                                {
                                    this.state.chatList.map((item, index) => {
                                        return <li key={index}>
                                            <LongPressable
                                                onShortPress={() => {
                                                    sessionStorage.setItem('selectedPeerId', item.peer_id);
                                                    this.props.history.push('/chat');
                                                }}
                                                onLongPress={() => {
                                                    this.setState({
                                                        selectedItem: item,
                                                        show_delete_room_popup: true
                                                    })
                                                }}
                                                longPressTime={700}>
                                                <ChatRoomList
                                                    index={index}
                                                    item={item}
                                                />
                                            </LongPressable>

                                        </li>
                                    })
                                }
                            </ul>
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(ChatFragment));