import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings.js';
import { inject } from 'mobx-react';

import $ from 'jquery';
import U from '../../config/util';
import FriendUserList from './cell/frienduser-list.jsx';
import ConfirmDialog from "../dlg/confirm-dialog.js";
import SendMsgDialog from "../dlg/send-msg-dialog.js";
import AlertDialog from "../dlg/alert-dialog.js";
import ProfileDialog from "../dlg/profile-dialog.js";

class FriendList extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            pageNum: 0,
            isLast: false,
            friendList: [],
            loading: false,
            show_friend_del_popup: false,
            selectedIndex: -1,
            show_detail_popup: false,
            peer_user: null,
            show_alert_popup: false,
            alert_msg: "",
            show_chat_popup: false,
            show_charge_popup: false,
            show_install_popup: false,
        };

        this.onBack = this.onBack.bind(this);
    }

    componentDidMount() {
        this.getFriendList();
    }

    getFriendList = () => {
        if (this.state.isLast || this.state.loading) {
            return;
        }

        this.setState({
            loading: true
        })
        let self = this;
        self.context.post(
            'getFriendBlockList',
            {
                id: this.props.rootStore.user.id,
                stype: "friend",
                page: this.state.pageNum
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    if (data.friendList.length == 0) {
                        self.setState({
                            isLast: true
                        })
                    }
                    if (self.state.pageNum == 0) {
                        self.setState({
                            friendList: data.friendList
                        })
                    } else {
                        let list = self.state.friendList.concat(data.friendList);
                        self.setState({
                            friendList: list
                        })
                    }
                }
                self.setState({
                    loading: false
                })
            });
    };

    onBack(event) {
        event.preventDefault();
        this.props.history.goBack();
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 5
        if (bottom) {
            this.setState({
                pageNum: this.state.pageNum + 1
            }, () => {
                this.getFriendList();
            })

        }
    }

    onFriendDel(index) {
        this.setState({
            selectedIndex: index,
            show_friend_del_popup: true
        })
    }

    reqFriendDel() {
        let peer = this.state.friendList[this.state.selectedIndex];

        let self = this;
        self.context.post(
            'deletefriend',
            {
                id: this.props.rootStore.user.id,
                peer_id: peer.id
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("친구 삭제되었습니다.");

                    let filteredArray = self.state.friendList.filter(item => item !== peer)
                    self.setState({
                        selectedIndex: -1,
                        friendList: filteredArray
                    })
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    openProfilePage() {
        let item = this.state.peer_user;

        if (item.id == "admin" || item.id == "adminchat") {
            return;
        }

        //상대회원 상세정보 얻기
        let self = this;
        self.context.post(
            'otherUserInfo',
            {
                id: item.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    item.level = data.level;
                    item.ulike = item.ulike;
                    item.profile_msg = item.profile_msg;
                    item.statevideo = item.statevideo;

                    sessionStorage.setItem('selectedUser', JSON.stringify(item));
                    self.props.history.push('/profile');
                }
            });
    }

    onChat() {
        let friend = this.state.friendList[this.state.selectedIndex];

        let self = this;
        self.context.post(
            'isChatRoomOpen',
            {
                id: this.props.rootStore.user.id,
                peer_id: friend.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    //채팅방 열림-이동
                    sessionStorage.setItem('selectedPeerId', friend.id);
                    self.props.history.push('/chat');
                } else if (data.result == "fail") {
                    self.setState({
                        show_chat_popup: true
                    });
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onSendMsg(msg) {
        if (Number(this.props.rootStore.systempoint.priceMsg) > Number(this.props.rootStore.user.point)) {
            if (this.props.rootStore.user.sex == "m") {
                this.setState({
                    show_charge_popup: true
                });
            } else {
                self.onAlert("포인트가 부족합니다.");
            }
            return;
        }

        let friend = this.state.friendList[this.state.selectedIndex];
        let self = this;
        self.context.post(
            'newMessage',
            {
                id: this.props.rootStore.user.id,
                peer_id: friend.id,
                content: msg,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("쪽지 발송되었습니다.");

                    let user = self.props.rootStore.user;
                    user.point = data.point;
                    self.props.rootStore.login(user);
                } else if (data.result == "userblock") {
                    self.onAlert("상대방이 차단했습니다.");
                } else if (data.result == "notenough") {
                    self.onAlert("포인트가 부족합니다.");
                } else if (data.result == "fail") {
                    self.onAlert(data.msg);
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    appInstall() {
        this.setState({
            show_install_popup: true
        })
    }

    appInstallLink() {
        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
        if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
            window.open(strings.appstore_link, '_blank');
        } else {
            window.open(strings.google_link, '_blank');
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='dashboard-div'>
                    <ConfirmDialog
                        title="알림"
                        msg={strings.use_app_version}
                        cancelBtn="아니"
                        okBtn="예"
                        isOpen={this.state.show_install_popup}
                        onClose={() => {
                            this.setState({ show_install_popup: false });
                        }}
                        onCancel={() => {
                            this.setState({ show_install_popup: false });
                        }}
                        onConfirm={() => {
                            this.setState({ show_install_popup: false });
                            this.appInstallLink();
                        }} />
                    <ConfirmDialog
                        title="알림"
                        msg="포인트가 부족하십니다.<br>포인트를 충전 하시겠습니까?"
                        cancelBtn="닫기"
                        okBtn="충전"
                        isOpen={this.state.show_charge_popup}
                        onClose={() => {
                            this.setState({ show_charge_popup: false });
                        }}
                        onCancel={() => {
                            this.setState({ show_charge_popup: false });
                        }}
                        onConfirm={() => {
                            this.setState({ show_charge_popup: false });
                            this.props.history.push('/pointcharge');
                        }} />
                    <SendMsgDialog
                        rootStore={this.props.rootStore}
                        peer={this.state.selectedIndex == -1 ? null : this.state.friendList[this.state.selectedIndex]}
                        isOpen={this.state.show_chat_popup}
                        onClose={() => {
                            this.setState({ show_chat_popup: false });
                        }}
                        onSend={(msg) => {
                            this.setState({ show_chat_popup: false });
                            this.onSendMsg(msg);
                        }} />
                    <ProfileDialog
                        peer={this.state.peer_user}
                        rootStore={this.props.rootStore}
                        isOpen={this.state.show_detail_popup}
                        onClose={() => {
                            this.setState({ show_detail_popup: false });
                        }}
                        onRequestAudioVideo={(e) => {
                            this.setState({ show_detail_popup: false });
                            this.appInstall();
                        }}
                        onProfile={() => {
                            this.setState({ show_detail_popup: false });
                            if (this.state.peer_user.sex == this.props.rootStore.user.sex) {
                                this.onAlert(strings.not_view_same_gender);
                            } else {
                                this.openProfilePage();
                            }
                        }} />
                    <AlertDialog
                        msg={this.state.alert_msg}
                        isOpen={this.state.show_alert_popup}
                        onClose={() => {
                            this.setState({ show_alert_popup: false });
                        }} />
                    <ConfirmDialog
                        title="알림"
                        msg="친구목록에서 삭제하시겠습니까?"
                        cancelBtn="아니요"
                        okBtn="네"
                        isOpen={this.state.show_friend_del_popup}
                        onClose={() => {
                            this.setState({ show_friend_del_popup: false });
                        }}
                        onCancel={() => {
                            this.setState({ show_friend_del_popup: false });
                        }}
                        onConfirm={() => {
                            this.setState({ show_friend_del_popup: false });
                            this.reqFriendDel();
                        }} />

                    <div className='common-header-div borderb'>
                        <div className='common-header-back-div' onClick={this.onBack}>
                            <img src={"assets/btn_back.png"} width={15} height={13} />
                        </div>
                        <label className='common-header-title-label'>{strings.friend_list}</label>
                    </div>

                    <div className='scroll-blocklist-div' onScroll={this.handleScroll}>
                        {
                            this.state.friendList.length == 0 ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 40 }}>
                                    <label className='tab-label'>{strings.empty_friend_list}</label>
                                </div>
                                :
                                <ul>
                                    {
                                        this.state.friendList.map((item, index) => {
                                            return <li key={index}>
                                                <FriendUserList
                                                    index={index}
                                                    item={item}
                                                    onPhoto={() => {
                                                        if (item.sex == this.props.rootStore.user.sex) {
                                                            this.onAlert(strings.not_view_same_gender);
                                                        } else {
                                                            if (item.id == "admin" || item.id == "adminchat") {
                                                                return;
                                                            }

                                                            item.pro_img_url = item.profile_img_url;

                                                            //상대회원 리뷰정보 얻기
                                                            let self = this;
                                                            self.context.post(
                                                                'otherUserReviewScore',
                                                                {
                                                                    id: this.props.rootStore.user.id,
                                                                    peer_id: item.id,
                                                                },
                                                                function (response) {
                                                                    var data = JSON.parse(response.data);
                                                                    // console.log(data);
                                                                    if (data.result == "success") {
                                                                        item.avg = data.avg;
                                                                        item.sum = data.sum;
                                                                        item.ulike_count = data.ulike_count;
                                                                        item.ulike_status = data.ulike_status;
                                                                        item.all_score = data.all_score;
                                                                        item.level = data.level;

                                                                        self.setState({
                                                                            peer_user: item,
                                                                        }, () => {
                                                                            self.openProfilePage();
                                                                        });
                                                                    }
                                                                });
                                                        }
                                                    }}
                                                    onWrite={() => {
                                                        if (item.sex == this.props.rootStore.user.sex) {
                                                            this.onAlert(strings.not_msg_same_gender);
                                                        } else {
                                                            this.setState({
                                                                selectedIndex: index,
                                                            }, () => {
                                                                this.onChat();
                                                            })
                                                        }
                                                    }}
                                                    onRequestVideo={(e) => {
                                                        if (item.id == "admin" || item.id == "adminchat") {
                                                            return;
                                                        }

                                                        if (item.statevideo == "1") {
                                                            this.onAlert("상대방이 통화중입니다.")
                                                            return;
                                                        }

                                                        item.pro_img_url = item.profile_img_url;

                                                        //상대회원 리뷰정보 얻기
                                                        let self = this;
                                                        self.context.post(
                                                            'otherUserReviewScore',
                                                            {
                                                                id: this.props.rootStore.user.id,
                                                                peer_id: item.id,
                                                            },
                                                            function (response) {
                                                                var data = JSON.parse(response.data);
                                                                // console.log(data);
                                                                if (data.result == "success") {
                                                                    item.avg = data.avg;
                                                                    item.sum = data.sum;
                                                                    item.ulike_count = data.ulike_count;
                                                                    item.ulike_status = data.ulike_status;
                                                                    item.all_score = data.all_score;
                                                                    item.level = data.level;

                                                                    self.setState({
                                                                        show_detail_popup: true,
                                                                        peer_user: item,
                                                                    });
                                                                }
                                                            });
                                                    }}
                                                    onFriendDel={(index) => {
                                                        this.onFriendDel(index);
                                                    }}
                                                />
                                            </li>
                                        })
                                    }
                                </ul>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(inject('rootStore')(FriendList));