import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import U from './../../config/util';
import ImageSelect from '../../components/content/image-select.jsx';
import UserProfileImg from '../../components/content/user_profile_img.jsx';

export default class PassportDialog extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {},
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    render() {
        let item = this.state.item;

        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.toggleModal}
                    size={'md'}>
                    <ModalBody style={{padding: 0}}>
                        <div style={{height: 44, background: '#FF545A', display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center', padding: '0 12px'}}>
                            <img src={"assets/ic_close.png"} width={20} height={20} className='img-contain' onClick={this.toggleModal}/>
                        </div>
                        <div style={{background: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 10}}>
                            <img src={"assets/ic_refund.png"} width={76} height={76} className='img-contain' style={{margin: 10}}/>
                            <p dangerouslySetInnerHTML={{__html: strings.passport_desc}} style={{fontSize: 12, color: '#A1A1A1', textAlign: 'center'}}></p>
                            <label style={{fontSize: 12, color: '#474747', marginTop: 10, marginBottom: 10, textAlign: 'center'}}>입금계좌 : {this.props.bank_info}</label>
                            <label style={{fontSize: 12, color: '#FF545A'}}>금 액 : {U.currencyFormat(this.props.price)}원</label>
                            <div className='dashed-line-div' style={{width: '100%', marginTop: 10, marginBottom: 10}}></div>
                            <label style={{fontSize: 10, color: '#A1A1A1'}}>입금자명과 금액이 정확히 일치해야 자동으로 처리됩니다.</label>
                            <div style={{height: 36, width: '100%', marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{height: 36, width: 120, borderRadius: 18, backgroundColor: '#FF777A', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} onClick={this.toggleModal}>
                                    <label style={{color: '#fff', fontSize: 15, marginLeft: 6}}>신청완료</label>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}