import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings.js';
import { inject } from 'mobx-react';

import $ from 'jquery';
import LiveFragment from './live-fragment.js';
import TalkFragment from './talk-fragment.js';
import RankFragment from './rank-fragment.js';
import ChatFragment from './chat-fragment.js';
import MoreFragment from './more-fragment.js';
import U from '../../config/util';
import ConfirmDialog from "../dlg/confirm-dialog.js";

class Dashboard extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            tabIndex: 0,
            point: props.rootStore.user.point,
            unread: 0,
            show_install_popup: false,
        };

        this.onPoint = this.onPoint.bind(this);
    }

    componentDidMount() {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        };
        this.resumeHomePage();
    }

    componentWillMount() {
        if (sessionStorage.getItem('mainTabIndex') != null) {
            this.setState({
                tabIndex: sessionStorage.getItem('mainTabIndex')
            })
        }
    }

    componentWillUnmount() {
        sessionStorage.setItem('mainTabIndex', this.state.tabIndex);
    }

    selectTab(index) {
        sessionStorage.setItem('mainTabIndex', index);
        this.setState({
            tabIndex: index
        })
    }

    onPoint(event) {
        event.preventDefault();
        if (this.props.rootStore.user.sex == "m") {
            this.props.history.push('/pointcharge');
        } else {
            this.props.history.push('/pointhistory');
        }
    }

    resumeHomePage() {
        this.getPointInfo();
        this.getUnreadChatCount();
    }

    getPointInfo() {
        let self = this;
        self.context.post(
            'getPointInfo',
            {
                id: this.props.rootStore.user.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    let user = self.props.rootStore.user;
                    user.point = data.point;
                    self.props.rootStore.login(user);
                    self.setState({
                        point: user.point
                    });
                }
            });
    }

    getUnreadChatCount() {
        let self = this;
        self.context.post(
            'getUnreadChatCount',
            {
                id: this.props.rootStore.user.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.setState({
                        unread: data.data
                    });
                }
            });
    }

    appInstall() {
        this.setState({
            show_install_popup: true
        })
    }

    appInstallLink() {
        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
        if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
            window.open(strings.appstore_link, '_blank');
        } else {
            window.open(strings.google_link, '_blank');
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='dashboard-div'>
                    <ConfirmDialog
                        title="알림"
                        msg="앱을 다운로드하시겠습니까?"
                        cancelBtn="아니"
                        okBtn="예"
                        isOpen={this.state.show_install_popup}
                        onClose={() => {
                            this.setState({ show_install_popup: false });
                        }}
                        onCancel={() => {
                            this.setState({ show_install_popup: false });
                        }}
                        onConfirm={() => {
                            this.setState({ show_install_popup: false });
                            this.appInstallLink();
                        }} />
                    <div className='header-div'>
                        <div style={{ flexGrow: 100, paddingLeft: 20 }}>
                            <img src={"assets/ic_main_title.png"} width={'90px'} height={'24px'} onClick={() => this.appInstall()} />
                        </div>
                        <div className='header-point-div' onClick={this.onPoint}>
                            <img src={"assets/ic_pointcharge.png"} width={'19px'} height={'11px'} />
                            <label className='header-point-label'>{U.formatAssets(this.state.point)}</label>
                        </div>
                    </div>

                    <div className='tab-div'>
                        <div className='tab-item-div' onClick={() => this.selectTab(0)}>
                            <img src={"assets/" + (this.state.tabIndex == 0 ? "ic_main_tab_live_sel.png" : "ic_main_tab_live_unsel.png")} width={'34px'} height={'34px'} className='img-contain' />
                            <label className={'tab-label ' + (this.state.tabIndex == 0 ? 'selected' : '')}>{strings.tab_live}</label>
                        </div>
                        <div className='tab-item-div' onClick={() => this.selectTab(1)}>
                            <img src={"assets/" + (this.state.tabIndex == 1 ? "ic_main_tab_talk_sel.png" : "ic_main_tab_talk_unsel.png")} width={'22px'} height={'34px'} className='img-contain' />
                            <label className={'tab-label ' + (this.state.tabIndex == 1 ? 'selected' : '')}>{strings.tab_talk}</label>
                        </div>
                        <div className='tab-item-div' onClick={() => this.selectTab(2)}>
                            <img src={"assets/" + (this.state.tabIndex == 2 ? "ic_main_tab_ranking_sel.png" : "ic_main_tab_ranking_unsel.png")} width={'25px'} height={'34px'} className='img-contain' />
                            <label className={'tab-label ' + (this.state.tabIndex == 2 ? 'selected' : '')}>{strings.tab_rank}</label>
                        </div>
                        <div className='tab-item-div' onClick={() => this.selectTab(3)}>
                            <div style={{ height: '34px', width: '100%', position: 'relative' }}>
                                <img src={"assets/" + (this.state.tabIndex == 3 ? "ic_main_tab_chat_sel.png" : "ic_main_tab_chat_unsel.png")} width={'24px'} height={'34px'} className='img-contain' style={{ marginLeft: 'calc(50% - 12px)' }} />
                                {
                                    this.state.unread != 0 &&
                                    <div className='chat-unread-div' style={{ position: 'absolute', top: 0, right: 'calc(50% - 22px)' }}>
                                        <label className='chat-unread-label'>{this.state.unread}</label>
                                    </div>
                                }
                            </div>
                            <label className={'tab-label ' + (this.state.tabIndex == 3 ? 'selected' : '')}>{strings.tab_chat}</label>
                        </div>
                        <div className='tab-item-div' onClick={() => this.selectTab(4)}>
                            <img src={"assets/" + (this.state.tabIndex == 4 ? "ic_main_tab_more_sel.png" : "ic_main_tab_more_unsel.png")} width={'13px'} height={'34px'} className='img-contain' />
                            <label className={'tab-label ' + (this.state.tabIndex == 4 ? 'selected' : '')}>{strings.tab_more}</label>
                        </div>
                    </div>

                    <div className='container-div'>
                        {
                            this.state.tabIndex == 0 &&
                            <LiveFragment
                                parent={this}
                            />
                        }
                        {
                            this.state.tabIndex == 1 &&
                            <TalkFragment
                                parent={this}
                            />
                        }
                        {
                            this.state.tabIndex == 2 &&
                            <RankFragment
                                parent={this}
                            />
                        }
                        {
                            this.state.tabIndex == 3 &&
                            <ChatFragment
                                parent={this}
                            />
                        }
                        {
                            this.state.tabIndex == 4 &&
                            <MoreFragment
                                parent={this}
                            />
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(inject('rootStore')(Dashboard));