import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import RankList from './cell/rank-list.jsx';
import RankingDialog from "../dlg/ranking-dialog.js";
import AlertDialog from "../dlg/alert-dialog.js";
import ConfirmDialog from "../dlg/confirm-dialog.js";
import SendMsgDialog from "../dlg/send-msg-dialog.js";

class RankFragment extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            selectedRankSex: 'm', //m, f //랭킹
            selectedRankDate: '1',//0:오늘, 1:이번주, 2:지난주
            rankList: [],
            show_detail_popup: false,
            show_alert_popup: false,
            alert_msg: "",
            selectedIndex: -1,
            show_chat_popup: false,
            show_charge_popup: false,
            show_install_popup: false,
        };

        this.onRanking = this.onRanking.bind(this);
    }

    componentDidMount() {
        this.setState({
            selectedRankSex: this.props.rootStore.user.sex == "m" ? "f" : "m"
        }, () => {
            this.getRankList();
        })
    }

    selectGender(index) {
        let self = this;
        this.setState({
            selectedRankSex: index
        }, () => {
            self.getRankList();
        })
    }

    selectDate(index) {
        let self = this;
        this.setState({
            selectedRankDate: index
        }, () => {
            self.getRankList();
        })
    }

    onRanking(event) {
        this.setState({
            show_detail_popup: true
        });
    }

    //랭킹리스트
    getRankList() {
        let self = this;
        self.context.post(
            'getRankList',
            {
                id: this.props.rootStore.user.id,
                sex: this.state.selectedRankSex == 'm' ? '남자' : '여자',
                period: this.state.selectedRankDate == '1'
                    ? "오늘"
                    : this.state.selectedRankDate == '2'
                        ? "이번주"
                        : "지난주",
            },
            function (response) {
                var data = JSON.parse(response.data);
                console.log(data);
                if (data.result == "success") {
                    self.setState({
                        rankList: data.user_list
                    })
                }
            });
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    onChat(event) {
        let rank = this.state.rankList[this.state.selectedIndex];

        let self = this;
        self.context.post(
            'isChatRoomOpen',
            {
                id: this.props.rootStore.user.id,
                peer_id: rank.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    //채팅방 열림-이동
                    sessionStorage.setItem('selectedPeerId', rank.id);
                    self.props.history.push('/chat');
                } else if (data.result == "fail") {
                    self.setState({
                        show_chat_popup: true
                    });
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    onSendMsg(msg) {
        if (Number(this.props.rootStore.systempoint.priceMsg) > Number(this.props.rootStore.user.point)) {
            if (this.props.rootStore.user.sex == "m") {
                this.setState({
                    show_charge_popup: true
                });
            } else {
                this.onAlert("포인트가 부족합니다.");
            }
            return;
        }

        let rank = this.state.rankList[this.state.selectedIndex];
        let self = this;
        self.context.post(
            'newMessage',
            {
                id: this.props.rootStore.user.id,
                peer_id: rank.id,
                content: msg,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    self.onAlert("쪽지 발송되었습니다.");

                    let user = self.props.rootStore.user;
                    user.point = data.point;
                    self.props.rootStore.login(user);
                } else if (data.result == "userblock") {
                    self.onAlert("상대방이 차단했습니다.");
                } else if (data.result == "notenough") {
                    self.onAlert("포인트가 부족합니다.");
                } else if (data.result == "fail") {
                    self.onAlert(data.msg);
                } else {
                    self.onAlert("서버와의 연결이 원할하지 않습니다.");
                }
            });
    }

    openProfilePage() {
        let item = this.state.peer_user;

        if (item.id == "admin" || item.id == "adminchat") {
            return;
        }

        //상대회원 상세정보 얻기
        let self = this;
        self.context.post(
            'otherUserInfo',
            {
                id: item.id,
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    item.level = data.level;
                    item.ulike = item.ulike;
                    item.profile_msg = item.profile_msg;
                    item.statevideo = item.statevideo;

                    sessionStorage.setItem('selectedUser', JSON.stringify(item));
                    self.props.history.push('/profile');
                }
            });
    }

    appInstall() {
        this.setState({
            show_install_popup: true
        })
    }

    appInstallLink() {
        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
        if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
            window.open(strings.appstore_link, '_blank');
        } else {
            window.open(strings.google_link, '_blank');
        }
    }

    render() {

        return (
            <div className='fragment-div'>
                <ConfirmDialog
                    title="알림"
                    msg={strings.use_app_version}
                    cancelBtn="아니"
                    okBtn="예"
                    isOpen={this.state.show_install_popup}
                    onClose={() => {
                        this.setState({ show_install_popup: false });
                    }}
                    onCancel={() => {
                        this.setState({ show_install_popup: false });
                    }}
                    onConfirm={() => {
                        this.setState({ show_install_popup: false });
                        this.appInstallLink();
                    }} />
                <RankingDialog
                    rootStore={this.props.rootStore}
                    isOpen={this.state.show_detail_popup}
                    onClose={() => {
                        this.setState({ show_detail_popup: false });
                    }} />
                <AlertDialog
                    msg={this.state.alert_msg}
                    isOpen={this.state.show_alert_popup}
                    onClose={() => {
                        this.setState({ show_alert_popup: false });
                    }} />
                <SendMsgDialog
                    rootStore={this.props.rootStore}
                    peer={this.state.selectedIndex == -1 ? null : this.state.rankList[this.state.selectedIndex]}
                    isOpen={this.state.show_chat_popup}
                    onClose={() => {
                        this.setState({ show_chat_popup: false });
                    }}
                    onSend={(msg) => {
                        this.setState({ show_chat_popup: false });
                        this.onSendMsg(msg);
                    }} />
                <ConfirmDialog
                    title="알림"
                    msg="포인트가 부족하십니다.<br>포인트를 충전 하시겠습니까?"
                    cancelBtn="닫기"
                    okBtn="충전"
                    isOpen={this.state.show_charge_popup}
                    onClose={() => {
                        this.setState({ show_charge_popup: false });
                    }}
                    onCancel={() => {
                        this.setState({ show_charge_popup: false });
                    }}
                    onConfirm={() => {
                        this.setState({ show_charge_popup: false });
                        this.props.history.push('/pointcharge');
                    }} />

                <div className='chat-menu-div'>
                    <div className='chat-menu-sort-div'>
                        <div className={'chat-item-div ' + (this.state.selectedRankSex == "f" ? "selected" : "")} onClick={() => this.selectGender("f")}>
                            <label className={'chat-label ' + (this.state.selectedRankSex == "f" ? 'selected' : '')}>{strings.female}</label>
                        </div>

                        <div className={'chat-item-div ' + (this.state.selectedRankSex == "m" ? "selected" : "")} onClick={() => this.selectGender("m")}>
                            <label className={'chat-label ' + (this.state.selectedRankSex == "m" ? 'selected' : '')}>{strings.male}</label>
                        </div>
                    </div>

                    <div className='chat-menu-sort-div'>
                        <div className={'chat-item-div ' + (this.state.selectedRankDate == "0" ? "selected" : "")} onClick={() => this.selectDate("0")}>
                            <label className={'chat-label ' + (this.state.selectedRankDate == "0" ? 'selected' : '')}>{strings.today}</label>
                        </div>

                        <div className={'chat-item-div ' + (this.state.selectedRankDate == "1" ? "selected" : "")} onClick={() => this.selectDate("1")}>
                            <label className={'chat-label ' + (this.state.selectedRankDate == "1" ? 'selected' : '')}>{strings.this_week}</label>
                        </div>

                        <div className={'chat-item-div ' + (this.state.selectedRankDate == "2" ? "selected" : "")} onClick={() => this.selectDate("2")}>
                            <label className={'chat-label ' + (this.state.selectedRankDate == "2" ? 'selected' : '')}>{strings.last_week}</label>
                        </div>
                    </div>
                </div>

                <div className='rank-guide-div' onClick={this.onRanking}>
                    <img src={"assets/ic_rank_tropee.png"} width={36} height={36} className='img-contain' />
                    <div className='rank-guide-label-div'>
                        <label className={'rank-guide-label-1'}>{strings.rank_guide_desc1}</label>
                        <label className={'rank-guide-label-2'}>{strings.rank_guide_desc2}</label>
                    </div>
                    <img src={"assets/ic_rank_heart.png"} width={38} height={30} className='img-contain' />
                </div>

                <div className='scroll-rank-div'>
                    <ul>
                        {
                            this.state.rankList.map((item, index) => {
                                return <li key={index}>
                                    <RankList
                                        index={index}
                                        item={item}
                                        rootStore={this.props.rootStore}
                                        onPhoto={() => {
                                            if (item.sex == this.props.rootStore.user.sex) {
                                                this.onAlert(strings.not_view_same_gender);
                                            } else {
                                                if (item.id == "admin" || item.id == "adminchat") {
                                                    return;
                                                }

                                                item.profile_img_url = item.profile_img_url;
                                                item.pro_img_url = item.profile_img_url;
                                                item.img_confirm = item.img_confirm;

                                                //상대회원 리뷰정보 얻기
                                                let self = this;
                                                self.context.post(
                                                    'otherUserReviewScore',
                                                    {
                                                        id: this.props.rootStore.user.id,
                                                        peer_id: item.id,
                                                    },
                                                    function (response) {
                                                        var data = JSON.parse(response.data);
                                                        // console.log(data);
                                                        if (data.result == "success") {
                                                            item.avg = data.avg;
                                                            item.sum = data.sum;
                                                            item.ulike_count = data.ulike_count;
                                                            item.ulike_status = data.ulike_status;
                                                            item.all_score = data.all_score;
                                                            item.level = data.level;

                                                            self.setState({
                                                                peer_user: item,
                                                            }, () => {
                                                                self.openProfilePage();
                                                            });
                                                        }
                                                    });
                                            }
                                        }}
                                        onRequestAudioVideo={(e) => {
                                            this.appInstall();
                                        }}
                                        onWrite={() => {
                                            if (item.sex == this.props.rootStore.user.sex) {
                                                this.onAlert(strings.not_msg_same_gender);
                                            } else {
                                                this.setState({
                                                    selectedIndex: index,
                                                }, () => {
                                                    this.onChat();
                                                })
                                            }
                                        }}
                                    />
                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(RankFragment));