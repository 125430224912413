import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { PageSettings } from '../../config/page-settings.js';
import { inject } from 'mobx-react';

import $ from 'jquery';
import U from '../../config/util';
import PointHistoryList from './cell/point-history-list.jsx';

class PointHistory extends React.Component {

    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            pageNum: 0,
            isLast: false,
            historyList: [],
            loading: false,
        };

        this.onBack = this.onBack.bind(this);
    }

    componentDidMount() {
        this.getHistoryList();
    }

    onBack(event) {
        event.preventDefault();
        this.props.history.goBack();
    }

    getHistoryList() {
        if (this.state.isLast || this.state.loading) {
            return;
        }

        this.setState({
            loading: true
        })
        let self = this;
        self.context.post(
            'pointHistory',
            {
                id: this.props.rootStore.user.id,
                page: this.state.pageNum
            },
            function (response) {
                var data = JSON.parse(response.data);
                // console.log(data);
                if (data.result == "success") {
                    if (data.data.length == 0) {
                        self.setState({
                            isLast: true
                        })
                    }
                    if (self.state.pageNum == 0) {
                        self.setState({
                            historyList: data.data
                        })
                    } else {
                        let list = self.state.historyList.concat(data.data);
                        self.setState({
                            historyList: list
                        })
                    }
                }
                self.setState({
                    loading: false
                })
            });
    }

    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 5
        if (bottom) {
            this.setState({
                pageNum: this.state.pageNum + 1
            }, () => {
                this.getHistoryList();
            })

        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='dashboard-div'>
                    <div className='common-header-div'>
                        <div className='common-header-back-div' onClick={this.onBack}>
                            <img src={"assets/btn_back.png"} width={15} height={13} />
                        </div>
                        <label className='common-header-title-label'>{strings.point_history}</label>
                    </div>

                    <div className='point-history-header-div'>
                        <label className='point-history-header-point-label'>{strings.owned_point}</label>
                        <div className='header-point-div' onClick={this.onPointHistory}>
                            <img src={"assets/ic_pointcharge.png"} width={'19px'} height={'11px'} />
                            <label className='header-point-label'>{U.formatAssets(this.props.rootStore.user.point)}</label>
                        </div>
                    </div>

                    <div className='scroll-point-history-div' onScroll={this.handleScroll}>
                        <ul>
                            {
                                this.state.historyList.map((item, index) => {
                                    return <li key={index}>
                                        <PointHistoryList
                                            index={index}
                                            item={item}
                                        />
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(inject('rootStore')(PointHistory));