import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'mobx-react';
import RootStore from './stores/root-store';

ReactDOM.render(
  <BrowserRouter>
      <Provider rootStore={new RootStore()}>
          <App/>
      </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

