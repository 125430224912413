import React from 'react';
import { Route, Link } from 'react-router-dom';
import strings from '../../../lang/strings.js';
import UserProfileImg from '../../../components/content/user_profile_img.jsx';

class LiveUserList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}


	render() {


		return (
			<div style={{
				display: 'flex', flexDirection: 'column', alignItems: 'center',
				borderRadius: 10,
				overflow: 'hidden',
				margin: 4,
			}}>
				<div className='profile-img-div' style={{width: '100%'}}>
					<UserProfileImg
						id={this.props.item.id}
						profileImgUrl={this.props.item.profile_img_url}
						sex={this.props.item.sex}
						imgConfirm={this.props.item.img_confirm}
						width="100%"
						height="80"
						radius="0px"
					/>
					{
						(this.props.item.state_video == "1" || this.props.item.allow_video == "n") &&
						<div className='in-video-div'>
							<img src={"assets/ic_status_videochat.png"} width={48} height={40} className='img-contain' />
						</div>
					}
				</div>

				<div className='live-info-div'>
					<div className='live-nick-div'>
						<label className='live-nick-label'>{this.props.item.nickname}</label>
						{
							this.props.item.sex == "m" ?
								<img src={"assets/ic_main_man.png"} width={8} height={8} className='img-contain' />
								:
								<img src={"assets/ic_main_girl.png"} width={8} height={8} className='img-contain' />
						}
						{
							this.props.item.sex == "m" ?
								<label className='chat-age-label-man'>{this.props.item.age}</label>
								:
								<label className='chat-age-label-girl'>{this.props.item.age}</label>
						}
					</div>
					<label className='live-msg-label'>{this.props.item.profile_msg}</label>
				</div>
			</div>
		);
	}
}

export default LiveUserList;