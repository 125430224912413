import $ from "jquery";
import strings from "../lang/strings";

const moment = require('moment');
const Const = require('./const');

String.prototype.currencyFormat = function () {
    return this.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

String.prototype.dateFormat = function (format) {
    if (typeof format === 'undefined') {
        format = 'YYYY-MM-DD HH:mm:ss';
    }

    return this.isEmpty() ? '' : moment(this).format(format);
};

String.prototype.customDateFormat = function () {
    if (this.isEmpty()) {
        return '';
    }

    let days = ['sun', 'mon', 'tue', 'wed', 'thr', 'fri', 'sat'];
    let colors = ['text-danger', '', '', '', '', '', 'text-blue'];

    return this + '<span class="' + colors[moment(this).weekday()] + '"> (' + days[moment(this).weekday()] + ')</span>';
};

String.prototype.isEmpty = function () {
    return this === '';
};

String.prototype.nl2br = function () {
    return this.replace(/\n/g, '<br/>');
};

Number.prototype.currencyFormat = function () {
    return this.toString().currencyFormat();
};

String.prototype.ellipsize = function (len) {
    if ( this.length > len )
        return this.substr(0, len) + "...";
    return this;
}

$.fn.dateFormat = function (date, format) {
    if (date == null || date === '') {
        return '';
    }

    if (typeof format === 'undefined') {
        format = 'YYYY-MM-DD';
    }

    return moment(date).format(format);
};

$.fn.currencyFormat = function (cost) {
    return cost.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default {
    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    },
    
    dateFormat(value, format = 'YYYY-MM-DD HH:mm:ss') {
        return value && !value.isEmpty() ? moment(value).format(format) : '';
    },

    currencyFormat(value) {
        if (!value) {
            return value;
        }

        return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    isInvalid(p_value) {
        return (p_value == undefined || p_value == null || p_value.length == 0)
    },

    isValidArray(array) {
        return array !== undefined && array !== null && array.filter(it => it != undefined && it != null).length > 0
    },

    // console.info(formatNumber(2665)) // 2,665
    formatNumber(num) {
        if (num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        } else {
            return num
        }
    },

    formatAssets(num) {
        if (this.isInvalid(num)) {
            return ""
        } else if (num >= 100000000) {
            if (num % 100000000 === 0) {
                return num / 100000000 + " " + strings.billion;
            } else {
                return (num / 100000000).toFixed(1) + " " + strings.billion;
            }
        } else if (num >= 1000000) {
            if (num % 1000000 === 0) {
                return num / 1000000 + " " + strings.million;
            } else {
                return (num / 1000000).toFixed(1) + " " + strings.million;
            }
        } else {
            return this.formatNumber(num)
        }
    },
}