import React from 'react';
import strings from '../../lang/strings.js';
import { PageSettings } from "../../config/page-settings";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import U from './../../config/util';
import ImageSelect from '../../components/content/image-select.jsx';
import UserProfileImg from '../../components/content/user_profile_img.jsx';
import AlertDialog from "../dlg/alert-dialog.js";

export default class TalkDialog extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            item: {
                content: ''
            },
            show_alert_popup: false,
            alert_msg: ""
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    componentDidMount() {
    }

    toggleModal(result) {
        if (this.props.onClose) {
            this.props.onClose(result);
        }
    }

    onDataChange = (e) => {
        let item = this.state.item;
        item[e.target.id] = e.target.value;

        this.setState({
            item: item
        });
    };

    onConfirm() {
        let content = this.state.item.content.trim();
        if (content == "") {
            this.onAlert("내용을 입력해 주세요.");
            return;
        }
        if (this.props.onConfirm) {
            this.props.onConfirm(content);
        }
    }

    onAlert(msg) {
        this.setState({
            alert_msg: msg,
            show_alert_popup: true
        });
    }

    render() {
        let item = this.state.item;

        return (
            <div>
                <AlertDialog
                    msg={this.state.alert_msg}
                    isOpen={this.state.show_alert_popup}
                    onClose={() => {
                        this.setState({ show_alert_popup: false });
                    }} />
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.toggleModal}
                    size={'md'}>
                    <ModalBody style={{ padding: 0 }}>
                        <div style={{ height: 44, background: '#FF545A', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '0 12px' }}>
                            <label style={{ color: '#fff', fontSize: 15, flexGrow: 100 }}>토크쓰기</label>
                            <img src={"assets/ic_close.png"} width={20} height={20} className='img-contain' onClick={this.toggleModal} />
                        </div>
                        <div style={{ background: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                            <textarea autoComplete='off' className='form-control' id='content'
                                placeholder='내용을 입력해주세요.&#10;휴대폰번호 메신저(카톡,라인 등) 아이디 작성 시 범죄에 악용될 수 있으며, 경고없이 차단 조치됩니다.'
                                rows={6}
                                value={item.content}
                                onChange={(e) => this.onDataChange(e)} />
                            <div style={{ height: 36, width: '100%', marginTop: 10, padding: '0 30px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ height: 36, flex: 1, borderRadius: 18, backgroundColor: '#9C9C9C', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} onClick={this.toggleModal}>
                                    <label style={{ color: '#fff', fontSize: 15, marginLeft: 6 }}>취소</label>
                                </div>
                                <div style={{ height: 36, flex: 1, borderRadius: 18, backgroundColor: '#FF777A', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 10 }} onClick={this.onConfirm}>
                                    <label style={{ color: '#fff', fontSize: 15, marginLeft: 6 }}>쓰기</label>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}